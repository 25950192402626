import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MynyfyButton from './MynyfyButton';

function MynyfyPDFGenerator({ htmlString, pdfName, btnName, containerStyle, style, customBtn }) {
  const generatePdf = () => {
    let iframe = document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    iframe.style.width = '794px';
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = htmlString;
    html2canvas(iframedoc.body, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save(pdfName ? pdfName + '.pdf' : 'mynyfy.pdf');
        iframe.remove();
      })
      .catch((error) => console.error('Error generating PDF:', error));
  };

  return customBtn ? (
    <div onClick={() => generatePdf()}>{customBtn()}</div>
  ) : (
    <MynyfyButton
      label={btnName || 'Download'}
      onClick={() => generatePdf()}
      containerStyle={containerStyle}
      style={style}
    />
  );
}

export default MynyfyPDFGenerator;
