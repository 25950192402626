import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import productOrderService from '../../services/productOrderService';
import { fetchIsNewOrder } from '../../redux/actions/newOrder.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  AccessTimeFilledRounded,
  CancelRounded,
  CheckCircleRounded,
  ChevronRightRounded,
} from '@mui/icons-material';
import { OrderDeliveryStatus, OrderStatus, PaymentMode } from '../../utils/enums';
import theme from '../../utils/theme';
import moment from 'moment';
import { orderDeliveryStatusSwitchCase, orderStatusSwitchCase } from '../../utils/SwitchCases';
import './orders.scss';
import OrderPaymentUpdate from './OrderPaymentUpdate';
import { objectReplace } from '../../utils/CommonMethods';
import ShowImages from '../../components/ShowImages';
import storeService from '../../services/storeService';

const OrdersList = ({ type, action, data, sellerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewOrder = useSelector((state) => state.isNewOrder);
  const buyerId = useSelector((state) => state.user?.user?.id);

  const [openPaymentUpdateModal, setOpenPaymentUpdateModal] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [openImgModal, setOpenImgModal] = useState(false);
  const [paymentImg, setPaymentImg] = useState(null);

  useEffect(() => {
    if (isNewOrder !== false || _.isEmpty(data)) {
      getOrders();
    }
  }, [isNewOrder]);

  const getOrders = () => {
    let body = { status: type };
    if (sellerId) body.seller = sellerId;

    productOrderService
      .getProductOrders(body)
      .then((res) => {
        if (res.success) {
          let sorted = _.orderBy(res.data, 'createdAt', 'desc');
          dispatch(action(sorted));
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      })
      .finally(() => {
        if (isNewOrder) dispatch(fetchIsNewOrder(false));
      });
  };

  const navHandler = (item) => {
    navigate('/Orders/' + item.orderId, {
      state: { orderId: item.orderId, orderDetails: item },
    });
  };

  return data ? (
    _.isEmpty(data) ? (
      <MynyfyEmptyMsg message={"You haven't placed any orders"} />
    ) : (
      <Fragment>
        {data?.map((item, i) => (
          <div id='Orders' key={i}>
            <div className='container'>
              <div>
                <div className='rowSB'>
                  <div
                    className='cursor'
                    onClick={() => {
                      if (item?.seller?.urlId) {
                        storeService.viewStore({ orgId: item?.seller?._id, buyerId });
                        navigate('/Stores/' + item?.seller?.urlId);
                      }
                    }}>
                    <MynyfyText title={item.seller.name} bold />
                    <MynyfyText title={item.seller.area?.name} small style={{ marginTop: 3 }} />
                  </div>
                  <div>
                    {item.paymentMode == PaymentMode.UPI_QR ? (
                      item?.paymentStatus !== 'RECEIVED' ? (
                        <div>
                          {item.paymentStatus == 'PAID' ? (
                            <MynyfyText
                              title={'View Payment'}
                              onClick={() => {
                                setOpenImgModal(true);
                                setPaymentImg(item.paymentImg);
                              }}
                              style={{ textAlign: 'right', paddingBottom: 5 }}
                              link
                            />
                          ) : item.paymentStatus == 'NOT RECEIVED' ? (
                            <Fragment>
                              <MynyfyText
                                title={'Update Payment'}
                                onClick={() => {
                                  setOpenPaymentUpdateModal(true);
                                  setOrderId(item._id);
                                }}
                                style={{ textAlign: 'right', paddingBottom: 5 }}
                                link
                              />
                              <MynyfyText
                                title={item.paymentStatus}
                                small
                                error
                                style={{ textAlign: 'right' }}
                              />
                            </Fragment>
                          ) : item.paymentStatus == 'REFUNDED' ? (
                            <MynyfyText
                              title={'Refunded'}
                              small
                              color={'green'}
                              style={{ textAlign: 'right' }}
                            />
                          ) : null}
                        </div>
                      ) : item.paymentStatus == 'RECEIVED' ? (
                        <MynyfyText
                          title={'Payment Completed'}
                          small
                          color={'green'}
                          style={{ textAlign: 'right' }}
                        />
                      ) : null
                    ) : item.paymentMode == PaymentMode.CASH ? (
                      <MynyfyText
                        title={'Cash Payment'}
                        small
                        color={'green'}
                        style={{ textAlign: 'right' }}
                      />
                    ) : item.paymentMode == PaymentMode.ONLINE ? (
                      <MynyfyText
                        title={item.paymentStatus == 'REFUNDED' ? 'Refunded' : 'Payment Completed'}
                        small
                        color={'green'}
                        style={{ textAlign: 'right' }}
                      />
                    ) : null}
                  </div>
                </div>
                <div onClick={() => navHandler(item)} className='cursor'>
                  <div className='rowSB' style={{ marginTop: 10 }}>
                    <div className='row'>
                      <MynyfyText title={theme.RUPEE + item.priceDetails.totalPrice} />
                      <ChevronRightRounded fontSize='' style={{ marginLeft: -2 }} />
                    </div>
                    <div className='rowSB'>
                      <MynyfyText
                        title={
                          [
                            OrderStatus.ACTIVE,
                            OrderStatus.COMPLETED,
                            OrderStatus.CANCELLED,
                          ].includes(item.status)
                            ? orderDeliveryStatusSwitchCase(item.deliveryDetails?.deliveryStatus)
                            : orderStatusSwitchCase(item.status)
                        }
                        small
                        style={{ margin: '0px 5px' }}
                      />
                      {item.status == OrderStatus.PENDING ? (
                        <AccessTimeFilledRounded fontSize='' color='warning' />
                      ) : item.status == OrderStatus.CANCELLED ? (
                        <CancelRounded fontSize='' color='error' />
                      ) : item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.IN_PROGRESS ||
                        item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.ACCEPTED ||
                        item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.PICK_UP_READY ||
                        item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.SHIPPED ? (
                        <AccessTimeFilledRounded fontSize='' color='primary' />
                      ) : item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.CANCELLED ||
                        item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.RETURNED ? (
                        <CheckCircleRounded fontSize='' color='error' />
                      ) : item.deliveryDetails?.deliveryStatus == OrderDeliveryStatus.DELIVERED ||
                        item.status == OrderStatus.COMPLETED ? (
                        <CheckCircleRounded fontSize='' color='success' />
                      ) : null}
                    </div>
                  </div>

                  <div className='divider' />

                  {item.products?.map((res, i) => (
                    <MynyfyText
                      key={i}
                      title={`${res.name || res.productId.name} x ${res.count}`}
                      style={{ marginBottom: 7 }}
                    />
                  ))}
                  {item.deals?.map((res, i) => (
                    <MynyfyText key={i} title={`${res.dealId.name}`} style={{ marginBottom: 7 }} />
                  ))}
                  <div className='rowSB'>
                    <MynyfyText
                      title={moment(item.createdAt).format('MMMM DD, hh:mm A')}
                      color={theme.MUTED_BLUE}
                      small
                    />
                    <MynyfyText
                      title={`Order Id: ${item.orderId}`}
                      small
                      color={theme.MUTED_BLUE}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <OrderPaymentUpdate
          isVisible={openPaymentUpdateModal}
          closeModal={(isUpdated) => {
            setOpenPaymentUpdateModal(false);
            if (isUpdated) {
              let reqOrder = data.find((e) => e._id == orderId);
              if (reqOrder) {
                reqOrder.paymentStatus = 'PAID';
                let modData = objectReplace(data, reqOrder, '_id');
                dispatch(action(modData));
              }
              setOrderId(null);
            } else {
              setOrderId(null);
            }
          }}
          id={orderId}
        />
        <ShowImages
          isVisible={openImgModal}
          closeModal={() => setOpenImgModal(false)}
          images={paymentImg}
        />
      </Fragment>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default OrdersList;
