import { DEAL_VIDEO } from '../actionTypes';

const INITIAL_STATE = true;

const dealVideoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEAL_VIDEO:
      return action.payload;
    default:
      return state;
  }
};

export default dealVideoReducer;
