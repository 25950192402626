import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { CallRounded, LocationOnRounded, MailRounded } from '@mui/icons-material';
import theme from '../../utils/theme';
import './stores.scss';
import MynyfySpan from '../../components/MynyfySpan';
import { Card } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import { exchangePolicy, returnPolicy } from '../../utils/CommonPolicies';

const StoreAddress = ({ details }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const contactNumComponent = (contactNo) => {
    return details ? (
      <div className='row contentSec' onClick={() => window.open('tel:' + contactNo)}>
        <MynyfyText title={'Contact seller at :'} className='subHeading' />
        <CallRounded
          color='primary'
          fontSize=''
          className='cursor'
          style={{ margin: '0px 5px 0px 10px' }}
        />
        <MynyfyText title={contactNo} pBig link />
      </div>
    ) : null;
  };

  return details ? (
    <div style={{ padding: 15 }}>
      {details.aboutUs ? (
        <div style={{ paddingBottom: 15 }}>
          <MynyfyText title={'About Us :'} p bold style={{ paddingBottom: 5 }} />
          <MynyfyText title={details.aboutUs} p style={{ marginLeft: 10 }} />
        </div>
      ) : null}
      <Card className='aboutSections'>
        {details?.address ? (
          <div className='row contentSec'>
            <MynyfyText title={'Address :'} className='subHeading' />
            <div style={{ marginLeft: 10 }}>
              {details.address.lane1 ? <MynyfySpan title={`${details.address.lane1},  `} /> : null}
              {details.address.lane2 ? <MynyfySpan title={`${details.address.lane2},  `} /> : null}
              {details.address.locality ? (
                <MynyfySpan title={`${details.address.locality},  `} />
              ) : null}
              {details.address.city ? <MynyfySpan title={`${details.address.city},  `} /> : null}
              {details.address.state ? <MynyfySpan title={`${details.address.state}`} /> : null}
            </div>
          </div>
        ) : null}

        {contactNumComponent(
          details.contactNo ? details.contactNo : details.ownerId.profile.mobile
        )}

        {details.email ? (
          <div
            className='row contentSec'
            onClick={() => window.open(`mailto:${details.email}?subject=Help!&body=Hi,`)}>
            <MynyfyText title={'Support Id :'} className='subHeading' />
            <MailRounded
              color='primary'
              fontSize=''
              className='cursor'
              style={{ margin: '0px 5px 0px 10px' }}
            />
            <MynyfyText title={details.email} pBig link />
          </div>
        ) : null}

        {details.mapUrl ? (
          <div className='row contentSec' onClick={() => window.open(details.mapUrl)}>
            <MynyfyText title={'Store Location :'} className='subHeading' />
            <LocationOnRounded
              color='primary'
              fontSize=''
              className='cursor'
              style={{ margin: '0px 5px 0px 10px' }}
            />
            <MynyfyText title='Google Maps' pBig link />
          </div>
        ) : null}

        {/* {details.aboutUs ? (
          <div className='row contentSec'>
            <MynyfyText title={'About Us :'} className='subHeading' />
            <MynyfyText title={details.aboutUs} pBig style={{ marginLeft: 10 }} />
          </div>
        ) : null} */}
      </Card>
      {/* <div>
        <Card className='aboutSections' style={{ marginTop: 15 }}>
          <MynyfyText title={'Policies'} className='cursor' onClick={() => setOpen(!open)} />
          {open ? (
            <Fragment>
              {_.isEmpty(details?.policies?.shipping) ? null : (
                <div className='userPolicyOptionCntainer'>
                  <Card
                    className='userPolicyOption'
                    style={{ marginTop: '5px' }}
                    onClick={() => {
                      navigate(`/Policies/ShippingAndDeliveryPolicy`, {
                        state: {
                          policy: details?.policies?.shipping,
                          policyName: 'Shipping / Delivery Policy',
                        },
                      });
                    }}>
                    <MynyfyText title={'Shipping / Delivery Policy'} />
                  </Card>
                </div>
              )}
              <div className='userPolicyOptionCntainer'>
                <Card
                  className='userPolicyOption'
                  style={{ marginTop: '5px' }}
                  onClick={() => navigate('/Policies/PrivacyPolicy')}>
                  <MynyfyText title={'Privacy Policy'} />
                </Card>
              </div>
              <div className='userPolicyOptionCntainer'>
                <Card
                  className='userPolicyOption'
                  style={{ marginTop: '5px' }}
                  onClick={() => {
                    navigate(`/Policies/RefundAndExchangePolicy`, {
                      state: {
                        policy: details?.policies?.isReturn
                          ? details?.policies?.refund
                          : returnPolicy,
                        policy2: details?.policies?.isExchange
                          ? details?.policies?.exchange
                          : exchangePolicy,
                        policyName: `Return / Refund / Exchange Policy`,
                      },
                    });
                  }}>
                  <MynyfyText title={'Return / Refund / Exchange Policy'} />
                </Card>
              </div>
              <div className='userPolicyOptionCntainer'>
                <Card
                  className='userPolicyOption'
                  style={{ marginTop: '5px' }}
                  onClick={() => {
                    navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                  }}>
                  <MynyfyText title={'Terms & Conditions'} />
                </Card>
              </div>
            </Fragment>
          ) : null}
        </Card>
        <MynyfyText
          title={'Powered by Mynyfy'}
          primary
          style={{ marginTop: 10, textAlign: 'end' }}
        />
      </div> */}
    </div>
  ) : null;
};

export default StoreAddress;
