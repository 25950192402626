import React from 'react';
import AdTips from './AdTips';
import MynyfyText from '../../components/MynyfyText';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfyButton from '../../components/MynyfyButton';
import { ChevronRight } from '@mui/icons-material';

const StepOnePublishAd = ({
  luckyDrawName,
  setLuckyDrawName,
  error,
  setTermsConditions,
  termsConditions,
  url,
  setUrl,
  getStepOneDisabled,
  setStep,
}) => {
  return (
    <>
      <MynyfyText bold title={'Enter you Ad details'} style={{ marginBottom: 7 }} />
      <div style={{ margin: '0px -5px' }}>
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Ad Title*'}
          value={luckyDrawName || ''}
          onChange={(e) => setLuckyDrawName(e.target.value)}
          error={error.luckyDrawName ? true : false}
          helperText={error.luckyDrawName}
        />
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Ad Description*'}
          value={termsConditions || ''}
          onChange={(e) => setTermsConditions(e.target.value)}
          multiline={true}
          minRows={4}
          maxRows={8}
          error={error.termsConditions ? true : false}
          helperText={error.termsConditions || ''}
        />
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Website / App store link'}
          value={url || ''}
          onChange={(e) => setUrl(e.target.value)}
          error={error.url ? true : false}
          helperText={error.url || ''}
        />
      </div>
      <AdTips step={1} />
      <MynyfyButton
        label={<ChevronRight fontSize='large' />}
        containerStyle={{ textAlign: 'end' }}
        className='stepBtn'
        disabled={getStepOneDisabled()}
        onClick={() => setStep(2)}
      />
    </>
  );
};

export default StepOnePublishAd;
