import React from 'react';
import _ from 'lodash';
import MynyfyText from './MynyfyText';
import { HighlightOffRounded } from '@mui/icons-material';
import MynyfyLoader from './MynfyfLoader';
import MynyfyModal from './MynyfyModal';

const ShowDescription = ({ isVisible, closeModal, data }) => {
  return (
    <MynyfyModal open={isVisible} close={() => closeModal()}>
      <div style={{ maxHeight: '80%', position: 'relative' }}>
        <div onClick={() => closeModal()} style={{ right: -5, top: -50, position: 'absolute' }}>
          <HighlightOffRounded style={{ color: '#fff' }} />
        </div>
        {data ? (
          <div>
            <MynyfyText title={data.title} bold h6 style={{ paddingBottom: 10 }} />
            <div style={{ marginBottom: 50 }}>
              <MynyfyText title={data.description} p style={{ paddingBottom: 20 }} />
            </div>
          </div>
        ) : (
          <MynyfyLoader />
        )}
      </div>
    </MynyfyModal>
  );
};

export default ShowDescription;
