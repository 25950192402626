import React from 'react';
import MynyfyText from './MynyfyText';
import { ContestType, FEATURE_TYPES } from '../utils/enums';
import _ from 'lodash';
import JackpotVideo from '../screens/Contest/Jackpot/JackpotVideo';
import { s3ToCloudFront } from '../utils/CommonMethods';

const MynyfyDoubleCard = ({ data, onClick, body, footer, type, cardStyle }) => {
  return (
    <div id='MynyfyDoubleCard'>
      {data?.map((res, i) => (
        <div className='dCard cursor' style={cardStyle} key={i}>
          {type === FEATURE_TYPES.STORES && _.isEmpty(res.orgImages) ? (
            <div className='noImgContainer'>
              <div>
                <MynyfyText title={'NO IMAGE'} h4 color='#B2B2B2' />
                <MynyfyText title={'AVAILABLE'} h4 color='#B2B2B2' />
              </div>
            </div>
          ) : res.logo?.url || res.orgImages?.[0]?.url || res.image?.url || res.image ? (
            <img
              src={s3ToCloudFront(
                res.logo?.url || res.orgImages?.[0]?.url || res.image?.url || res.image
              )}
              className='dImg'
              onClick={() => onClick(res)}
            />
          ) : res.video && res.type === ContestType.JACKPOT ? (
            <div style={{ margin: 2 }}>
              <div style={{ borderRadius: 6, overflow: 'hidden' }}>
                <JackpotVideo contestDetails={res} className='dImg' style={{ margin: 0 }} />
              </div>
            </div>
          ) : (
            <div className='dImg'>{body(res)}</div>
          )}
          <div style={{ marginTop: 'auto' }}>{footer(res)}</div>
        </div>
      ))}
    </div>
  );
};

export default MynyfyDoubleCard;
