import React from 'react';
import _ from 'lodash';
import MynyfyText from './MynyfyText';
import MynyfyModal from './MynyfyModal';
import { useNavigate } from 'react-router';

const SelectStoreType = ({ isVisible, closeModal, isRestaurant }) => {
  const navigate = useNavigate();

  const storeNavigation = (key) => {
    navigate(key);
    closeModal();
  };

  return (
    <MynyfyModal open={isVisible} close={() => closeModal()}>
      <div>
        <MynyfyText title={'Shop from any seller to apply this Coupon'} bold center />
        <div className='rowSA' style={{ paddingTop: 10 }}>
          <div
            className='cursor'
            style={{ textAlign: 'center' }}
            onClick={() => {
              storeNavigation(isRestaurant ? '/HomeDeliveryRestaurants' : '/OnlineStores');
            }}>
            <img
              src={require('../assets/imgs/LandingPage/doorDelivery.png')}
              style={{ width: 45, height: 45, objectFit: 'contain' }}
            />
            <MynyfyText
              title={isRestaurant ? 'Home Delivery' : 'Online Stores'}
              style={{ marginTop: -5 }}
            />
          </div>
          <div
            className='cursor'
            style={{ textAlign: 'center' }}
            onClick={() => {
              storeNavigation(isRestaurant ? '/TakeAwayRestaurants' : '/OfflineStores');
            }}>
            <img
              src={require('../assets/imgs/LandingPage/Localsellers.png')}
              style={{ width: 40, height: 40, objectFit: 'contain' }}
            />
            <MynyfyText title={isRestaurant ? 'Take Away' : 'Offline Stores'} />
          </div>
        </div>
      </div>
    </MynyfyModal>
  );
};

export default SelectStoreType;
