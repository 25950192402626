import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import contestService from '../../services/contestService';
import { objectReplace } from '../../utils/CommonMethods';
import { ContestType, FEATURE_TYPES } from '../../utils/enums';
import _ from 'lodash';
import MynyfyDoubleCard from '../../components/MynyfyDoubleCard';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';

const ContestDoubleCardList = ({
  data,
  onClick,
  isLoading,
  refreshing,
  onRefresh,
  customComponent,
  emptyListMsg,
  emptyListTitle,
}) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location?.location);

  const viewContestHandler = (id) => {
    let data = {
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
    };

    contestService
      .viewContest(id, data)
      .then((res) => {
        if (res.success) {
          let reqObj = data.find((e) => e._id == id);
          if (reqObj) {
            reqObj.totalViews = reqObj.totalViews ? reqObj.totalViews + 1 : 1;
            let newData = objectReplace(data, reqObj, '_id');
            data = newData;
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const detailsNavHandler = (item) => {
    onClick(item);
    if (item.type === ContestType.SEE_AND_WIN) {
      viewContestHandler(item._id);
    }
  };

  return isLoading ? (
    <MynyfyLoader paddingTop={'18vh'} />
  ) : _.isEmpty(data) ? (
    <MynyfyEmptyMsg
      paddingTop={'18vh'}
      message={emptyListMsg || `No ${emptyListTitle} currently running in your area`}
    />
  ) : (
    <MynyfyDoubleCard
      data={data}
      onClick={(item) => detailsNavHandler(item)}
      footer={(item) => customComponent(item)}
      type={FEATURE_TYPES.CONTESTS}
    />
  );
};

export default ContestDoubleCardList;
