import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import SignIn from '../screens/Auth/SignIn';
import SignUp from '../screens/Auth/SignUp';
import ForgotPassword from '../screens/Auth/ForgotPassword';
import Home from '../screens/Home';
import StoreDetails from '../screens/Stores/StoreDetails';
import { SiteUrls } from '../utils/MynyfyEnums';

const origin = window.location.origin;
const isMynyfy = SiteUrls.some((url) => origin.includes(url));

const parsedUrl = new URL(origin);
let hostname = parsedUrl.hostname;
if (hostname.startsWith('www.')) hostname = hostname.substring(4);
const baseDomain = hostname.split('.')[0];

console.log(baseDomain);

const AuthStack = () => {
  return (
    <Fragment>
      <Route path='/' element={isMynyfy ? <Home /> : <StoreDetails site={baseDomain} />} />
      <Route path='SignIn' element={<SignIn />} />
      <Route path='SignUp' element={<SignUp />} />
      <Route path='ForgotPassword' element={<ForgotPassword />} />
    </Fragment>
  );
};

export default AuthStack;
