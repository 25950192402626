import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdItem from './AdItem';
import { getContests, interestHandler, joinContest, viewContest } from './AdsHelper';
import SwipeReminder from './SwipeReminder';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { fetchSeeAndWin } from '../../redux/actions/contest.action';
import MynyfyHeader from '../../components/MynyfyHeader';
import { AddCircleRounded } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { getOS } from '../../utils/CommonMethods';
import { useNavigate } from 'react-router-dom';
import './adFeed.scss';
import _ from 'lodash';
import theme from '../../utils/theme';

const PAGE_LIMIT = 10;

const AdsFeed = ({ goHome }) => {
  const pagerViewRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const platform = getOS();

  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [showSwipeUp, setShowSwipeUp] = useState(false);
  const [currPosition, setCurrPosition] = useState(null);
  const [infinity, setInfinity] = useState(true);
  const [page, setPage] = useState(0);
  const [isInfinityApiCall, setIsInfinityApiCall] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isCoupon, setIsCoupon] = useState(false);

  const seeAndWinContests = useSelector((state) => state.contest?.seeAndWin ?? []);
  const brandDetails = useSelector((state) => state.brands?.details);
  const location = useSelector((state) => state.location?.location);
  const adTimer = useSelector((state) => state.adTimer);
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const isLoggedIn = user?.isLoggedIn;
  const adsTimer = adTimer ? adTimer * 1000 : 3000;

  useEffect(() => {
    if (!location) {
      return;
    } else {
      const data = { limit: PAGE_LIMIT, page: 0, buyerId: id };
      callGetContest(data, true);
    }
  }, [location, brandDetails]);

  useEffect(() => {
    if (seeAndWinContests && seeAndWinContests.length > 1 && (currPosition == 0 || currPosition)) {
      if (!seeAndWinContests[currPosition].isContested) {
        setScrollEnabled(false);
        setShowSwipeUp(false);
        setTimeout(() => {
          joinContest({
            drawData: seeAndWinContests[currPosition],
            buyerId: id,
            dispatch,
            seeAndWinContests,
            fetchSeeAndWin,
            setIsCoupon,
          });
          setScrollEnabled(true);
          setTimeout(() => swipeUpHandler(), 1000);
        }, adsTimer);
      }
    }
  }, [currPosition]);

  const swipeUpHandler = () => {
    setShowSwipeUp(true);
    setTimeout(() => setShowSwipeUp(false), 3000);
  };

  const callGetContest = (data, isInitial) => {
    getContests({
      data,
      brandDetails,
      location,
      setInfinity,
      setPage,
      dispatch,
      setIsLoading,
      setIsInfinityApiCall,
      fetchSeeAndWin,
      PAGE_LIMIT,
      page,
      draws: seeAndWinContests,
      isInitial,
      setCurrPosition,
    });
  };

  const infinityScrolling = () => {
    if (infinity && isInfinityApiCall) {
      const data = { limit: PAGE_LIMIT, page: page + 1, buyerId: id };
      setIsInfinityApiCall(false);
      callGetContest(data, false);
    }
  };

  const handleIntrest = (contestId) => {
    interestHandler({
      contestId,
      location,
      dispatch,
      item: seeAndWinContests[currPosition],
      fetchSeeAndWin,
      seeAndWinContests,
    });
  };

  const handleOnPageSelected = (e) => {
    setCurrPosition(e);
    let data = {
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
    };
    let isInifinity = e === seeAndWinContests.length - 2;
    viewContest({
      id: seeAndWinContests[e]._id,
      data,
      seeAndWinContests,
      dispatch,
      fetchSeeAndWin,
      isInifinity,
    });
    if (isInifinity) {
      infinityScrolling();
    }
  };

  const pages = useMemo(
    () =>
      seeAndWinContests &&
      seeAndWinContests.map((data, itemIndex) => {
        return (
          <div className='container' key={data._id}>
            <AdItem
              item={data}
              currPosition={currPosition}
              handleIntrest={handleIntrest}
              itemIndex={itemIndex}
            />
          </div>
        );
      }),
    [seeAndWinContests, currPosition]
  );

  if (isLoading) {
    return <MynyfyLoader />;
  } else {
    return (
      <div id='AdFeed'>
        <div className='container'>
          <MynyfyHeader
            title={''}
            goHome={goHome}
            customElememt={() => (
              <div className='row'>
                <div style={{ position: 'relative' }}>
                  {showSwipeUp && platform !== 'android' && platform !== 'ios' ? (
                    <SwipeReminder moment={'horizontal'} />
                  ) : null}
                </div>
                <AddCircleRounded
                  className='cursor'
                  style={{ color: theme.PRIMARY_BLUE }}
                  onClick={() => {
                    if (isLoggedIn) navigate('/PublishAds/Create');
                    else navigate('/SignIn');
                  }}
                />
              </div>
            )}
          />
          {!isLoading && _.isEmpty(seeAndWinContests) ? (
            <MynyfyEmptyMsg message={'No contest currently running in your area'} />
          ) : (
            <SwipeableViews
              axis={platform === 'android' || platform === 'ios' ? 'y' : 'x'}
              ref={pagerViewRef}
              index={currPosition}
              onChangeIndex={(e) => handleOnPageSelected(e)}
              enableMouseEvents
              className='pagerView'
              containerStyle={{ height: 'inherit' }}
              slideStyle={{ height: 'inherit' }}>
              {pages}
            </SwipeableViews>
          )}
          {showSwipeUp && (platform === 'android' || platform === 'ios') ? (
            <SwipeReminder moment={'vertical'} />
          ) : null}
        </div>
      </div>
    );
  }
};

export default AdsFeed;
