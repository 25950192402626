import React, { Fragment, useState } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MynyfyInput from './MynyfyInput';
import MynyfySelect from './MynyfySelect';
import MynyfyModal from './MynyfyModal';
import MynyfyRadioButton from './MynyfyRadioButton';
import commonService from '../services/commonService';
import MynyfyButton from './MynyfyButton';
import { SortTypes } from '../utils/enums';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import MynyfyText from './MynyfyText';
import { ReactComponent as VegIcon } from '../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../assets/svg/nonVeg.svg';

const MynyfyFilter = ({
  searchAndCatFilter,
  sortFilter,
  isMynts,
  isPrice,
  isFood,
  style,
  userCategories,
}) => {
  const allCategories = useSelector((state) => state.appData?.categories);
  let categories = _.isEmpty(userCategories)
    ? allCategories
    : userCategories.filter((e) => e.code === 'Category');

  const [filterType, setFilterType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedFoodType, setSelectedFoodType] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [selectedSort, setSelectedSort] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);

  const handelFilterType = (key) => {
    setFilterType((filterType) => (filterType === key ? null : key));
  };

  const appDataApi = (key) => {
    commonService.publicCategorySort(key).then((res) => {
      let sortedData = _.orderBy(res, ['name'], ['asc']);
      setSubCategories(sortedData);
    });
  };

  const onCategorySelected = (cat) => {
    setSelectedCategory(cat);
    setSelectedSubCategory(null);
    setSubCategories([]);
    if (cat) {
      if (_.isEmpty(userCategories)) {
        appDataApi(cat.data);
      } else {
        let reqSubCats = userCategories?.filter((e) => e.code === cat.data);
        let sorted = _.orderBy(reqSubCats, 'name', 'asc');
        setSubCategories(sorted);
      }
    }
  };

  const onSortTypeSelected = (e) => {
    setSelectedSort(e);
    sortFilter(e);
    setOpenSortModal(false);
  };

  const searchAndCatFilterHandler = (searchedText, foodType) => {
    searchAndCatFilter({
      searchedText,
      selectedCategory,
      selectedSubCategory,
      selectedFoodType: foodType || selectedFoodType,
      selectedSort,
    });
  };

  const foodFilterHandler = (e) => {
    setSelectedFoodType(e);
    setOpenSortModal(false);
    searchAndCatFilterHandler(searchInput, e);
  };

  return (
    <Fragment>
      <div className='row' style={{ ...style }}>
        <div className='filterContainer' style={{ display: 'flex' }}>
          <div className='filterOption'>
            <SearchOutlinedIcon
              color='primary'
              className='cursor'
              onClick={() => handelFilterType('Search')}
            />
          </div>
          <div className='filterOption'>
            <FilterListIcon
              color='primary'
              className='cursor'
              onClick={() => handelFilterType('Filter')}
            />
          </div>
          <div className='filterOption' style={{ borderRight: 0 }}>
            <SwapVertIcon
              color='primary'
              className='cursor'
              onClick={() => setOpenSortModal(!openSortModal)}
            />
          </div>
        </div>
      </div>

      {filterType === 'Search' ? (
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Search'}
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
            searchAndCatFilterHandler(e.target.value);
          }}
          style={{ marginTop: 10 }}
        />
      ) : null}
      {filterType === 'Filter' ? (
        <div style={{ margin: '10px 0px' }}>
          <MynyfySelect
            placeholder={'Category'}
            value={selectedCategory}
            onChange={(e) => onCategorySelected(e)}
            list={categories.map((res) => ({
              label: res.name,
              value: res._id,
              data: res.data,
            }))}
            isClear
          />
          <MynyfySelect
            placeholder={'Sub Category'}
            value={selectedSubCategory}
            onChange={(e) => setSelectedSubCategory(e)}
            list={subcategories.map((res) => ({
              label: res.name,
              value: res._id,
              data: res.data,
            }))}
            isClear
          />
          <MynyfyButton
            containerStyle={{ textAlign: 'end' }}
            label={'Apply filter'}
            onClick={() => searchAndCatFilterHandler(searchInput)}
          />
        </div>
      ) : null}

      <MynyfyModal open={openSortModal} close={() => setOpenSortModal(false)}>
        <div>
          <MynyfyText
            title={
              'Sort by ' +
              (isFood
                ? ''
                : (isMynts ? 'Mynts' : '') +
                  (isMynts && isPrice ? '/' : '') +
                  (isPrice ? 'Price' : ''))
            }
            bold
            center
            style={{ paddingBottom: 10 }}
          />
          {isMynts ? (
            <Fragment>
              <MynyfyRadioButton
                bold
                title={SortTypes.LOW_TO_HIGH_MYNTS}
                onClick={() => onSortTypeSelected(SortTypes.LOW_TO_HIGH_MYNTS)}
                isSelected={selectedSort === SortTypes.LOW_TO_HIGH_MYNTS ? true : false}
                style={{ paddingBottom: 10 }}
              />
              <MynyfyRadioButton
                bold
                title={SortTypes.HIGH_TO_LOW_MYNTS}
                onClick={() => onSortTypeSelected(SortTypes.HIGH_TO_LOW_MYNTS)}
                isSelected={selectedSort === SortTypes.HIGH_TO_LOW_MYNTS ? true : false}
                style={{ paddingBottom: 10 }}
              />
            </Fragment>
          ) : null}
          {isPrice ? (
            <Fragment>
              <MynyfyRadioButton
                bold
                title={SortTypes.LOW_TO_HIGH_PRICE}
                onClick={() => onSortTypeSelected(SortTypes.LOW_TO_HIGH_PRICE)}
                isSelected={selectedSort === SortTypes.LOW_TO_HIGH_PRICE ? true : false}
                style={{ paddingBottom: 10 }}
              />
              <MynyfyRadioButton
                bold
                title={SortTypes.HIGH_TO_LOW_PRICE}
                onClick={() => onSortTypeSelected(SortTypes.HIGH_TO_LOW_PRICE)}
                isSelected={selectedSort === SortTypes.HIGH_TO_LOW_PRICE ? true : false}
                style={{ paddingBottom: 10 }}
              />
            </Fragment>
          ) : null}
          {isFood ? (
            <Fragment>
              <div className='row' style={{ paddingBottom: 5, marginTop: -5 }}>
                <MynyfyRadioButton
                  bold
                  title={SortTypes.ONLY_VEG}
                  onClick={() => foodFilterHandler(SortTypes.ONLY_VEG)}
                  isSelected={selectedFoodType === SortTypes.ONLY_VEG ? true : false}
                />
                <VegIcon width={20} height={20} style={{ marginLeft: 5 }} />
              </div>
              <div className='row' style={{ paddingBottom: 5 }}>
                <MynyfyRadioButton
                  bold
                  title={SortTypes.ONLY_NON_VEG}
                  onClick={() => foodFilterHandler(SortTypes.ONLY_NON_VEG)}
                  isSelected={selectedFoodType === SortTypes.ONLY_NON_VEG ? true : false}
                />
                <NonVegIcon width={20} height={20} style={{ marginLeft: 5 }} />
              </div>
              <MynyfyRadioButton
                bold
                title={SortTypes.ALL}
                onClick={() => foodFilterHandler(SortTypes.ALL)}
                isSelected={selectedFoodType === SortTypes.ALL ? true : false}
                style={{ paddingBottom: 10 }}
              />
            </Fragment>
          ) : null}
        </div>
      </MynyfyModal>
    </Fragment>
  );
};

export default MynyfyFilter;
