import { BOTTOM_TAB } from '../actionTypes';

const INITIAL_STATE = 'home';

const bottomTabReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOTTOM_TAB:
      return action.payload;
    default:
      return state;
  }
};

export default bottomTabReducer;
