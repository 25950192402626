import { myApi, myPubApi } from '../utils/apiHelper';

class OpportunityService {
  async getOpportunities(queryParam) {
    try {
      return await myApi.get(`/getOpportunity`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getPubOpportunities(queryParam) {
    try {
      return await myPubApi.get(`/getOpportunity`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async viewOpportunity(id, data) {
    try {
      return await myApi.put(`/viewOpportunity/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async websiteClicks(id) {
    try {
      return await myApi.put(`/opportunity/websiteClicks/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async createOpportunity(data) {
    try {
      return await myApi.post(`/opportunity/create`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getOpportunitiesForSeller(queryParam) {
    try {
      return await myApi.get(`/getOpportunityForSeller`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }
}

const opportunityService = new OpportunityService();
export default opportunityService;
export { opportunityService };
