import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyHeader from '../../components/MynyfyHeader';
import { useLocation } from 'react-router-dom';
import './policy.scss';

const RefundPolicy = () => {
  let data = useLocation();

  return (
    <Fragment>
      <MynyfyHeader title={data.state} />
      <div style={{ padding: 20 }} id='policy'>
        <MynyfyText
          title={
            'Publish Flyer Ads is a feature that shows your Flyer in the area you have selected at the time of creating the Ad. Flyer Ads are listed in a sequential manner based on the logic designed by MYNYFY. Therefore, it is possible that a particular Flyer Ad is shown in an expected sequence. Flyer Ads enable users to draw attention to their posts and give an opportunity to  reach more buyers. MYNYFY do not give any guarantee on increase of responses received.'
          }
          style={{ paddingBottom: 20 }}
        />
        <MynyfyText
          title={
            'If you choose to delete or deactivate an ad prior to the period for which it is active, MYNYFY will not be liable to refund for any unutilized time. In the event you are not able to publish the Ad on MYNYFY on account of any technical reasons, on receipt of payment confirmation, The Amount will be credited back to your Account.'
          }
          style={{ paddingBottom: 20 }}
        />
        <MynyfyText
          title={
            'The above refund policy shall not be applicable in the event any content in your Flyer Ad is found to be violating the Terms or there has been any abuse reported in relation to your advertisement or your advertisement is deleted or not re-posted on MYNYFY on account of any technical reasons due to any subsequent editing done by you.'
          }
          style={{ paddingBottom: 20 }}
        />
      </div>
    </Fragment>
  );
};

export default RefundPolicy;
