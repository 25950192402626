import { myApi, myPubApi, setAuthToken } from '../utils/apiHelper';
import jwtDecode from 'jwt-decode';

class AuthService {
  async profileExist(value) {
    return await myPubApi.post('profileExists', { value: value }).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return null;
      }
    });
  }

  async login(data) {
    try {
      const response = await myPubApi.post('login', data);
      return response;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async check() {
    try {
      const response = await myPubApi.get('/');
      return response;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async referralCode(id, data) {
    try {
      const response = await myApi.put(`/profile/update/referralCode/${id}`, data);
      return response;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async referralCheck(code) {
    try {
      const response = await myApi.get(`/checkReferrel/org?code=${code}`);
      return response.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async register(data) {
    try {
      const response = await myPubApi.post('register', data);
      return response;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async forgotPasswordSendOTP(data) {
    try {
      const response = await myPubApi.post('forgot/sendOTP', data);
      return response.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async sendOtp(number) {
    return await myPubApi
      .get(`/register/${number}/sendOtp`)
      .then((res) => {
        if (res.data.success) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
  }

  async forgotPassword(data) {
    try {
      const response = await myPubApi.post('forgot/verify', data);
      return response.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async logout(data) {
    try {
      const response = await myApi.post('logout', data);
      return response;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async profile() {
    let id = localStorage.getItem('id');
    return await myApi
      .get(`profile/${id}`)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return null;
        }
      })
      .catch((e) => {
        return e?.response?.data || null;
      });
  }

  async _setToken(token) {
    localStorage.setItem('login_token', token);
    setAuthToken(token);
    var decoded = jwtDecode(token);
    localStorage.setItem('id', decoded.id);
    localStorage.setItem('profile_id', decoded.profile);
  }

  async _removeToken() {
    localStorage.removeItem('login_token');
    setAuthToken(undefined);
    localStorage.removeItem('id');
    localStorage.removeItem('profile_id');
    localStorage.removeItem('fbt');
  }
}

const authService = new AuthService();
export default authService;
export { authService };
