import { FLASH_ADS, SEEN_FLASH_ADS } from '../actionTypes';

export const fetchFlashAds = (flashAds) => ({
  type: FLASH_ADS,
  payload: flashAds,
});

export const seenFlashAds = (seenAds) => ({
  type: SEEN_FLASH_ADS,
  payload: seenAds,
});
