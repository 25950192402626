import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tabs, Tab } from '@mui/material';
import theme from '../utils/theme';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { fetchBottomTab } from '../redux/actions/bottomTab.action';
import { useDispatch } from 'react-redux';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: '100%' }}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const MynyfyTab = ({
  tabIndex,
  tabs,
  tabComponent,
  isScroll,
  noBackBtn,
  noSwipe,
  style,
  goHome,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(tabIndex || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div id='MynyfyTabs'>
      <AppBar position='relative'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          style={{ backgroundColor: '#fff', color: '#000' }}
          scrollButtons={isScroll}
          variant={isScroll ? 'scrollable' : 'fullWidth'}>
          {tabs?.map((res, i) => (
            <Tab
              label={res}
              style={{ textTransform: 'capitalize', fontFamily: theme.BOLD_FONT }}
              {...tabProps(i)}
              key={i}
            />
          ))}
        </Tabs>
        {noBackBtn ? null : (
          <div
            className='tabsBackBtn'
            onClick={() => {
              if (goHome) {
                dispatch(fetchBottomTab('home'));
              } else {
                navigate(-1);
              }
            }}>
            <ChevronLeftRounded color='primary' />
          </div>
        )}
      </AppBar>
      {noSwipe ? (
        tabComponent?.map((res, i) => (
          <TabPanel value={value} index={i} key={i}>
            {res}
          </TabPanel>
        ))
      ) : (
        <SwipeableViews
          axis={'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
          className='tab'
          style={style}
          containerStyle={{ height: '100%' }}
          slideStyle={{ height: '100%' }}>
          {tabComponent?.map((res, i) => (
            <TabPanel value={value} index={i} key={i}>
              {res}
            </TabPanel>
          ))}
        </SwipeableViews>
      )}
    </div>
  );
};

export default MynyfyTab;
