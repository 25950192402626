import React, { useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyModal from '../../components/MynyfyModal';
import MynyfySelect from '../../components/MynyfySelect';
import MynyfyUploadImg from '../../components/MynyfyUploadImg';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyButton from '../../components/MynyfyButton';
import productOrderService from '../../services/productOrderService';
import { fileUploadToS3WD } from '../../utils/S3Uploader';

export const PaymentStatuses = [{ label: 'Paid', value: 'PAID' }];

const OrderPaymentUpdate = ({ isVisible, closeModal, id }) => {
  const dispatch = useDispatch();

  const [paymentStatus, setPaymentStatus] = useState(PaymentStatuses[0]);
  const [paymentImg, setPaymentImg] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [isClicked, setClicked] = useState(false);

  const uploadHandler = (event) => {
    if (event.imgFile.size / 1000 <= 1024) {
      event.purposeType = 'Order Payment';
      setPaymentImg(event);
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'File size must not exceed 4MB' })
      );
    }
  };

  const closeHandler = (isUpdated) => {
    closeModal(isUpdated || false);
    setPaymentImg(null);
    setErrMsg(null);
  };

  const updateOrderDetails = async () => {
    if (paymentStatus && paymentImg && id) {
      setClicked(true);
      let body = {
        paymentStatus: paymentStatus?.value,
        paymentImg: paymentImg,
      };
      let s3ErrorMsg = null;
      await fileUploadToS3WD(paymentImg, 'OrderPayment').then((res) => {
        if (res.status !== 403 && res.url) {
          body.paymentImg = { name: res.name, url: res.url, purposeType: res.purposeType };
          s3ErrorMsg = null;
        } else {
          s3ErrorMsg = 'Unable to upload store image please try again';
        }
      });
      if (s3ErrorMsg) {
        dispatch(snackbarInfo({ open: true, type: 'warning', message: s3ErrorMsg }));
        setClicked(false);
      } else {
        productOrderService
          .updateOrderDetails(id, body)
          .then((res) => {
            if (res.success) {
              dispatch(
                snackbarInfo({ open: true, type: 'success', message: 'Updated Successfully' })
              );
              closeHandler(true);
            } else {
              dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
            }
          })
          .finally(() => setClicked(false));
      }
    } else {
      setErrMsg('All fields are mandatory');
    }
  };

  return (
    <MynyfyModal open={isVisible} close={() => closeHandler()}>
      <div style={{ maxHeight: '75%' }}>
        <div>
          <MynyfyText
            title={'Update your Payment Details'}
            bold
            center
            style={{ paddingBottom: 10 }}
          />
          <MynyfySelect
            placeholder={'Payment Status'}
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e)}
            list={PaymentStatuses}
            menuPlacement={'top'}
          />
          <MynyfyUploadImg
            accept={true}
            type='button'
            label={paymentImg ? 'Uploaded' : 'Upload Image'}
            name='uploadImage'
            containerMargin='8px 4px 10px'
            onChange={(e) => uploadHandler(e)}
          />
          {errMsg ? (
            <MynyfyText title={errMsg} error pSmall center style={{ paddingBottom: 10 }} />
          ) : null}
          <MynyfyButton
            label={'Update'}
            center
            loading={isClicked}
            disabled={isClicked}
            onClick={() => updateOrderDetails()}
          />
        </div>
      </div>
    </MynyfyModal>
  );
};

export default OrderPaymentUpdate;
