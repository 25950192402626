import React from 'react';
import { TextField } from '@mui/material';

const MynyfyInput = ({
  placeHolder,
  variant,
  type,
  value,
  onChange,
  onFocus,
  onBlur,
  fullWidth,
  helperText,
  style,
  error,
  className,
  rest,
  InputProps,
  disabled,
  multiline,
  rows,
  maxRows,
  minRows,
  textRef,
}) => {
  return (
    <TextField
      label={placeHolder || 'Label'}
      variant={variant || 'outlined'}
      type={type || 'text'}
      value={value || ''}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      size={'small'}
      error={error}
      helperText={helperText || null}
      style={{ margin: '5px 5px 8px', width: fullWidth && '-webkit-fill-available', ...style }}
      className={className || ''}
      multiline={multiline || false}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
      InputProps={InputProps}
      disabled={disabled}
      inputRef={textRef}
      {...rest}
    />
  );
};
export default MynyfyInput;
