import paymentService from '../services/paymentService';
import { PaymentData, PaymentGateways } from '../utils/enums';
import theme from '../utils/theme';

export const cancelRazorPayPayment = ({ orderId, eventTracker }) => {
  let body = { orderId, reason: 'User cancelled payment' };
  paymentService
    .cancelRazorPayPayment(body)
    .then((res) => eventTracker('Cancel Payment'))
    .catch((err) => eventTracker('Cancel Payment', { error: err }));
};

export const RazorPayOptions = ({ description, amount, orderId, user }) => {
  let options = {
    description,
    image: PaymentData.LOGO,
    currency: PaymentData.CURRENCY,
    key: process.env.REACT_APP_RZP_SECRET,
    name: PaymentData.NAME,
    order_id: orderId,
    amount: amount,
    prefill: {
      email: user?.email,
      contact: user?.mobile,
      name: user?.firstName + ' ' + (user?.lastName || ''),
    },
    theme: { color: theme.PRIMARY_BLUE },
  };
  return options;
};
