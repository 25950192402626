import React from 'react';
import MynyfySpan from './MynyfySpan';
import { MynyfyEnums } from '../utils/MynyfyEnums';
import { useSelector } from 'react-redux';
import theme from '../utils/theme';

const MynyfyEmailSupport = () => {
  const profile = useSelector((state) => state.user?.profile?.profile);

  return (
    <MynyfySpan
      title={MynyfyEnums.SUPPORT_EMAIL}
      link
      onClick={() => {
        let msg = profile
          ? `mailto:support@mynyfy.com?subject=Help!&body=Hi, this is ${profile?.firstName || ''} ${
              profile?.lastName || ''
            } and Mobile No. ${profile?.mobile || ''}.${theme.BREAK}${theme.BREAK}`
          : 'mailto:support@mynyfy.com?subject=Help!&body=Hi,';

        window.open(msg);
      }}
    />
  );
};

export default MynyfyEmailSupport;
