import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyHeader from '../../components/MynyfyHeader';
import { useLocation } from 'react-router-dom';
import { CircleRounded } from '@mui/icons-material';
import './policy.scss';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyEmailSupport from '../../components/MynyfyEmailSupport';

let deliveryProcess = [
  'Once order, is received your products are inspected thoroughly to ensure they are in a perfect condition.',
  'They are packed and handed over to our trusted delivery partner.',
  'Tracking number will be shared with the customer and updated to the order in the system.',
  'Our delivery partners then bring the package to you at the earliest possibility. In case, they are unable to reach your provided address or at a suitable time, they will contact you to resolve the issue.',
];

let deliveryPartners = [
  { name: 'DTDC', link: 'https://www.dtdc.in' },
  { name: 'Bluedart', link: 'https://www.bluedart.com' },
  { name: 'Shiprocket', link: 'https://www.shiprocket.in' },
];

let refundProcess = [
  'Customer to send the package back to Mynyfy.',
  "Mynyfy team's validates the received products and provides a coupon code worth the order value to shop again on the system.",
];

const ReturnPolicy = () => {
  let data = useLocation();

  return (
    <Fragment>
      <MynyfyHeader title={data.state || 'Shipping & Return Policy'} />
      <div style={{ padding: 20 }} id='policy'>
        <MynyfyText title={'Shipping Policy'} bold p style={{ paddingBottom: 10 }} />
        <MynyfyText
          title={'How does the delivery process work?'}
          style={{ paddingBottom: 10 }}
          bold
          p
        />
        {deliveryProcess.map((res, i) => (
          <div className='row' style={{ marginBottom: 10, alignItems: 'flex-start' }} key={i}>
            <CircleRounded className='dotIcon' />
            <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
          </div>
        ))}

        <MynyfyText
          title={'Following are some of our trusted courier partners:'}
          style={{ paddingTop: 10 }}
        />
        {deliveryPartners.map((res, i) => (
          <div className='row' style={{ paddingTop: 10 }} key={i}>
            <MynyfyText
              title={i + 1}
              style={{ margin: '0px 5px', textAlign: 'center', width: 20 }}
            />
            <MynyfyText title={res.name + ' - '} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
            &nbsp;
            <MynyfyText
              title={res.link}
              onClick={() => window.open(res.link)}
              link
              style={{ flexWrap: 'wrap', flexShrink: 1 }}
            />
          </div>
        ))}

        <MynyfyText title={'Delivery Time: 3-7 business days '} p bold style={{ paddingTop: 30 }} />
        <div style={{ paddingTop: 10 }}>
          <MynyfySpan title={'If you need help with your order, please write to us at '} />
          <MynyfyEmailSupport />
        </div>

        <MynyfyText title={'Return / Refund Policy'} p bold style={{ paddingTop: 30 }} />
        <MynyfyText
          title={
            'We do not offer any return services to our customers. Products once sold are cannot be returned. They can raise an exchange request for the same amount as the order value.'
          }
          style={{ paddingTop: 10 }}
        />

        <MynyfyText title={'How it works:'} p style={{ paddingTop: 20 }} />
        {refundProcess.map((res, i) => (
          <div className='row' style={{ marginTop: 10, alignItems: 'flex-start' }} key={i}>
            <CircleRounded className='dotIcon' />
            <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
          </div>
        ))}
        <div style={{ paddingTop: 10, paddingBottom: 40 }}>
          <MynyfySpan title={'For more queries, send us mail at '} pSmall />
          <MynyfyEmailSupport />
        </div>
      </div>
    </Fragment>
  );
};

export default ReturnPolicy;
