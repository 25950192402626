import { APP_STATE } from '../actionTypes';

const INITIAL_STATE = true;

const appStateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_STATE:
      return action.payload;
    default:
      return state;
  }
};

export default appStateReducer;
