import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfySpan from '../../components/MynyfySpan';
import { useLocation } from 'react-router';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyHeader from '../../components/MynyfyHeader';
import { CircleRounded } from '@mui/icons-material';
import './policy.scss';
import MynyfyPre from '../../components/MynyfyPre';

const UserPolicy = () => {
  const route = useLocation();

  const [policy, setPolicy] = useState(null);
  const [policy2, setPolicy2] = useState(null);
  const [policyName, setPolicyName] = useState(null);

  useEffect(() => {
    if (route?.state?.policy) {
      setPolicy(route?.state?.policy);
      setPolicyName(route?.state?.policyName);
    }
    if (route?.state?.policy2) {
      setPolicy2(route?.state?.policy2);
    }
  }, []);

  return policy ? (
    <Fragment>
      <MynyfyHeader title={policyName} />
      <div style={{ padding: 20 }} id='policy'>
        {policy.map((item, i) => (
          <Fragment key={i}>
            <MynyfyText title={item?.heading || ''} bold style={{ paddingBottom: 10 }} />
            {item?.content?.map((res, j) => (
              <div className='row' style={{ marginBottom: 10, alignItems: 'flex-start' }} key={j}>
                {item?.content?.length > 1 ? <CircleRounded className='dotIcon' /> : null}
                <MynyfyPre title={res || ''} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
              </div>
            ))}
          </Fragment>
        ))}
        {!route?.state?.isRestaurant && policy2 ? (
          <div style={{ paddingTop: 30 }}>
            {policy2.map((item, i) => (
              <Fragment key={i + 'p2'}>
                <MynyfyText title={item?.heading || ''} bold style={{ paddingBottom: 10 }} />
                {item?.content?.map((res, j) => (
                  <div
                    className='row'
                    style={{ marginBottom: 10, alignItems: 'flex-start' }}
                    key={j + 'p2'}>
                    {item?.content?.length > 1 ? <CircleRounded className='dotIcon' /> : null}
                    <MynyfyPre title={res || ''} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        ) : null}
      </div>
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default UserPolicy;
