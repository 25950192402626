import React, { Fragment, useState } from 'react';
import MynyfyText from './MynyfyText';
import theme from '../utils/theme';
import _ from 'lodash';
import MynyfyModal from './MynyfyModal';
import MynyfyLoader from './MynfyfLoader';
import { snackbarInfo } from '../redux/actions/snackbar.action';
import { useDispatch, useSelector } from 'react-redux';
import { PromoType } from '../utils/enums';
import MynyfySpan from './MynyfySpan';
import { HighlightOff } from '@mui/icons-material';

const SelectPromo = ({
  isVisible,
  closeModal,
  selectedPromo,
  setData,
  promos,
  totalProductsValue,
}) => {
  const mynts = useSelector((state) => state.mynts);

  const dispatch = useDispatch();
  const [more, setMore] = useState(null);

  const applyCouponCode = (data) => {
    let isMinimumOrderValuePassed = data.minimumOrderValue
      ? totalProductsValue >= data.minimumOrderValue
        ? true
        : false
      : true;
    if (
      (data.points <= mynts || !data.points) &&
      data.count > (data.claims || 0) &&
      isMinimumOrderValuePassed
    ) {
      setData(data);
      closeModal();
    } else {
      let errMsg = '';
      if (!(data.count > (data.claims || 0))) {
        errMsg = 'Coupon limit reached';
      } else if (!isMinimumOrderValuePassed) {
        errMsg = `Total Products value should be ${data.minimumOrderValue} to apply`;
      } else {
        errMsg = 'You have insufficient Mynts to apply this coupon';
      }
      dispatch(snackbarInfo({ open: true, type: 'error', message: errMsg }));
    }
  };

  return (
    <MynyfyModal open={isVisible} close={() => closeModal()} style={{ paddingBottom: 0 }}>
      <div style={{ maxHeight: '80vh', backgroundColor: '#fff', overflow: 'scroll' }}>
        <div style={{ paddingHorizontal: 10 }}>
          <div className='rowSB' style={{ paddingBottom: 15, paddingHorizontal: 3 }}>
            <MynyfyText title={'Apply Coupons'} bold h5 />
            {selectedPromo ? (
              <MynyfyText
                title={'Remove'}
                error
                bold
                onClick={() => {
                  setData(null);
                  closeModal();
                }}
                className='cursor'
              />
            ) : (
              <HighlightOff onClick={() => closeModal()} color='error' className='cursor' />
            )}
          </div>
          <div>
            {promos ? (
              _.isEmpty(promos) ? (
                <MynyfyText
                  title={'No Claimed Coupons are available'}
                  style={{ padding: '50px 10px' }}
                  center
                />
              ) : (
                <div id='OrderModals'>
                  {promos.map((e, j) => (
                    <Fragment key={j + e.title}>
                      {/* <MynyfyText title={e.title} h6 bold style={{ paddingBottom: 10 }} /> */}
                      {_.isEmpty(e.data) ? (
                        <MynyfyText
                          title={`No ${e.key} available`}
                          center
                          style={{ paddingBottom: 10 }}
                        />
                      ) : (
                        e.data.map((res, i) => (
                          <div
                            className='orderModalContainer cursor'
                            style={{
                              backgroundColor:
                                selectedPromo?._id == res._id ? theme.ICE_BLUE : '#fff',
                            }}
                            key={i}
                            onClick={() => applyCouponCode(res)}>
                            <div className='rowSB' style={{ marginBottom: 6 }}>
                              <MynyfyText title={res.name} p bold />
                              <MynyfyText
                                title={theme.RUPEE + res.actualPrice}
                                color={theme.GREEN_COLOR}
                                bold
                              />
                            </div>
                            <div className='rowSB'>
                              <MynyfyText title={res.description} small />
                              {res.type == PromoType.REWARD && res.points ? (
                                <div
                                  className='row'
                                  style={{ paddingTop: '5px 0px 0px 10px', whiteSpace: 'nowrap' }}>
                                  <MynyfyText title={`For ${res.points}`} small bold />
                                  <img
                                    src={require('../assets/imgs/icons/coin.png')}
                                    alt='mynts'
                                    style={{ width: 10, height: 10, marginLeft: 3 }}
                                  />
                                </div>
                              ) : null}
                            </div>
                            {res.minimumOrderValue ? (
                              <div style={{ marginTop: 7 }}>
                                <MynyfySpan
                                  title={`Min order value of products should be `}
                                  small
                                  bold
                                />
                                <MynyfySpan
                                  title={`${theme.RUPEE}${res.minimumOrderValue}`}
                                  small
                                  primary
                                  bold
                                />
                                <MynyfySpan
                                  title={` to claim this ${
                                    e.key == 'rewards' ? 'reward' : 'coupon'
                                  }`}
                                  small
                                  bold
                                />
                              </div>
                            ) : null}
                            {/* <MynyfyText
                              title={more == res._id ? 'less' : 'more'}
                              small
                              primary
                              style={{ paddingTop: 5, width: 60 }}
                              onClick={() => setMore(more == res._id ? null : res._id)}
                            /> */}
                          </div>
                        ))
                      )}
                    </Fragment>
                  ))}
                </div>
              )
            ) : (
              <MynyfyLoader />
            )}
          </div>
        </div>
      </div>
    </MynyfyModal>
  );
};

export default SelectPromo;
