import { myApi, myPubApi } from '../utils/apiHelper';

class CommonService {
  async categorySort(category) {
    try {
      return await myApi.get(`/dropdown/${category}`).then((res) => {
        return res.data.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async publicCategorySort(category) {
    try {
      return await myPubApi.get(`/dropdown/${category}`).then((res) => {
        return res.data.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async appDataByData(data) {
    try {
      return await myPubApi.get(`/appDataByData/${data}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async appDataByAny(data) {
    try {
      return await myApi.get(`/appdataByany`, { params: data }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async count(params) {
    try {
      return await myPubApi.get(`/buyersCountOnLocation`, { params }).then((res) => {
        return res.data?.data;
      });
    } catch (err) {
      return console.log(err);
    }
  }

  async mynyfyStats() {
    try {
      return await myPubApi.get(`/stats`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return console.log(err);
    }
  }
}

const commonService = new CommonService();
export default commonService;
export { commonService };
