import { ORDERS, ORDERS_HISTORY } from '../actionTypes';

const INITIAL_STATE = {
  active: null,
  history: null,
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORDERS:
      return {
        ...state,
        active: action.payload,
      };
    case ORDERS_HISTORY:
      return {
        ...state,
        history: action.payload,
      };

    default:
      return state;
  }
};

export default orderReducer;
