import React, { useState } from 'react';
import './auth.scss';
import { Card } from '@mui/material';
import MynyfyText from '../../components/MynyfyText';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfyButton from '../../components/MynyfyButton';
import { useLocation, useNavigate } from 'react-router-dom';
import validate from '../../utils/validate';
import _ from 'lodash';
import authService from '../../services/authService';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin, userToken } from '../../redux/actions/user.action';
import { RELOAD_WITH_LOC_WATCHER, WATCH_PROFILE } from '../../redux/actionTypes';
import { MsgEnums } from '../../utils/MsgEnums';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import profileService from '../../services/profileService';

const VALIDATOR = {
  mobile: [(value) => (value?.length === 10 ? null : 'Please enter valid Mobile Number')],
  password: [(value) => (value?.length ? null : 'Please enter Password')],
};

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useLocation();

  const location = useSelector((state) => state.location?.location);

  const [mobile, setMobile] = useState(params?.state || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});

  const signInHandler = () => {
    let body = { mobile, password };
    let errors = validate(body, VALIDATOR);
    setError(errors);

    if (_.isEmpty(errors)) {
      authService
        .login(body)
        .then(async (res) => {
          if (res.data.message === 'Authenticated!') {
            await authService._setToken(res.data.token);
            const user = jwtDecode(res.data.token);
            dispatch(userToken(res.data.token));
            locationApi(user);
            dispatch({ type: RELOAD_WITH_LOC_WATCHER });
            dispatch({ type: WATCH_PROFILE });
            dispatch(
              snackbarInfo({ open: true, type: 'success', message: 'Logged In Successful!' })
            );
            dispatch(userLogin(user));
            navigate('/', { replace: true });
          } else {
            dispatch(snackbarInfo({ open: true, type: 'error', message: res.data?.message }));
          }
        })
        .catch((err) => {
          dispatch(
            snackbarInfo({
              open: true,
              type: 'error',
              message: [MsgEnums.SOMETHING_WENT_WRONG, MsgEnums.TRY_AGAIN],
            })
          );
        });
    } else {
      dispatch(snackbarInfo({ open: true, type: 'warning', message: MsgEnums.FILL_DETAILS }));
    }
  };

  const locationApi = (user) => {
    if (location?.state?._id && location?.city?._id && location?.area?._id) {
      let data = {
        state: location?.state?._id,
        city: location?.city?._id,
        area: location?.area?._id,
      };
      profileService.profileUpdate(data, user.id, user.profile).catch((e) => {});
    }
  };

  return (
    <div id='auth'>
      <img
        src={require('../../assets/imgs/LandingPage/mynyfy_White.png')}
        alt='Mynyfy'
        className='logo'
      />
      <MynyfyText title={'Login to your account'} color={'#fff'} h6 />
      <Card className='container'>
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Phone Number'}
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          helperText={error?.mobile}
          error={error?.mobile ? true : false}
        />
        <MynyfyInput
          fullWidth={true}
          placeHolder={'Password'}
          type={'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          helperText={error?.password}
          error={error?.password ? true : false}
        />
        <MynyfyButton label={'Login'} fullWidth={true} onClick={() => signInHandler()} />
        <MynyfyText
          title={'Forgot Password?'}
          center
          pBig
          link
          style={{ margin: '25px 0px' }}
          onClick={() => navigate('/ForgotPassword')}
        />
        <div className='rowC' style={{ textAlign: 'center', margin: '25px 0px' }}>
          <MynyfyText title={"Don't have an account?"} pBig />
          &nbsp;
          <MynyfyText title={'SignUp'} h6 link onClick={() => navigate('/SignUp')} />
        </div>
      </Card>
    </div>
  );
};

export default SignIn;
