import { KeyboardBackspaceRounded, SentimentDissatisfiedRounded } from '@mui/icons-material';
import React from 'react';
import './pageNotFound.scss';
import MynyfyText from '../../components/MynyfyText';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div id='pageNotFound'>
      <KeyboardBackspaceRounded className='backBtn' onClick={() => navigate(-1)} />
      <img
        src={require('../../assets/imgs/LandingPage/mynyfy_White.png')}
        alt='Mynyfy'
        className='logo'
      />
      <MynyfyText title={'Sorry! Not Authorized'} h3 color={'#fff'} />
      <SentimentDissatisfiedRounded className='icon' />
    </div>
  );
};

export default PageNotFound;
