import { REFER_POINTS } from '../actionTypes';

const INITIAL_STATE = null;

const referPointsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFER_POINTS:
      return action.payload;
    default:
      return state;
  }
};

export default referPointsReducer;
