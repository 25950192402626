import { ORDERS, ORDERS_HISTORY } from '../actionTypes';

export const fetchOrders = (orders) => ({
  type: ORDERS,
  payload: orders,
});

export const fetchOrdersHistory = (orders) => ({
  type: ORDERS_HISTORY,
  payload: orders,
});
