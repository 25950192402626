import S3FileUpload from './AwsS3';

/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

export const fileUploadToS3 = async (fullImgObj, dirName) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET,
    dirName: dirName,
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    // s3Url: sellerSite() /* optional */,
  };

  const S3Client = new S3FileUpload(config);
  /* This is optional (second param in uploadFile function) */
  // const newFileName = 'my-awesome-file';

  return S3Client.uploadFile(fullImgObj.file)
    .then((response) => {
      let newObj = {
        name: response.key,
        url: response.location,
        fileType: fullImgObj.type,
        purposeType: fullImgObj.purposeType,
      };
      return newObj;
    })
    .catch((err) => {
      return err;
    });
};

export const fileUploadToS3WD = async (fullImgObj, dirName) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET,
    dirName: dirName,
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    // s3Url: sellerSite() /* optional */,
  };

  const S3Client = new S3FileUpload(config);
  /* This is optional (second param in uploadFile function) */
  // const newFileName = 'my-awesome-file';

  return S3Client.uploadFile(fullImgObj.imgFile)
    .then((response) => {
      let newObj = {
        name: response.key,
        url: response.location,
        fileType: 'jpeg',
        purposeType: fullImgObj.purposeType,
      };
      return newObj;
    })
    .catch((err) => {
      return err;
    });
};

export const fileDeleteFromS3 = async (filename, dirName) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET,
    dirName: dirName,
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    // s3Url: sellerSite() /* optional */,
  };

  const S3Client = new S3FileUpload(config);

  await S3Client.deleteFile(filename)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

/** uploaded object
 * {
 *   Response: {
 *     bucket: "your-bucket-name",
 *     key: "photos/image.jpg",
 *     location: "https://your-bucket.s3.amazonaws.com/photos/image.jpg"
 *   }
 * }
 */

/** Deleted object
   * {
   *   Response: {
   *      ok: true,
          status: 204,
          message: 'File deleted',
          fileName: 'hello-world.pdf'
   *   }
   * }
   */
