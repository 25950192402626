import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ReactComponent as TorchIcon } from '../../../assets/svg/torch.svg';
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import contestService from '../../../services/contestService';
import MynyfyText from '../../../components/MynyfyText';
import theme from '../../../utils/theme';
import { objectReplace } from '../../../utils/CommonMethods';
import { fetchScanAndWin } from '../../../redux/actions/contest.action';
import MynyfyButton from '../../../components/MynyfyButton';
import JoinScanAndWinByVoucher from './JoinScanAndWinByVoucher';
import { useNavigate } from 'react-router';
import MynyfyClaimed from '../../../components/MynyfyClaimed';
import MynyfyHeader from '../../../components/MynyfyHeader';

const JoinScanAndWinQR = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const scanAndWinContests = useSelector((state) => state.scanAndWin);

  const [isClicked, setIsClicked] = useState(false);
  const [isJoinDrawModal, setIsJoinDrawModal] = useState(false);
  const [isTorch, setIsTorch] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [contestData, setContestData] = useState(false);

  return (
    <Fragment>
      <MynyfyHeader title={'Scan to Join'} />
      {isClicked ? (
        <Fragment>
          <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <div className='loader' />
            <MynyfyText title={'Please wait'} p />
            <MynyfyText title={'we are entering you into the contest'} p />
          </div>
          <MynyfyClaimed
            title={`Hohoo! ${
              contestData?.myntsCanEarn
                ? `You have earned ${contestData?.myntsCanEarn} mynts and`
                : 'You have'
            } entered into ${contestData?.name} contest`}
            open={isClaimed}
            callback={() => {
              setIsClaimed(false);
              setIsClicked(false);
              navigate(-1);
            }}
          />
        </Fragment>
      ) : (
        <div>
          <MynyfyButton
            label={'Enter code'}
            containerStyle={{ textAlign: 'center', padding: 20 }}
            onClick={() => setIsJoinDrawModal(true)}
          />
          <JoinScanAndWinByVoucher
            isJoinDrawModal={isJoinDrawModal}
            close={() => setIsJoinDrawModal(false)}
            isBack={true}
          />
        </div>
      )}
    </Fragment>
  );
};

export default JoinScanAndWinQR;
