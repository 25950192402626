import {
  BAG_SELLING_ID,
  BAG_OFFERS,
  BAG_PRODUCTS,
  BAG_CALCULATION,
  BAG_ABOUT,
} from '../actionTypes';

const INITIAL_STATE = {
  id: null,
  about: null,
  offers: null,
  products: null,
  calculation: null,
};

const bagReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BAG_SELLING_ID:
      return {
        ...state,
        id: action.payload,
      };
    case BAG_ABOUT:
      return {
        ...state,
        about: action.payload,
      };
    case BAG_OFFERS:
      return {
        ...state,
        offers: action.payload,
      };
    case BAG_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case BAG_CALCULATION:
      return {
        ...state,
        calculation: action.payload,
      };
    default:
      return state;
  }
};

export default bagReducer;
