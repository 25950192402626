import { SNACKBAR } from '../actionTypes';

const INITIAL_STATE = {
  open: false,
};

const snackbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SNACKBAR:
      return action.payload;
    default:
      return state;
  }
};

export default snackbarReducer;
