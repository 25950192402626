import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Profile from '../screens/Profile';
import EditProfile from '../screens/Profile/EditProfile';
import Address from '../screens/Address';
import ChangePassword from '../screens/Auth/ChangePassword';
import EditAddress from '../screens/Address/EditAddress';
import Orders from '../screens/Orders';
import OrderDetails from '../screens/Orders/OrderDetails';
import Jackpot from '../screens/Contest/Jackpot';
import ShopAndWin from '../screens/Contest/ShopAndWin';
import ScanAndWin from '../screens/Contest/ScanAndWin';
import JoinScanAndWinQR from '../screens/Contest/ScanAndWin/JoinScanAndWinByQR';
import Rewards from '../screens/Promos/Rewards';
import Coupons from '../screens/Promos/Coupons';
import PromoDetails from '../screens/Promos/PromoDetails';
import ProductCart from '../screens/Products/ProductCart';
import BottomTabs from './BottomTabs';
import Favourites from '../screens/Favourites';
import Transactions from '../screens/Transactions';
import Notifications from '../screens/Notifications';
import Refer from '../screens/Refer';
import AdsFeed from '../screens/AdsFeed';
import FlashAdsList from '../screens/FlashAds/FlashAdsList';
import CreateFlashAds from '../screens/FlashAds/CreateFlashAds';
import OpportinityDetails from '../screens/Opportunities/OpportinityDetails';
import CreatePublishAd from '../screens/PublishAd/CreatePublishAd';
import PreviewAd from '../screens/PublishAd/PreviewAd';
import PublishAds from '../screens/PublishAd';
import PublishAdDetails from '../screens/PublishAd/PublishAdDetails';
import InterestedUsers from '../screens/PublishAd/InterestedUsers';
import EditPublishAd from '../screens/PublishAd/EditPublishAd';
import FlashAdsDetails from '../screens/FlashAds/FlashAdsDetails';
import StoreDetails from '../screens/Stores/StoreDetails';
import { SiteUrls } from '../utils/MynyfyEnums';

const origin = window.location.origin;
const isMynyfy = SiteUrls.some((url) => origin.includes(url));

const parsedUrl = new URL(origin);
let hostname = parsedUrl.hostname;
if (hostname.startsWith('www.')) hostname = hostname.substring(4);
const baseDomain = hostname.split('.')[0];

console.log(baseDomain);

const AppStack = () => {
  return (
    <Fragment>
      <Route path='/' element={isMynyfy ? <BottomTabs /> : <StoreDetails site={baseDomain} />} />
      <Route path='Profile' element={<Profile />} />
      <Route path='EditProfile' element={<EditProfile />} />
      <Route path='ScanToJoin/QrScanner' element={<JoinScanAndWinQR />} />
      <Route path='Coupons' element={<Coupons />} />
      <Route path='Coupons/:id' element={<PromoDetails />} />
      <Route path='Orders' element={<Orders />} />
      <Route path='Orders/:orderId' element={<OrderDetails />} />
      <Route path='ChangePassword' element={<ChangePassword />} />
      <Route path='Address' element={<Address />} />
      <Route path='EditAddress' element={<EditAddress />} />
      {/* <Route path='Products/Bag' element={<ProductCart />} /> */}
      <Route path='Favourites' element={<Favourites />} />
      <Route path='Transactions' element={<Transactions />} />
      <Route path='Notifications' element={<Notifications />} />
      <Route path='Refer' element={<Refer />} />
      <Route path='Posts' element={<AdsFeed />} />

      {/* Flash Ads */}
      <Route path='FlashAds' element={<FlashAdsList />} />
      <Route path='FlashAds/:id' element={<FlashAdsDetails />} />
      <Route path='FlashAds/Create' element={<CreateFlashAds />} />

      {/* Publish Ads */}
      <Route path='PublishAds' element={<PublishAds />} />
      <Route path='PublishAds/:id' element={<PublishAdDetails />} />
      <Route path='PublishAds/Edit/:id' element={<EditPublishAd />} />
      <Route path='PublishAds/InterestedUsers/:id' element={<InterestedUsers />} />
      <Route path='PublishAds/Create' element={<CreatePublishAd />} />
      <Route path='PublishAds/Create/Preview' element={<PreviewAd />} />
    </Fragment>
  );
};

export default AppStack;
