import React, { Fragment } from 'react';
import MynyfyText from './MynyfyText';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../redux/actions/snackbar.action';
import { ContentCopyRounded, LanguageRounded } from '@mui/icons-material';

const TrackingDetails = ({ deatils }) => {
  const dispatch = useDispatch();

  return deatils ? (
    <div id='OrderModals'>
      <div style={{ padding: '0px 9px' }}>
        <div className='rowSB'>
          <MynyfyText title={'Order Status'} />
          <MynyfyText
            title={deatils?.deliveryStatus?.toLowerCase()}
            style={{ textTransform: 'capitalize' }}
          />
        </div>
      </div>
      {deatils?.trackingId ? (
        <div className='rowSB trackingContainer'>
          <MynyfyText title={deatils?.trackingId} style={{ paddingRight: 10 }} p />
          <div
            onClick={() => {
              navigator.clipboard.writeText(deatils?.trackingId);
              dispatch(
                snackbarInfo({ open: true, type: 'success', message: 'Tracking Id Copied' })
              );
            }}>
            <ContentCopyRounded fontSize='small' color='primary' className='link' />
          </div>
        </div>
      ) : null}
      {deatils?.trackingProvider ? (
        <div style={{ padding: '0px 9px' }} onClick={() => window.open(deatils?.trackingProvider)}>
          <div className='rowSB'>
            <MynyfyText title={'Tracking Website'} bold link />
            <LanguageRounded fontSize='small' color='primary' className='link' />
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default TrackingDetails;
