import {
  DAY_DEALS,
  COUPONS,
  REWARDS,
  SORTED_DAY_DEALS,
  SORTED_COUPONS,
  SORTED_REWARDS,
  PROMOS,
  SORTED_PROMOS,
  FOOD_COUPONS,
  SORTED_FOOD_COUPONS,
} from '../actionTypes';

export const fetchDayDeals = (data) => ({
  type: DAY_DEALS,
  payload: data,
});

export const fetchSortedDayDeals = (data) => ({
  type: SORTED_DAY_DEALS,
  payload: data,
});

export const fetchCoupons = (data) => ({
  type: COUPONS,
  payload: data,
});

export const fetchSortedCoupons = (data) => ({
  type: SORTED_COUPONS,
  payload: data,
});

export const fetchFoodCoupons = (data) => ({
  type: FOOD_COUPONS,
  payload: data,
});

export const fetchSortedFoodCoupons = (data) => ({
  type: SORTED_FOOD_COUPONS,
  payload: data,
});

export const fetchRewards = (data) => ({
  type: REWARDS,
  payload: data,
});

export const fetchSortedRewards = (data) => ({
  type: SORTED_REWARDS,
  payload: data,
});

export const fetchPromos = (data) => ({
  type: PROMOS,
  payload: data,
});

export const fetchSortedPromos = (data) => ({
  type: SORTED_PROMOS,
  payload: data,
});
