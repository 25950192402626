import {
  FETCH_ACTUAL_BRANDS,
  FETCH_ACTUAL_FAV_BRANDS,
  FETCH_BRANDS,
  FETCH_BRANDS_DETAILS,
  FETCH_FAV_BRANDS,
} from '../actionTypes';

const INITIAL_STATE = {
  actualBrands: null,
  brands: null,
  actualFavBrands: null,
  favBrands: null,
  details: null,
};

const brandReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ACTUAL_BRANDS:
      return {
        ...state,
        actualBrands: action.payload,
      };
    case FETCH_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case FETCH_ACTUAL_FAV_BRANDS:
      return {
        ...state,
        actualFavBrands: action.payload,
      };
    case FETCH_FAV_BRANDS:
      return {
        ...state,
        favBrands: action.payload,
      };
    case FETCH_BRANDS_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};

export default brandReducer;
