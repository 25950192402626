import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_REGISTER,
  USER_DEVICE_ID,
  USER_TOKEN,
  USER_PROFILE,
  WATCH_PROFILE,
} from '../actionTypes';

export const userLogin = (user) => ({
  type: USER_LOGIN,
  payload: user,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const userRegister = (user) => ({
  type: USER_REGISTER,
  payload: user,
});

export const userDeviceId = (deviceId) => ({
  type: USER_DEVICE_ID,
  payload: deviceId,
});

export const userToken = (token) => ({
  type: USER_TOKEN,
  payload: token,
});

export const userProfile = (profile) => ({
  type: USER_PROFILE,
  payload: profile,
});

export const watchProfile = () => ({
  type: WATCH_PROFILE,
});
