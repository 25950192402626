import React from 'react';
import './promos.scss';
import { PromoType } from '../../utils/enums';
import Promos from '.';
import { useSelector } from 'react-redux';
import { fetchRewards, fetchSortedRewards } from '../../redux/actions/promo.action';

const Rewards = ({ goHome }) => {
  const promos = useSelector((state) => state.promos?.rewards?.actual);
  const sortedPromos = useSelector((state) => state.promos?.rewards?.sorted);

  return (
    <Promos
      type={PromoType.REWARD}
      isFilter={false}
      screen={'Rewards'}
      emptyListTitle={'Rewards'}
      promos={promos}
      sortedPromos={sortedPromos}
      action={fetchRewards}
      sortedAction={fetchSortedRewards}
      goHome={goHome}
    />
  );
};

export default Rewards;
