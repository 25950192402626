export const onShare = async (code) => {
  try {
    if (navigator.share) {
      await navigator.share({
        title: 'Mynyfy Referal',
        text:
          'You are invited to join MYNYFY - A CNDC Platform. Your local savings starts with us. Join now.' +
          '\n' +
          '\n' +
          ' App link : http://mynyfy.app.link/nTPcGVF1Bvb' +
          '\n' +
          '\n' +
          `${code ? `Use my refferal code: ${code}` : ''}`,
      });
    }
  } catch (error) {
    alert(error.message);
  }
};
