import React, { useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import Lottie from 'react-lottie';
import MynyfyHeader from '../../components/MynyfyHeader';
import NotifyAnimation from '../../assets/animations/notify.json';
import notificationService from '../../services/notificationService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '../../redux/actions/notifications.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { objectReplace } from '../../utils/CommonMethods';
import _ from 'lodash';
import MynyfyLoader from '../../components/MynfyfLoader';
import './notifications.scss';
import { HighlightOff } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const PAGE_LIMIT = 20;

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifications = useSelector((state) => state.notifications);

  const [canInfinity, setCanInfinity] = useState(true);
  const [isInfinityApiCall, setIsInfinityApiCal] = useState(false);
  const [canMarkAllRead, setCanMarkAllRead] = useState(false);

  // Add event listener for scrolling
  useEffect(() => {
    // Function to check if the user has reached the bottom of the page (80%)
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollY + clientHeight >= scrollHeight * 0.8 && isInfinityApiCall) {
        infinityScrolling();
      }
    };

    // Adjust the debounce time as needed
    const debounceScroll = debounce(handleScroll, 500);

    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [isInfinityApiCall]);

  // Debounce function to delay the infinity API call
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    getNotifications(Date.now());
  }, []);

  const getNotifications = (timeCutoff, fromInfinity) => {
    notificationService
      .getNotifications(timeCutoff, PAGE_LIMIT)
      .then((res) => {
        if (res.success) {
          if (!_.isEmpty(res.data?.data)) {
            setCanInfinity(false);
          } else if (res.data?.data?.length < PAGE_LIMIT) {
            setCanInfinity(false);
          } else {
            setCanInfinity(true);
          }

          let modNotifications = fromInfinity
            ? notifications
              ? {
                  data: [...notifications.data, ...res.data.data],
                  count: res.data.count,
                }
              : res.data
            : res.data;
          modNotifications?.data?.map((res) => {
            if (!res.isRead) setCanMarkAllRead(true);
          });
          dispatch(fetchNotifications(modNotifications));
        } else {
          dispatch(fetchNotifications(notifications || []));
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      })
      .finally(() => setIsInfinityApiCal(true));
  };

  const infinityScrolling = () => {
    if (canInfinity && isInfinityApiCall && notifications?.data) {
      setIsInfinityApiCal(false);
      let time = notifications.data?.[notifications.data?.length - 1].eventTime;
      getNotifications(time, true);
    }
  };

  const markNotificationRead = (notiID, item) => {
    if (item.isRead) {
      // handleNotification(item);
    } else {
      notificationService.markRead(notiID).then((res) => {
        if (res.success) {
          let filtered = notifications?.data?.filter((e) => e._id == notiID);
          filtered[0].isRead = true;
          let newArr = objectReplace(notifications.data, filtered[0], '_id');
          dispatch(fetchNotifications({ data: newArr, count: notifications.count - 1 }));
          // handleNotification(item);
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
    }
  };

  const handleNotification = (item) => {
    if (item.category == 'ProductOrder') {
      navigate('/Orders');
    }
  };

  const markAllRead = () => {
    notificationService.markAllRead().then((res) => {
      if (res.success) {
        let modifiedData = notifications?.data.map((res) => {
          res.isRead = true;
          return res;
        });
        dispatch(fetchNotifications({ data: modifiedData, count: 0 }));
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    });
  };

  const deleteNotification = (notiID, item) => {
    notificationService.markDelete(notiID).then((res) => {
      if (res.success) {
        let filtered = notifications?.data.filter((e) => e._id !== notiID);
        dispatch(
          fetchNotifications({
            data: filtered,
            count: item.isRead ? notifications?.count : notifications?.count - 1,
          })
        );
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    });
  };

  const notifiationTitle = (info, msg) => {
    switch (info) {
      case 'memberJoined':
        return 'A new member has joined your group';
      case 'memberLeft':
        return 'A member has left your group ';
      case 'adminLeft':
        return 'Admin has left the group';
      case 'groupAdmin':
        return 'You are now Group Admin ';
      case 'sellerQuoted':
        return 'A seller has quoted on your order';
      case 'orderExperied':
        return 'Order has experied';
      case 'indOrderCancelled':
        return 'Order has been cancelled';
      case 'quoteCancelled':
        return 'Seller has cancelled your order';
      case 'orderRequoted':
        return 'Seller has Re-Quoted on  your order';
      case 'quoteConfirmed':
        return 'Order Confirmed by seller ';
      case 'orderCompleted':
        return 'Order has been successfully completed';
      case 'groupOrderCancelled':
        return 'Group order has been successfully completed';
      case 'groupOrderSubmitted':
        return 'Group order has been successfully submitted';
      case 'sb_referrel':
        return 'One seller was send a referral to you';
      case 'quoteAccepted':
        return 'Group admin has accepted a seller quote';
      case 'sellerRequoted':
        return 'A Seller has requoted on your order';
      case 'publicOfferCreated':
        return 'New offer is created';
      case 'productOrderCompleted':
        return msg;
      case 'productOrderUpdate':
        return msg;
      default:
        return info;
    }
  };

  return (
    <div id='Notifications'>
      <MynyfyHeader
        title={'Notifications'}
        customElememt={
          notifications?.data && canMarkAllRead
            ? () => <MynyfyText title={'Read All'} link onClick={() => markAllRead()} />
            : null
        }
      />
      {notifications ? (
        _.isEmpty(notifications.data) ? (
          <div style={{ paddingTop: 30 }}>
            <Lottie
              options={{ autoplay: true, loop: false, animationData: NotifyAnimation }}
              style={{ width: 'min(80vw, 340px)', height: 'min(80vw, 340px)' }}
            />
            <MynyfyText
              title={'You will be notified soon'}
              pBig
              center
              style={{ padding: '20px 5px' }}
            />
          </div>
        ) : (
          <div>
            {notifications?.data?.map((res, i) => (
              <div
                key={i}
                read={res.isRead ? 'true' : 'false'}
                className='notificationContainer rowSB'>
                <div className='cursor' onClick={() => markNotificationRead(res._id, res)}>
                  <MynyfyText title={notifiationTitle(res.type, res.info)} />
                </div>
                <HighlightOff
                  color='error'
                  fontSize='small'
                  className='cursor'
                  style={{ marginLeft: 10 }}
                  onClick={() => deleteNotification(res._id, res)}
                />
              </div>
            ))}
          </div>
        )
      ) : (
        <MynyfyLoader />
      )}
      {canInfinity && notifications?.data?.length >= PAGE_LIMIT ? (
        <div style={{ padding: '20px' }}>
          <div className='loader' />
        </div>
      ) : null}
    </div>
  );
};

export default Notifications;
