import React, { useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import contestService from '../../services/contestService';
import MynyfyButton from '../../components/MynyfyButton';
import validate from '../../utils/validate';
import _ from 'lodash';
import { Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfyLoader from '../../components/MynfyfLoader';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fileUploadToS3WD } from '../../utils/S3Uploader';
import { useLocation, useNavigate } from 'react-router';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyUploadImg from '../../components/MynyfyUploadImg';

let VALIDATOR = {
  title: [(value) => (_.isEmpty(value) ? `Please enter Title of your Ad` : null)],
  description: [(value) => (_.isEmpty(value) ? `Please enter Ad Description` : null)],
  image: [(value) => (_.isEmpty(value) ? `Please upload Ad Image` : null)],
};

const EditPublishAd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();

  const [details, setDetails] = useState(null);
  const [title, setTitle] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [newImg, setNewImg] = useState(null);
  const [description, setDescription] = useState(null);
  const [website, setWebsite] = useState(null);
  const [error, setError] = useState({});
  const [isClicked, setClicked] = useState(false);

  useEffect(() => {
    if (route?.state?.details) {
      setDetails(route?.state?.details);
      setTitle(route?.state?.details?.name);
      setImgUrl(route?.state?.details?.image);
      setWebsite(route?.state?.details?.url);
      setDescription(route?.state?.details?.terms);
    }
  }, []);

  useEffect(() => {
    if (newImg) {
      // Create a blob URL from the blob
      const blobUrl = URL.createObjectURL(newImg.imgFile);
      setImgUrl(blobUrl);

      // Clean up the blob URL when the component unmounts
      return () => URL.revokeObjectURL(newImg.imgFile);
    }
  }, [newImg]);

  const uploadHandler = (event) => {
    if (event.imgFile.size <= 524288) {
      event.purposeType = 'LuckyDraw';
      setNewImg(event);
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'File size must not exceed 5MB' })
      );
    }
  };

  const submit = async () => {
    let obj = { ...error, title, description, image: imgUrl };
    let errors = validate(obj, VALIDATOR);
    if (_.isEmpty(errors)) {
      setClicked(true);
      setError(errors);
      let body = {
        name: title,
        terms: description,
      };
      if (body.url) body.url = body.website;
      let s3ErrorMsg;
      if (newImg) {
        await fileUploadToS3WD(newImg, 'LuckyDraw').then((res) => {
          if (res.status !== 403 && res.url) {
            body.image = res.url;
            s3ErrorMsg = null;
          } else {
            delete body.image;
            s3ErrorMsg = ['Unable to upload image', 'Please upload the image again'];
          }
        });
      }
      contestService
        .updateAd(details._id, body)
        .then((res) => {
          if (res.success) {
            dispatch(
              snackbarInfo({
                open: true,
                type: s3ErrorMsg ? 'warning' : 'success',
                message: s3ErrorMsg || 'Ad updated successfully',
              })
            );
            navigate(-1);
          } else {
            dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
          }
        })
        .catch((e) => console.log(e))
        .finally((e) => setClicked(false));
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'Please enter required fields' })
      );
      setError(errors);
    }
  };

  return details ? (
    <div id='PublishAd'>
      <MynyfyHeader title={'Edit Ad'} />
      <div className='detailsContainer'>
        <Card style={{ padding: '0px 5px 5px' }}>
          <img src={imgUrl} className='drawImg' />
          <div style={{ padding: 6, paddingTop: 10, textAlign: 'end' }}>
            <MynyfyUploadImg
              accept={true}
              type='text'
              label={'Change Image'}
              name={'adImage'}
              onChange={(e) => uploadHandler(e)}
            />
          </div>
        </Card>
      </div>
      <div style={{ padding: '8px 10px' }}>
        <MynyfyInput
          placeHolder={'Title*'}
          value={title || ''}
          onChange={(e) => setTitle(e.target.value)}
          error={error.title ? true : false}
          helperText={error.title || ''}
          fullWidth={true}
        />

        {website ? (
          <MynyfyInput
            placeHolder={'Website'}
            value={website || ''}
            onChange={(e) => setWebsite(e.target.value)}
            error={error.url ? true : false}
            helperText={error.url || ''}
            fullWidth={true}
          />
        ) : null}
        <MynyfyInput
          placeHolder={`Description`}
          value={description || ''}
          onChange={(e) => setDescription(e.target.value)}
          multiline={true}
          minRows={4}
          maxRows={8}
          fullWidth={true}
        />
      </div>
      <MynyfyButton
        label={'Update'}
        containerStyle={{ paddingBottom: 40 }}
        center
        onClick={() => submit()}
        disabled={isClicked}
        loading={isClicked}
      />
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default EditPublishAd;
