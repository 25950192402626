import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import MynyfyText from './MynyfyText';
import MynyfyModal from './MynyfyModal';

const MynyfyAlert = ({ open, close, title, content, action1, action2 }) => {
  return (
    <MynyfyModal
      open={open}
      close={close}
      position={'center'}
      style={{ borderRadius: 15, margin: 10 }}>
      <div id='MynyfyAlert'>
        {title ? <MynyfyText title={title} bold h5 /> : null}
        {content ? <MynyfyText title={content} h6 style={{ marginTop: 7 }} /> : null}
        {action1 || action2 ? (
          <DialogActions style={{ paddingBottom: 0 }}>
            {action1 ? (
              <Button
                className='alertBtn'
                style={{ color: action1.color }}
                onClick={action1.handler}>
                {action1.label}
              </Button>
            ) : null}
            {action2 ? (
              <Button
                className='alertBtn'
                style={{ color: action2.color }}
                onClick={action2.handler}>
                {action2.label}
              </Button>
            ) : null}
          </DialogActions>
        ) : null}
      </div>
    </MynyfyModal>
  );
};

export default MynyfyAlert;
