import _ from 'lodash';
import contestService from '../../services/contestService';
import { objectReplace } from '../../utils/CommonMethods';
import transactionService from '../../services/transactionService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fetchMynts } from '../../redux/actions/mynts.action';

export const getContests = ({
  data,
  location,
  setInfinity,
  setPage,
  dispatch,
  setIsLoading,
  setIsInfinityApiCall,
  fetchSeeAndWin,
  PAGE_LIMIT,
  page,
  draws,
  isInitial,
  setCurrPosition,
}) => {
  let params = {
    status: 'active',
    isCats: false,
    state: location?.state?._id,
    city: location?.city?._id,
    area: location?.area?._id,
    type: 'SEE_AND_WIN',
    ...data,
  };
  contestService
    .getContests(params)
    .then((res) => {
      if (res.success) {
        if (_.isEmpty(res.data)) {
          setInfinity(false);
        } else {
          if (res.data?.length < PAGE_LIMIT) {
            setInfinity(false);
          }
          let viewed = [];
          let notViewed = [];
          res.data.map((draw) => {
            if (!draw.isContested) {
              notViewed.unshift(draw);
            } else {
              viewed.push(draw);
            }
          });
          let sortViewed = _.orderBy(viewed, 'createdAt', 'desc');
          let sortNotViewed = _.orderBy(notViewed, 'createdAt', 'desc');
          let sorted = [...sortNotViewed, ...sortViewed];
          data.page ? setPage(page + 1) : setPage(0);
          setInfinity(true);
          dispatch(fetchSeeAndWin(data.page ? [...draws, ...sorted] : sorted));
          if (isInitial) setCurrPosition(0);
        }
      } else {
        dispatch(fetchSeeAndWin([]));
      }
    })
    .finally(() => {
      setIsLoading(false);
      setIsInfinityApiCall(true);
    });
};

export const getPoints = async ({ dispatch }) => {
  transactionService.getPoints().then((res) => {
    if (res?.data?.points) {
      dispatch(fetchMynts(res?.data?.points));
    }
  });
};

export const joinContest = async ({
  drawData,
  buyerId,
  dispatch,
  seeAndWinContests,
  fetchSeeAndWin,
  setIsCoupon,
}) => {
  if (!drawData?.isContested) {
    let data = {
      _id: drawData._id,
      buyerId,
      image: null,
      amount: 0,
    };
    if (drawData.organization) {
      data.sellerId = drawData.organization._id;
    }
    contestService.enterContest(data).then(async (res) => {
      if (res.success) {
        await getPoints({ dispatch });
        let newData = objectReplace(seeAndWinContests, res.data.luckyDraw, '_id');
        dispatch(fetchSeeAndWin(newData));
        if (res.data.couponCount > 0) {
          setIsCoupon(true);
        }
      } else {
        // dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  }
};

export const viewContest = ({
  id,
  data,
  seeAndWinContests,
  dispatch,
  fetchSeeAndWin,
  isInifinity,
}) => {
  contestService
    .viewContest(id, data)
    .then((res) => {
      if (res.success && !isInifinity) {
        let reqObj = seeAndWinContests.find((e) => e._id == id);
        if (reqObj) {
          reqObj.totalViews = reqObj.totalViews ? reqObj.totalViews + 1 : 1;
          let newData = objectReplace(seeAndWinContests, reqObj, '_id');
          dispatch(fetchSeeAndWin(newData));
        }
      }
    })
    .catch((e) => console.log(e));
};

export const interestHandler = ({
  contestId,
  location,
  dispatch,
  item,
  fetchSeeAndWin,
  seeAndWinContests,
}) => {
  let data = {
    state: location?.state?._id,
    city: location?.city?._id,
    area: location?.area?._id,
  };
  contestService
    .interstedContest(contestId, data)
    .then((res) => {
      if (res.success) {
        if (!item.isUserInterested) {
          item.isUserInterested = true;
          let newData = objectReplace(seeAndWinContests, item, '_id');
          dispatch(fetchSeeAndWin(newData));
        }
        dispatch(snackbarInfo({ type: 'success', open: true, message: res.data }));
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    })
    .catch((e) => console.log(e));
};

export const websiteClick = (contestId, url) => {
  contestService.updateWebsiteClicks(contestId).catch((e) => console.log(e));
  window.open(url);
};
