import { myApi } from '../utils/apiHelper';

class CustomerSubscriptionService {
  async getSubscriptionPacks(params) {
    try {
      return myApi.get('/getSubscriptionPacks', { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getSubscriptionPackById(id) {
    try {
      return myApi.get(`/getSubscriptionPackById/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async addSubscriber(data) {
    try {
      return myApi.post('/addSubscriber', data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getSubscribedPacks(params) {
    try {
      return myApi.get('/getSubscribedPacks', { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getSubscribedPackById(id) {
    try {
      return myApi.get(`/getSubscribedPackById/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const customerSubscriptionService = new CustomerSubscriptionService();
export default customerSubscriptionService;
export { customerSubscriptionService };
