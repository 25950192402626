import React from 'react';
import MyntsTxn from './MyntsTxn';
import PaymentTxn from './PaymentTxn';
import MynyfyTab from '../../components/MynyfyTab';
import { useLocation } from 'react-router-dom';

const Transactions = () => {
  const { state } = useLocation();

  return (
    <MynyfyTab
      tabIndex={state}
      noSwipe={true}
      tabs={['Payments', 'Mynts']}
      tabComponent={[<PaymentTxn />, <MyntsTxn />]}
    />
  );
};

export default Transactions;
