import React, { Fragment, useRef, useState } from 'react';
import MynyfyText from '../../../components/MynyfyText';
import MynyfyInput from '../../../components/MynyfyInput';
import MynyfyModal from '../../../components/MynyfyModal';
import contestService from '../../../services/contestService';
import { useDispatch, useSelector } from 'react-redux';
import { objectReplace } from '../../../utils/CommonMethods';
import { fetchScanAndWin } from '../../../redux/actions/contest.action';
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import MynyfyClaimed from '../../../components/MynyfyClaimed';
import MynyfyButton from '../../../components/MynyfyButton';
import validate from '../../../utils/validate';
import _ from 'lodash';

const VALIDATOR = {
  code1: [(value) => (value ? (value?.length == 4 ? null : 'Enter valid part 1') : 'Enter part 1')],
  code2: [(value) => (value ? (value?.length == 4 ? null : 'Enter valid part 2') : 'Enter part 2')],
  code3: [(value) => (value ? (value?.length == 4 ? null : 'Enter valid part 3') : 'Enter part 3')],
  code4: [(value) => (value ? (value?.length == 5 ? null : 'Enter valid part 4') : 'Enter part 4')],
};

const JoinScanAndWinByVoucher = ({ isJoinDrawModal, close, isBack }) => {
  let code1Ref = useRef(null);
  let code2Ref = useRef(null);
  let code3Ref = useRef(null);
  let code4Ref = useRef(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  let id = user?.user?.id;
  const scanAndWin = useSelector((state) => state.contest?.scanAndWin);

  const [code, setCode] = useState({});
  const [errMsg, setErrMsg] = useState(null);
  const [isClicked, setClicked] = useState(false);
  const [isClaimed, setClaimed] = useState(false);
  const [contest, setContest] = useState(null);
  const [error, setError] = useState({});

  const changeHandler = (name, e, textRef) => {
    let value = e.target.value;
    setCode((code) => ({ ...code, [name]: value }));
    if (value?.length == 4 && textRef) {
      textRef?.current?.focus();
    }
  };

  const joinContest = () => {
    const { code1, code2, code3, code4 } = code;
    let isError = validate({ code1, code2, code3, code4 }, VALIDATOR);
    setError(isError);
    if (_.isEmpty(isError)) {
      setClicked(true);
      setErrMsg(null);
      let data = {
        drawCode: `${code1.toUpperCase()}-${code2.toUpperCase()}-${code3.toUpperCase()}`,
        buyerId: id || null,
        contestId: code4.toUpperCase(),
      };
      contestService.joinScanAndWin(data).then((res) => {
        if (res.success) {
          if (!_.isEmpty(scanAndWin)) {
            let newData = objectReplace(scanAndWin, res.data, '_id');
            dispatch(fetchScanAndWin(newData));
          }
          if (res.data?.myntsCanEarn) {
            setClaimed(true);
            setContest({ name: res.data?.name, earnedMynts: res.data?.myntsCanEarn });
          } else {
            closeModal();
            dispatch(
              snackbarInfo({
                type: 'success',
                open: true,
                message: 'Thank you for your Participation',
              })
            );
          }
        } else {
          setClicked(false);
          setErrMsg(res.message);
        }
      });
    } else {
      setErrMsg('Please enter valid code');
    }
  };

  const closeModal = () => {
    setCode({});
    setClicked(false);
    setErrMsg(null);
    setClaimed(false);
    close();
    setContest(null);
  };

  return (
    <Fragment>
      <MynyfyModal open={isJoinDrawModal} close={() => close()}>
        <MynyfyText title={'Join Shop & Scan Contest'} bold primary center h6 />
        <div className='row' style={{ paddingTop: 15 }}>
          {[
            { id: 1, ref: code1Ref, nextRef: code2Ref },
            { id: 2, ref: code2Ref, nextRef: code3Ref },
            { id: 3, ref: code3Ref, nextRef: code4Ref },
            { id: 4, ref: code4Ref },
          ].map((res, i) => (
            <MynyfyInput
              key={i}
              fullWidth={true}
              placeHolder={'Part ' + res.id}
              value={code?.['code' + res.id]}
              onChange={(e) => changeHandler(`code${res.id}`, e, res.nextRef)}
              error={error?.['code' + res.id] ? true : false}
              textRef={res.ref}
            />
          ))}
        </div>
        <MynyfyText title={errMsg} error center />
        <MynyfyButton
          label={'Join'}
          onClick={() => joinContest()}
          center
          disabled={isClicked}
          loading={isClicked}
          style={{ marginTop: 10, marginBottom: 15, borderRadius: 20 }}
        />
      </MynyfyModal>
      <MynyfyClaimed
        title={`Hohoo! ${
          contest?.myntsCanEarn ? `You have earned ${contest?.myntsCanEarn} mynts and` : 'You have'
        } entered into ${contest?.name} contest`}
        open={isClaimed}
        callback={() => closeModal()}
      />
    </Fragment>
  );
};

export default JoinScanAndWinByVoucher;
