import React from 'react';
import Select from 'react-select';
import theme from '../utils/theme';
import { FormHelperText } from '@mui/material';

export default function MynyfySelect({
  width,
  error,
  helperText,
  type,
  list,
  placeholder,
  value,
  onChange,
  disabled,
  isClear,
  helperTextStyle,
  menuPlacement,
  ...rest
}) {
  const Styles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'none',
      arginBottom: 9.6,
      marginLeft: 4,
      marginRight: 4,
      marginTop: 8,
      width: width || '-webkit-fill-available',
      borderColor: error ? '#b80915' : 'hsl(0, 0%, 80%)',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        width: width || '-webkit-fill-available',
        backgroundColor: isDisabled ? null : isSelected ? 'none' : isFocused ? 'none' : null,
        ':hover': { backgroundColor: theme.DULL_BLUE },
        color: isDisabled ? '#ccc' : isSelected ? theme.PRIMARY_BLUE : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'red',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'green',
      ':hover': { backgroundColor: theme.ERROR, color: 'white' },
    }),
    menu: (styles, { data }) => ({
      ...styles,
      width: width || '-webkit-fill-available',
      zIndex: 1000,
    }),
    groupHeading: (base) => ({
      ...base,
      color: 'white',
      backgroundColor: 'purple',
      fontSize: 15,
      padding: 15,
    }),
  };

  return (
    <div>
      <Select
        closeMenuOnSelect={type === 'multi' ? false : true} //? Menu list close/open on select
        isMulti={type === 'multi' ? true : false} //? Multiple select or not
        options={list} //? Drop down data [Array of obj]
        //********* option array structure ********//
        //* for Non-Group --> data1 = [{label:"MacLeran", value:"cars 1"},{label:"Lambo", value:"cars 2"}]
        //* for Group --> data2 = [{label:"Cars", options:data1},{label:"Colors", value:colorsData}]
        styles={Styles} //? All styles
        hideSelectedOptions={false} //? Selected Options hide/not
        isClearable={isClear} //? clear/not all selected options helps in multi
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
        {...rest}
      />
      <FormHelperText
        error
        className='MuiFormHelperText-contained'
        style={{ margin: '3px 5px 0px', ...helperTextStyle }}>
        {helperText}
      </FormHelperText>
    </div>
  );
}
