import { fork, put, call, all, takeLatest } from '@redux-saga/core/effects';
import _ from 'lodash';
import { INITIAL_RELOAD_WATCHER } from '../actionTypes';
import commonService from '../../services/commonService';
import { fetchReferPoints } from '../actions/referPoints.action';
import { fetchCategories, fetchOrderMynts, fetchStates } from '../actions/appData.action';
import { fetchDealVideo } from '../actions/dealVideo.action';
import videoService from '../../services/videoService';
import { PromoType } from '../../utils/enums';
import { fetchMynyfyStats } from '../actions/mynyfyStats.action';

function* getInitialReloadEffect() {
  const mynyfyStats = yield call(commonService.mynyfyStats);
  const referPoints = yield call(commonService.publicCategorySort, 'BUYER_REFERRAL');
  const states = yield call(commonService.publicCategorySort, 'State');
  const categories = yield call(commonService.publicCategorySort, 'Category');
  const orderMynts = yield call(commonService.publicCategorySort, 'OrderMynts');
  const dealVideo = yield call(videoService.getVideos, {
    isActive: true,
    isDate: true,
    type: PromoType.DAY_DEAL,
    isDelete: false,
  });

  yield all([
    put(fetchReferPoints(_.isEmpty(referPoints) ? '' : referPoints[0].quotePoints)),
    put(fetchStates(_.isEmpty(states) ? [] : _.orderBy(states, 'name', 'asc'))),
    put(fetchCategories(_.isEmpty(categories) ? [] : _.orderBy(categories, 'name', 'asc'))),
    put(fetchDealVideo(_.isEmpty(dealVideo?.data) ? null : dealVideo.data[0])),
    put(fetchMynyfyStats(mynyfyStats?.success ? mynyfyStats.obj : null)),
    put(fetchOrderMynts(_.isEmpty(orderMynts) ? null : orderMynts[0])),
  ]);
}

function* watchInitialReload() {
  yield takeLatest(INITIAL_RELOAD_WATCHER, getInitialReloadEffect);
}

const InitialReloadSagas = [fork(watchInitialReload)];
export default InitialReloadSagas;
