import { EnquiryStatus, OrderDeliveryStatus, OrderStatus } from './enums';

export const enquiryStatusSwitchCase = (key) => {
  let status;
  switch (key) {
    case EnquiryStatus.INITIATED:
      status = EnquiryStatus.GROUP_INITIATED;
      break;
    case EnquiryStatus.CREATED:
      status = EnquiryStatus.SUBMITTED;
      break;
    case EnquiryStatus.WAITING:
      status = EnquiryStatus.WAITING_FOR_ACCEPTANCE;
      break;
    case EnquiryStatus.GROUP_EXPIRED:
      status = EnquiryStatus.GROUP_TIME_ELAPSED;
      break;
    case EnquiryStatus.ENQUIRY_EXPIRED:
      status = EnquiryStatus.ENQUIRY_TIME_ELAPSED;
      break;
    case EnquiryStatus.ACCEPTED:
      status = EnquiryStatus.ACCEPTED;
      break;
    case EnquiryStatus.CONFIRMED:
      status = EnquiryStatus.CONFIRMED;
      break;
    case EnquiryStatus.COMPLETED:
      status = EnquiryStatus.COMPLETED;
      break;
    case EnquiryStatus.CANCELLED:
      status = EnquiryStatus.CANCELLED;
      break;
    default:
      status = null;
  }
  return status;
};

export const orderStatusSwitchCase = (key) => {
  let status;
  switch (key) {
    case OrderStatus.PENDING:
      status = 'Pending';
      break;
    case OrderStatus.IN_PROGRESS:
      status = 'In Progress';
      break;
    case OrderStatus.ACTIVE:
      status = 'Active';
      break;
    case OrderStatus.COMPLETED:
      status = 'Completed';
      break;
    case OrderStatus.CANCELLED:
      status = 'Cancelled';
      break;
    case OrderStatus.REFUND_REQUESTED:
      status = 'Refund Requested';
      break;
    case OrderStatus.REFUND_COMPLETED:
      status = 'Refund Completed';
      break;
    default:
      status = null;
  }
  return status;
};

export const orderDeliveryStatusSwitchCase = (key) => {
  let status;
  switch (key) {
    case OrderDeliveryStatus.IN_PROGRESS:
      status = 'Waiting to Accept';
      break;
    case OrderDeliveryStatus.ACCEPTED:
      status = 'Order Accepted';
      break;
    case OrderDeliveryStatus.SHIPPED:
      status = 'Shipped';
      break;
    case OrderDeliveryStatus.PICK_UP_READY:
      status = 'Pick-up Ready';
      break;
    case OrderDeliveryStatus.DELIVERED:
      status = 'Delivered';
      break;
    case OrderDeliveryStatus.RETURNED:
      status = 'Returned';
      break;
    case OrderDeliveryStatus.CANCELLED:
      status = 'Cancelled';
      break;
    default:
      status = null;
  }
  return status;
};

export const shippingChargesKey = (key) => {
  let reqKey;
  switch (key) {
    case '0 to 2Kg':
      reqKey = 'for0';
      break;
    case '2 to 5Kg':
      reqKey = 'for5';
      break;
    case '5 to 10Kg':
      reqKey = 'for10';
      break;
    case '10 to 20Kg':
      reqKey = 'for20';
      break;
    case '20 or more':
      reqKey = 'forMore';
      break;
    default:
      reqKey = null;
  }
  return reqKey;
};
