import theme from '../../utils/theme';

export const PolicyList = [
	{
		name: 'Terms & Conditions',
		helperText: 'View Terms and Conditions',
		icon: 'terms',
		iconColor: '#5e35b1',
		screenName: 'Terms&Conditions',
	},
	{
		name: 'Privacy Policy',
		helperText: 'View Privacy, Data Policies',
		icon: 'policy',
		iconColor: '#fb8c00',
		screenName: 'PrivacyPolicy',
	},
	{
		name: 'Cancellation/Refund Policy',
		helperText: 'View Cancellation/Refund Policy',
		icon: 'refund',
		iconColor: '#388e3c',
		screenName: 'Cancellation&RefundPolicy',
	},
	{
		name: 'Shipping & Return Policy',
		helperText: 'View Shipping & Return Policy',
		icon: 'return',
		iconColor: theme.PRIMARY_BLUE,
		screenName: 'Shipping&ReturnPolicy',
	},
	{
		name: 'Mynyfy Guidelines for Advertisements',
		helperText: 'View Guidelines for publishing Ads on Mynyfy',
		icon: 'ads',
		iconColor: '#843284',
		screenName: 'PublishAdPolicy',
	},
	{
		name: 'About Us',
		helperText: 'View About Mynyfy',
		icon: 'about',
		iconColor: '#ffa500',
		screenName: 'AboutUs',
	},
	{
		name: 'Contact Us',
		helperText: 'Contact Us for any queries',
		icon: 'contact',
		iconColor: theme.PRIMARY_BLUE,
		screenName: 'ContactUs',
	},
];
