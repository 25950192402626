import { myApi } from '../utils/apiHelper';

class SellerCustomerService {
  async customerCheck(params) {
    try {
      return myApi.get('/sellerCustomer/exsisted', { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getCustomers(params) {
    try {
      return myApi.get('/getSellerCustomers', { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const sellerCustomerService = new SellerCustomerService();
export default sellerCustomerService;
export { sellerCustomerService };
