import React from 'react';
import MynyfyText from './MynyfyText';
import theme from '../utils/theme';
import _ from 'lodash';
import MynyfyModal from './MynyfyModal';
import { ReactComponent as AnnouncementIcon } from '../assets/svg/announcement.svg';
import { ReactComponent as SeeAndWinIcon } from '../assets/svg/see_and_win.svg';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const imgSize = 32;
const color = theme.PRIMARY_BLUE;

const SelectAdType = ({ isVisible, closeModal, adScreen, flashAdScreen }) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;

  return (
    <MynyfyModal open={isVisible} close={() => closeModal()} style={{ paddingBottom: 0 }}>
      <div style={{ maxHeight: '80vh', backgroundColor: '#fff', overflow: 'scroll' }}>
        <div style={{ paddingHorizontal: 10 }}>
          <MynyfyText
            title={'Select Ad Type'}
            bold
            h6
            style={{ padding: '0px 10px 30px' }}
            center
          />
          <div className='rowSE' style={{ paddingBottom: 15, paddingHorizontal: 3 }}>
            <div
              style={{ textAlign: 'center' }}
              className='cursor'
              onClick={() => {
                closeModal();
                if (isLoggedIn) navigate(adScreen);
                else navigate('/SignIn');
              }}>
              <SeeAndWinIcon width={imgSize} height={imgSize} fill={color} />
              <MynyfyText title={'Regular Ad'} h6 style={{ marginTop: 5 }} />
            </div>
            <div
              style={{ textAlign: 'center' }}
              className='cursor'
              onClick={() => {
                closeModal();
                if (isLoggedIn) navigate(flashAdScreen);
                else navigate('/SignIn');
              }}>
              <AnnouncementIcon width={imgSize} height={imgSize} fill={color} />
              <MynyfyText title={'Flash Ad'} h6 style={{ marginTop: 5 }} />
            </div>
          </div>
        </div>
      </div>
    </MynyfyModal>
  );
};

export default SelectAdType;
