import React, { Fragment, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import SignInModal from '../../Auth/SignInModal';
import transactionService from '../../../services/transactionService';
import { fetchMynts } from '../../../redux/actions/mynts.action';
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import { fetchJackpot } from '../../../redux/actions/contest.action';
import { objectReplace } from '../../../utils/CommonMethods';
import contestService from '../../../services/contestService';
import { PlayCircleFilled } from '@mui/icons-material';
import './jackpot.scss';

const JackpotVideo = ({ contestDetails, style, className, playBtnSize }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const id = user.user?.id || null;
  const jackpot = useSelector((state) => state.contest?.jackpot);

  const [openSignIn, setOpenSignIn] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);

  const joinJackpotHandler = async () => {
    if (isLoggedIn) {
      if (!contestDetails.isContested) {
        let data = { _id: contestDetails._id, buyerId: id, image: null, amount: 0 };
        contestService
          .enterContest(data)
          .then((res) => {
            if (res.success) {
              let reqObj = jackpot?.find((e) => e._id == contestDetails._id);
              if (reqObj) {
                reqObj.isContested = true;
                let newData = objectReplace(jackpot, reqObj, '_id');
                dispatch(fetchJackpot(newData));
              }
              getPoints();
              dispatch(
                snackbarInfo({
                  open: true,
                  type: 'success',
                  message: `Thank you for Participating in ${contestDetails.name}`,
                })
              );
            } else {
              dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
            }
          })
          .finally(() => setVideoPlay(false));
      } else setVideoPlay(false);
    } else {
      setOpenSignIn(true);
      setVideoPlay(false);
    }
  };

  const getPoints = () => {
    transactionService.getPoints().then((res) => {
      if (res?.data?.points) dispatch(fetchMynts(res?.data?.points));
    });
  };

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <ReactPlayer
          url={contestDetails.video}
          config={{
            youtube: { playerVars: { showinfo: 0, rel: 0, controls: 0 } },
            vimeo: { playerOptions: { title: false } },
          }}
          width={'100%'}
          height={'100%'}
          onEnded={() => joinJackpotHandler(contestDetails)}
          className={className || ''}
          style={style}
          playing={videoPlay}
        />
        {contestDetails.video?.includes('vimeo') && !videoPlay ? (
          <PlayCircleFilled
            onClick={() => setVideoPlay(!videoPlay)}
            className='videoPlayBtn'
            style={{ fontSize: playBtnSize || 30 }}
          />
        ) : null}
      </div>
      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </Fragment>
  );
};

export default JackpotVideo;
