import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const MynyfyButton = ({
  label,
  disabled,
  startIcon,
  endIcon,
  fullWidth,
  size,
  variant,
  className,
  style,
  containerStyle,
  rest,
  elevation,
  loading,
  onClick,
  center,
}) => {
  return (
    <div style={{ margin: 5, ...(center && { textAlign: 'center' }), ...containerStyle }}>
      <Button
        disabled={disabled || false}
        startIcon={startIcon || null}
        endIcon={endIcon || null}
        fullWidth={fullWidth || false}
        size={size || 'large'}
        variant={variant || 'contained'}
        disableElevation={elevation || true}
        style={{
          borderRadius: 8,
          textTransform: 'capitalize',
          fontWeight: 400,
          height: 40,
          ...style,
        }}
        className={className || ''}
        onClick={onClick}
        {...rest}
      >
        {loading ? <CircularProgress size={26} style={{ color: '#fff' }} /> : label}
      </Button>
    </div>
  );
};
export default MynyfyButton;
