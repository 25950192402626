import { SHA256 } from 'crypto-js';
import Axios from 'axios';
import { PaymentGateways } from '../utils/enums';
import { getOS, merchantTxnId } from '../utils/CommonMethods';

class PhonePeService {
  async phonePePayloadKeys(obj, endPoint) {
    let objJsonStr = JSON.stringify(obj);
    let base64Str = btoa(objJsonStr);
    let SHA256Str =
      SHA256(base64Str + endPoint + process.env.REACT_APP_PP_SALT_KEY) +
      '###' +
      process.env.REACT_APP_PP_SALT_INDEX;
    return { base64Str, SHA256Str };
  }

  async phonePeUPIPaymentObj({ amount, type, userId, mobileNumber }) {
    try {
      if (amount && type && userId && mobileNumber) {
        let myTxnId = merchantTxnId({ userId, mobile: mobileNumber });
        let GST = type == 'LuckyDraw' ? 0.18 : 0;
        let reqAmount = (amount + amount * GST).toFixed(2);
        let paymentObj = {
          merchantId: process.env.REACT_APP_PP_MERCHANT_ID,
          merchantTransactionId: myTxnId,
          merchantUserId: `MYMU-${userId}`,
          amount: reqAmount.replace('.', ''),
          callbackUrl: '',
          mobileNumber: mobileNumber?.toString(),
          deviceContext: {
            deviceOS: getOS() === 'ios' ? 'IOS' : 'ANDROID',
          },
          paymentInstrument: {
            type: 'UPI_INTENT',
            targetApp: getOS() === 'android' ? 'PHONEPE' : 'com.phonepe.app',
          },
        };
        return { success: true, paymentObj };
      } else {
        throw 'All fields are mandatory';
      }
    } catch (err) {
      return { success: false, message: err };
    }
  }

  async initiatePayment({ data, dispatch, action }) {
    let endPoint = '/pg/v1/pay';
    try {
      let keys = await this.phonePePayloadKeys(data, endPoint);
      const options = {
        method: 'POST',
        url: process.env.REACT_APP_PP_BASE_API + endPoint,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          'X-VERIFY': keys.SHA256Str,
        },
        data: { request: keys.base64Str },
      };

      return Axios.request(options)
        .then((response) => {
          // console.log('success:', response);
          if (response.data.success) {
            return {
              success: true,
              txnId: response.data.data.merchantTransactionId,
              txnLink: response.data.data.instrumentResponse.intentUrl,
            };
          }
        })
        .catch((error) => {
          dispatch(
            action({
              open: true,
              type: 'error',
              message: ['Something went wrong', 'Please try again'],
            })
          );
          return { success: false };
        });
    } catch (err) {
      console.log(err);
    }
  }

  async transactionStatusCheck({
    txnId,
    submitHandler,
    extraData,
    clickHandler,
    dispatch,
    action,
  }) {
    let endPoint = `/pg/v1/status/${process.env.REACT_APP_PP_MERCHANT_ID}/${txnId}`;
    try {
      const options = {
        method: 'GET',
        url: process.env.REACT_APP_PP_BASE_API + endPoint,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          'X-VERIFY':
            SHA256(endPoint + process.env.REACT_APP_PP_SALT_KEY) +
            '###' +
            process.env.REACT_APP_PP_SALT_INDEX,
          'X-MERCHANT-ID': process.env.REACT_APP_PP_MERCHANT_ID,
        },
      };

      return Axios.request(options)
        .then((response) => {
          // console.log(response.data);
          let res = response.data.data;
          if (response.data.success && response.data.code == 'PAYMENT_SUCCESS') {
            if (res?.state == 'COMPLETED' && res?.transactionId) {
              let reqAmount = res.amount.toString().slice(0, -2);
              let paymentDetails = {
                amount: reqAmount,
                merchantTransactionId: res.merchantTransactionId,
                transactionId: res.transactionId,
                utr: res.paymentInstrument.utr,
                type: PaymentGateways.PHONE_PE,
              };
              submitHandler({ paymentDetails, extraData });
            } else {
              dispatch(
                action({
                  open: true,
                  type: 'error',
                  message: [
                    'Sorry! There is an issue with the payment',
                    "Don't worry, we will resolve and place your order or amount will be refunded in 3 to 4 working days if debited any",
                  ],
                })
              );
              clickHandler(false);
            }
          } else if (response.data.success && response.data.code == 'PAYMENT_PENDING') {
            if (res?.state == 'PENDING') {
              dispatch(
                action({
                  open: true,
                  type: 'error',
                  message: [
                    'Your Transaction is not completed',
                    'Amount will be credited in 5 to 7 working days if debited any',
                  ],
                })
              );
              clickHandler(false);
            }
          } else if (response.data.success == false && response.data.code == 'PAYMENT_ERROR') {
            if (res?.state == 'FAILED') {
              dispatch(
                action({
                  open: true,
                  type: 'error',
                  message: [
                    'Your Transaction is Failed',
                    'Amount will be credited in 5 to 7 working days if debited any',
                  ],
                })
              );
              clickHandler(false);
            }
          }
        })
        .catch((error) => console.error('error:', error.response));
    } catch (err) {
      console.log(err);
    }
  }
}

export const PhonePeGateway = new PhonePeService();
