import React from 'react';
import TopBar from './TopBar';
import './home.scss';
import HomeLayout from './HomeLayout';
import FlashAds from '../FlashAds';
import DownloadApp from '../../components/DownloadApp';

const Home = () => {
  return (
    <div id='Home'>
      <TopBar />
      <DownloadApp />
      <HomeLayout />
      <FlashAds />
    </div>
  );
};

export default Home;
