import { myApi, myPubApi } from '../utils/apiHelper';

class PromoService {
  async getPromos(queryParam) {
    try {
      return await myPubApi.get(`/getPromo`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getPromosForOrder(queryParam) {
    try {
      return await myApi.get(`/getPromosForOrder`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getPromoById(id) {
    try {
      return await myPubApi.get(`/promoById/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getNonViewedPromoCount(queryParam) {
    try {
      return await myApi.get(`/getNonViewedPromoCount`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getBuyerClaimedPromos(queryParam) {
    try {
      return await myApi.get(`/getBuyerClaimedPromos`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getBuyerPromosCount(queryParam) {
    try {
      return await myApi.get(`/getBuyerPromosCount`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getCouponCodeOfPromo(promoId) {
    try {
      return await myApi.get(`/getCouponCodeOfPromo/${promoId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async claimPromo(id) {
    try {
      return await myApi.put(`/claimPromo/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async claimPromoByScan(id, sellerUpiId) {
    try {
      return await myApi.put(`/claimPromoByScan/${id}/${sellerUpiId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async viewPromo(id, data) {
    try {
      return await myApi.put(`/viewPromo/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async purchaseDeal(data) {
    try {
      return await myApi.post(`/purchaseDeal`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const promoService = new PromoService();
export default promoService;
export { promoService };
