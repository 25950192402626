import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import SelectSeller from '../../components/SelectSeller';
import SelectAddress from '../../components/SelectAddress';
import { OrderType, PaymentMode } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import { useNavigate } from 'react-router';
import './products.scss';
import { ChevronRight, ShoppingBagOutlined } from '@mui/icons-material';

const ProductCartHelper = ({ navFrom, sellerDetails, brandDetails, table, from }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bag = useSelector((state) => state.bag);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  const [openSellersList, setOpenSellersList] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);

  const handelViewCart = ({ seller, address, orderType }) => {
    let reqAddress = address || selectedAddress;
    let shippingType;
    if (orderType === OrderType.HOME_DELIVERY) {
      shippingType =
        seller?.city?.data == reqAddress.city
          ? 'insideCity'
          : seller?.state?.data == reqAddress.state
          ? 'insideState'
          : 'outsideState';
    }
    navigate('/Products/Bag', {
      state: {
        name: seller.name,
        orgId: seller._id,
        orgPayModes: paymentModeHandler(seller),
        deleveryAddress: reqAddress,
        orderType,
        shippingType,
        policies: seller?.policies,
        upiId: seller?.upiId,
        upiQr: seller?.bankDetails?.upiQr?.url,
        isOffline: [OrderType.DINE_IN, OrderType.TAKE_AWAY].includes(orderType),
        dineTablesList: seller.dineTablesList,
        table,
        isRestaurant: sellerDetails?.ownerId?.categories?.find(
          (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
        )
          ? true
          : false,
        canAccess: true,
      },
    });
  };

  const paymentModeHandler = (seller) => {
    let modes = [];
    if (table && !seller.receiveDineInPayment) {
      modes.push(PaymentMode.CASH);
    } else {
      if (seller.upi && (seller.upiId || seller.bankDetails?.upiQr?.url)) {
        modes.push(PaymentMode.UPI_QR);
      }
      if (seller.cash) modes.push(PaymentMode.CASH);
      if (seller.online) modes.push(PaymentMode.ONLINE);
    }
    return modes;
  };

  const addressHandler = (e) => {
    if (e.orderType) setOrderType(e.orderType);
    else setSelectedAddress(e.address);

    if (navFrom == 'seller') {
      handelViewCart({
        seller: sellerDetails,
        address: e.address,
        orderType: e.orderType || OrderType.HOME_DELIVERY,
      });
    } else {
      setOpenAddressModal(false);
      setTimeout(() => setOpenSellersList(true), 500);
    }
  };

  const cartHandler = () => {
    if (isLoggedIn) {
      if (table) addressHandler({ orderType: OrderType.DINE_IN });
      else {
        if (sellerDetails?.pickUp && sellerDetails?.homeDelivery) {
          setOpenAddressModal(true);
        } else if (sellerDetails?.homeDelivery) {
          setOpenAddressModal(true);
        } else if (sellerDetails?.pickUp) {
          addressHandler({ orderType: OrderType.TAKE_AWAY });
        }
      }
    } else {
      setOpenSignIn(true);
    }
  };

  return (
    <Fragment>
      {bag?.calculation?.qty ? (
        <Fragment>
          {from == 'web' ? (
            <div
              id='Products'
              className='cursor'
              style={{ position: 'relative', marginLeft: 8, padding: 0 }}
              onClick={() => cartHandler()}>
              <ShoppingBagOutlined style={{ fontSize: 30, display: 'block' }} />
              <MynyfyText title={bag?.calculation?.qty || 0} className='webCartNum' />
            </div>
          ) : (
            <div id='Products' style={{ padding: 0 }}>
              <div className='cartBtnContainer cursor' onClick={() => cartHandler()}>
                <div className='cartBtn rowSB'>
                  <MynyfyText
                    title={
                      (bag?.calculation?.qty || 0) +
                      (bag?.calculation?.qty == 1 ? ' Item ' : ' Items ') +
                      'Added'
                    }
                    color={'#fff'}
                    className='cartNum'
                  />
                  <div className='row'>
                    <MynyfyText title={'View Bag'} color={'#fff'} />
                    <ChevronRight style={{ color: '#fff' }} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <SelectSeller
            isVisible={openSellersList}
            closeModal={() => setOpenSellersList(false)}
            brandId={bag?.id}
            selectedAddress={selectedAddress}
            selectedSeller={selectedSeller}
            setData={(e) => {
              setSelectedSeller(e);
              handelViewCart({ seller: e });
            }}
          />
          <SelectAddress
            isVisible={openAddressModal}
            closeModal={() => setOpenAddressModal(false)}
            selectedAddress={selectedAddress}
            from={navFrom}
            fromId={bag?.id}
            fromName={bag?.about?.name}
            isOffline={!sellerDetails?.homeDelivery}
            sellerDetails={sellerDetails}
            isRestaurant={
              sellerDetails?.ownerId?.categories?.find(
                (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
              )
                ? true
                : false
            }
            orderType={orderType}
            setData={(e) => addressHandler(e)}
          />
        </Fragment>
      ) : null}

      <SignInModal
        open={openSignIn}
        close={() => setOpenSignIn(false)}
        isGuest={table ? true : false}
        guestLoginForDineIn={() => addressHandler({ orderType: OrderType.DINE_IN })}
        sellerDetails={{ image: sellerDetails?.orgImages[0].url, storename: sellerDetails?.name }}
      />
    </Fragment>
  );
};

export default ProductCartHelper;
