import {
  DAY_DEALS,
  COUPONS,
  REWARDS,
  SORTED_DAY_DEALS,
  SORTED_REWARDS,
  SORTED_COUPONS,
  PROMOS,
  SORTED_PROMOS,
  FOOD_COUPONS,
  SORTED_FOOD_COUPONS,
} from '../actionTypes';

const INITIAL_STATE = {
  dayDeals: {
    actual: null,
    sorted: null,
  },
  coupons: {
    actual: null,
    sorted: null,
  },
  foodCoupons: {
    actual: null,
    sorted: null,
  },
  rewards: {
    actual: null,
    sorted: null,
  },
  promos: {
    actual: null,
    sorted: null,
  },
};

const promoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DAY_DEALS:
      return {
        ...state,
        dayDeals: { ...state.dayDeals, actual: action.payload },
      };
    case SORTED_DAY_DEALS:
      return {
        ...state,
        dayDeals: { ...state.dayDeals, sorted: action.payload },
      };
    case COUPONS:
      return {
        ...state,
        coupons: { ...state.coupons, actual: action.payload },
      };
    case SORTED_COUPONS:
      return {
        ...state,
        coupons: { ...state.coupons, sorted: action.payload },
      };
    case FOOD_COUPONS:
      return {
        ...state,
        foodCoupons: { ...state.foodCoupons, actual: action.payload },
      };
    case SORTED_FOOD_COUPONS:
      return {
        ...state,
        foodCoupons: { ...state.foodCoupons, sorted: action.payload },
      };
    case REWARDS:
      return {
        ...state,
        rewards: { ...state.rewards, actual: action.payload },
      };
    case SORTED_REWARDS:
      return {
        ...state,
        rewards: { ...state.rewards, sorted: action.payload },
      };
    case PROMOS:
      return {
        ...state,
        promos: { ...state.promos, actual: action.payload },
      };
    case SORTED_PROMOS:
      return {
        ...state,
        promos: { ...state.promos, sorted: action.payload },
      };
    default:
      return state;
  }
};

export default promoReducer;
