import { NOTIFICATIONS } from '../actionTypes';

const INITIAL_STATE = null;

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS:
      return action.payload;
    default:
      return state;
  }
};

export default notificationsReducer;
