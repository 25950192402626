import React from 'react';
import MynyfyTab from '../../components/MynyfyTab';
import BrandsList from './BrandsList';
import { useSelector } from 'react-redux';
import {
  fetchActualBrands,
  fetchActualFavBrands,
  fetchBrands,
  fetchFavBrands,
} from '../../redux/actions/brand.action';

const Brands = () => {
  const brands = useSelector((state) => state.brands?.brands);
  const actualBrands = useSelector((state) => state.brands?.actualBrands);
  const favBrands = useSelector((state) => state.brands?.favBrands);
  const actualFavBrands = useSelector((state) => state.brands?.actualFavBrands);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  return (
    <MynyfyTab
      tabs={['Brands', 'Favourite Brands']}
      tabComponent={[
        <BrandsList
          isFav={false}
          data={brands}
          actualData={actualBrands}
          action={fetchBrands}
          actualAction={fetchActualBrands}
          emptyListMsg={'No Brands are there at your location'}
        />,
        <BrandsList
          isFav={true}
          data={favBrands}
          actualData={actualFavBrands}
          action={fetchFavBrands}
          actualAction={fetchActualFavBrands}
          emptyListMsg={
            isLoggedIn
              ? "You haven't added any Brands to your favourites"
              : 'Please Login for your favourite Brands'
          }
        />,
      ]}
    />
  );
};

export default Brands;
