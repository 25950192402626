import { myApi, myPubApi } from '../utils/apiHelper';

class ContestService {
  async getContests(queryParam) {
    try {
      return await myPubApi.get(`/draw`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getContestByVoucher(queryParam) {
    try {
      return await myPubApi.get(`/getDrawByVoucher`, { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async enterContest(data) {
    try {
      return await myApi.post(`/draw/enter`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async joinScanAndWin(data) {
    try {
      return await myApi.post(`/scanAndWin/enter`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async viewContest(id, data) {
    try {
      return await myApi.put(`/viewContest/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async interstedContest(id, data) {
    try {
      return await myApi.put(`/interestedBuyer/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async createAd(data) {
    try {
      return await myApi.post(`/draw/createAd`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async checkFirstAd() {
    try {
      return await myApi.get(`/draw/checkFirstAd`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async updateWebsiteClicks(id) {
    try {
      return await myApi.put(`/updateWebsiteClicks/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getMyAds(queryParam) {
    try {
      return await myApi.get('/draw/getUserAds', { params: queryParam }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async userAdById(id) {
    try {
      return await myApi.get(`/draw/userAdById/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async updateAd(id, data) {
    try {
      return await myApi.put(`/draw/updateAd/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getInterestedUsers(id, params) {
    try {
      return await myApi.get(`/draw/getInterestedUsers/${id}`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const contestService = new ContestService();
export default contestService;
export { contestService };
