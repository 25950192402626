import React, { Fragment, useEffect, useState } from 'react';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfyHeader from '../../components/MynyfyHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import commonService from '../../services/commonService';
import _ from 'lodash';
import MynyfySelect from '../../components/MynyfySelect';
import validate from '../../utils/validate';
import { useDispatch, useSelector } from 'react-redux';
import { pincodeValidation } from '../../utils/validators';
import profileService from '../../services/profileService';
import { WATCH_PROFILE } from '../../redux/actionTypes';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfyRadioButton from '../../components/MynyfyRadioButton';
import MynyfyText from '../../components/MynyfyText';

let VALIDATOR = {
  pincode: [
    (value) =>
      value
        ? pincodeValidation.test(value) === false
          ? 'Please enter a valid Pincode'
          : null
        : 'Please enter Pincode',
  ],
  selectedState: [(value) => (_.isEmpty(value) ? `Please select State` : null)],
  selectedCity: [(value) => (_.isEmpty(value) ? `Please select City` : null)],
  selectedArea: [(value) => (_.isEmpty(value) ? `Please select Area` : null)],
  lane1: [(value) => (_.isEmpty(value) ? `Please enter lane 1` : null)],
  landmark: [(value) => (_.isEmpty(value) ? `Please enter landmark` : null)],
  locationUrl: [(value) => (_.isEmpty(value) ? `Please enter your google map location URL` : null)],
  addressType: [(value) => (_.isEmpty(value) ? `Please select Address Type` : null)],
};

const EditAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  const states = useSelector((state) => state.appData?.states);
  const user = useSelector((state) => state.user?.user);
  const id = user?.id || null;
  const profileId = user?.profile || null;

  const [address, setAddress] = useState(null);
  const [lane1, setLane1] = useState('');
  const [lane2, setLane2] = useState('');
  const [landmark, setLandmark] = useState('');
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [pincode, setPincode] = useState('');
  const [locationUrl, setLocationUrl] = useState('');
  const [error, setError] = useState({});
  const [isClicked, setClicked] = useState(false);
  const [addressType, setAddressType] = useState(null);
  const [saveAs, setSaveAs] = useState(null);

  useEffect(() => {
    let addressDetails = route?.state?.address;
    if (addressDetails) {
      setAddress(addressDetails);
      if (addressDetails?.lane1) setLane1(addressDetails?.lane1);
      if (addressDetails?.lane2) setLane2(addressDetails?.lane2);
      if (addressDetails?.landMark) setLandmark(addressDetails?.landMark);
      if (addressDetails?.pincode) setPincode(`${addressDetails?.pincode}`);
      if (addressDetails?.locationUrl) setLocationUrl(`${addressDetails?.locationUrl}`);
      if (addressDetails?.type) setAddressType(`${addressDetails?.type}`);
      saveInitialLocData(addressDetails);
    }
  }, []);

  const saveInitialLocData = async (addressDetails) => {
    if (addressDetails?.state && states) {
      await appDataApi(addressDetails?.state, setCities).then(async (citiesRes) => {
        if (addressDetails?.city) {
          await appDataApi(addressDetails?.city, setAreas).then(async (areasRes) => {
            if (addressDetails?.locality) {
              setAddressLoc(areasRes, addressDetails?.locality, setSelectedArea);
            }
          });
          setAddressLoc(citiesRes, addressDetails?.city, setSelectedCity);
        }
      });
      setAddressLoc(states, addressDetails?.state, setSelectedState);
    }
  };

  const setAddressLoc = (arr, key, setData) => {
    let reqObj = arr.find((e) => e.data === key);
    if (reqObj) {
      setData({ label: reqObj.name, value: reqObj._id, data: reqObj.data });
    }
  };

  const appDataApi = async (key, setData) => {
    return commonService.categorySort(key).then((res) => {
      let sortedData = _.orderBy(res, ['name'], ['asc']);
      setData(sortedData);
      return sortedData;
    });
  };

  const submitHandler = () => {
    let obj = { lane1, selectedState, selectedCity, selectedArea, pincode, addressType };
    if (addressType == 'Work') obj.landmark = landmark;
    let isError = validate(obj, VALIDATOR);
    setError(isError);
    if (_.isEmpty(isError)) {
      setClicked(true);
      let data = {
        lane1: lane1,
        lane2: lane2,
        locality: selectedArea?.data,
        city: selectedCity?.data,
        state: selectedState?.data,
        country: 'India',
        pincode: pincode,
        type: addressType,
      };
      if (address?._id) data.addressId = address._id;
      if (locationUrl) data.locationUrl = locationUrl;
      if (saveAs) data.saveAs = saveAs;
      if (landmark) data.landmark = landmark;

      profileService.profileAddressUpdate(data, id, profileId).then((res) => {
        if (res.success) {
          dispatch({ type: WATCH_PROFILE });
          dispatch(
            snackbarInfo({ type: 'success', open: true, message: 'Address updated successfully' })
          );
          setClicked(false);
          navigate(-1);
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          setClicked(false);
        }
      });
    }
  };

  return (
    <Fragment>
      <MynyfyHeader title={route?.state?.title || 'Add Address'} />
      <div id='Address'>
        <div className='row' style={{ marginLeft: -12 }}>
          {['Home', 'Work'].map((res, i) => (
            <MynyfyRadioButton
              key={i}
              title={res}
              onClick={() => {
                setAddressType(res);
                setLandmark('');
              }}
              isSelected={res == addressType}
            />
          ))}
        </div>
        {error?.addressType ? (
          <MynyfyText
            title={error.addressType}
            pSmall
            color={'#d32f2f'}
            style={{ marginLeft: 18 }}
          />
        ) : null}
        <MynyfyInput
          fullWidth={true}
          value={lane1}
          onChange={(e) => setLane1(e.target.value)}
          helperText={error.lane1}
          error={error?.lane1 ? true : false}
          placeHolder={
            addressType == 'Work'
              ? 'Building Name/Floor No./Desk No.*'
              : 'H.No./Building Name/Floor No.*'
          }
          className='addressField'
        />
        {addressType == 'Work' ? (
          <MynyfyInput
            fullWidth={true}
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
            helperText={error?.landmark ? 'Please enter Office Name/Organisation Name' : null}
            error={error?.landmark ? true : false}
            placeHolder={'Office Name/Organisation Name*'}
            className='addressField'
          />
        ) : null}
        <MynyfyInput
          fullWidth={true}
          value={lane2}
          onChange={(e) => setLane2(e.target.value)}
          helperText={error.lane2}
          error={error?.lane2 ? true : false}
          placeHolder={'Colony/Street Name*'}
          className='addressField'
        />
        {addressType == 'Home' ? (
          <MynyfyInput
            fullWidth={true}
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
            helperText={error.landmark}
            error={error?.landmark ? true : false}
            placeHolder={'Landmark*'}
            className='addressField'
          />
        ) : null}
        <MynyfySelect
          placeholder={'State*'}
          value={selectedState}
          error={error.selectedState ? true : false}
          helperText={error.selectedState}
          onChange={async (e) => {
            setSelectedState(e);
            setSelectedCity(null);
            setCities([]);
            setSelectedArea(null);
            setAreas([]);
            await appDataApi(e.data, setCities);
          }}
          list={states.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          className='dropField'
          helperTextStyle={{ margin: '-5px 19px 0px', paddingBottom: 4 }}
        />
        <MynyfySelect
          placeholder={'City*'}
          value={selectedCity}
          error={error.selectedCity ? true : false}
          helperText={error.selectedCity}
          onChange={async (e) => {
            setSelectedCity(e);
            setSelectedArea(null);
            setAreas([]);
            await appDataApi(e.data, setAreas);
          }}
          list={cities.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          className='dropField'
          helperTextStyle={{ margin: '-5px 19px 0px', paddingBottom: 4 }}
        />
        <MynyfySelect
          placeholder={'Area*'}
          value={selectedArea}
          error={error.selectedArea ? true : false}
          helperText={error.selectedArea}
          onChange={(e) => setSelectedArea(e)}
          list={areas.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          className='dropField'
          helperTextStyle={{ margin: '-5px 19px 0px', paddingBottom: 4 }}
        />
        <MynyfyInput
          fullWidth={true}
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          helperText={error.pincode}
          error={error?.pincode ? true : false}
          placeHolder={'Pincode*'}
          className='addressField'
        />
        <MynyfyInput
          fullWidth={true}
          value={locationUrl}
          onChange={(e) => setLocationUrl(e.target.value)}
          helperText={error.locationUrl}
          error={error?.locationUrl ? true : false}
          placeHolder={'Google Map Location URL'}
          className='addressField'
        />
        <MynyfyInput
          fullWidth={true}
          value={saveAs}
          onChange={(e) => setSaveAs(e.target.value)}
          helperText={error.saveAs}
          error={error?.saveAs ? true : false}
          placeHolder={'Save As'}
          className='addressField'
        />

        <MynyfyButton
          label={route?.state?.btnText || 'save'}
          onClick={() => submitHandler()}
          containerStyle={{ textAlign: 'center', margin: 20 }}
          loading={isClicked}
        />
      </div>
    </Fragment>
  );
};

export default EditAddress;
