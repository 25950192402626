// Note: When ever you are changing the positions, please check with corresponding files and change the index in those
// Note: Don't add extra types to existing without checking with backend
// Note: Don't change value key data

import theme from './theme';

export const WeightRanges = [
  { label: '0 to 2Kg', value: '0 to 2Kg' },
  { label: '2 to 5Kg', value: '2 to 5Kg' },
  { label: '5 to 10Kg', value: '5 to 10Kg' },
  { label: '10 to 20Kg', value: '10 to 20Kg' },
  { label: '20 or more', value: '20 or more' },
];

export const DiscountTypes = [
  { label: theme.RUPEE, value: 'amount' },
  { label: '%', value: 'percentage' },
];
