import { myApi, myPubApi } from '../utils/apiHelper';

class ProductOrderService {
  async getProductOrderPrice(data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.post(`/productOrder/new/price`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async createProductOrder(data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.post('/productOrder/create', data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async confirmProductOrder(data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.put('/productOrder/confirm', data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async deleteProductOrder(id) {
    try {
      return await myApi.put(`/productOrder/delete/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getProductOrders(data) {
    try {
      return await myApi.get(`/getProductOrder`, { params: data }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async getProductOrderById(id) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/productOrderById/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async updateOrderDetails(id, data) {
    try {
      return await myApi.put(`/productOrder/updateTrackingDetails/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      console.log(err);
      return err.response.data;
    }
  }

  async getTableOrders(data, id) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/getTableOrders/${id}`, { params: data }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getProductOrderIdByTable(data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.get(`/getProductOrderIdByTable`, { params: data }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async editProductOrder(data, id) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.put(`/productOrder/edit/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async deleteProductsOfOrder(id, data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.put(`/productOrder/deleteProductsOfOrder/${id}`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const productOrderService = new ProductOrderService();
export default productOrderService;
export { productOrderService };
