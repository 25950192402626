import { myPubApi } from '../utils/apiHelper';

class VideoService {
  async getVideos(data) {
    try {
      return await myPubApi.get(`/getVideos`, { params: data }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getVideoById(id) {
    try {
      return await myPubApi.get(`/getVideoById/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}
const videoService = new VideoService();
export default videoService;
export { videoService };
