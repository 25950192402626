import { FLASH_ADS, SEEN_FLASH_ADS } from '../actionTypes';

const INITIAL_STATE = {
  flashAds: null,
  seenAds: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FLASH_ADS:
      return {
        ...state,
        flashAds: action.payload,
      };
    case SEEN_FLASH_ADS:
      return {
        ...state,
        seenAds: action.payload,
      };
    default:
      return state;
  }
};
