import React, { useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import LocationFields from './LocationFields';
import './location.scss';
import validate from '../../utils/validate';
import MynyfyButton from '../../components/MynyfyButton';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation } from '../../redux/actions/location.action';
import MynyfyModal from '../../components/MynyfyModal';
import { RELOAD_WITH_LOC_WATCHER } from '../../redux/actionTypes';
import profileService from '../../services/profileService';
import { HighlightOffRounded } from '@mui/icons-material';

let VALIDATOR = {
  selectedState: [(value) => (value ? null : 'Select your State')],
  selectedCity: [(value) => (value ? null : 'Select your City')],
  selectedArea: [(value) => (value ? null : 'Select your Area')],
};

const LocationModal = ({ isOpen, close, closeBtn }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user?.user);
  const id = user?.id || null;
  const profileId = user?.profile || null;

  const [error, setError] = useState({});
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const locationApi = () => {
    let obj = {
      selectedState,
      selectedCity,
      selectedArea,
    };

    let isErrors = validate(obj, VALIDATOR);
    setError(isErrors);

    if (_.isEmpty(isErrors)) {
      dispatch(updateLocation({ state: selectedState, city: selectedCity, area: selectedArea }));
      if (id && profileId) {
        let data = { state: selectedState?._id, city: selectedCity?._id, area: selectedArea?._id };
        profileService.profileUpdate(data, id, profileId).catch((e) => {});
      }
      dispatch({ type: RELOAD_WITH_LOC_WATCHER });
      close(true);
    }
  };

  const setDataHandler = (key, value) => {
    if (key === 'state') {
      setSelectedState(value);
    } else if (key === 'city') {
      setSelectedCity(value);
    } else if (key === 'area') {
      setSelectedArea(value);
    }
  };

  return (
    <MynyfyModal open={isOpen} close={close}>
      <div id='LocationModal'>
        <div className='rowSB' style={{ marginBottom: 20 }}>
          <div />
          <MynyfyText title={'Select Location'} center pBig bold />
          <HighlightOffRounded color='error' onClick={() => closeBtn()} />
        </div>
        <LocationFields menuPlacement='top' error={error} sendData={setDataHandler} />
        <MynyfyButton
          label={'Set'}
          containerStyle={{ textAlign: 'center' }}
          style={{ marginTop: 10 }}
          onClick={locationApi}
        />
      </div>
    </MynyfyModal>
  );
};

export default LocationModal;
