import moment from 'moment';
import _ from 'lodash';
import theme from '../utils/theme';
import { MynyfyEnums } from '../utils/MynyfyEnums';
import { useSelector } from 'react-redux';

const RegularAdInvoice = ({ details }) => {
  let amount = details?.transactionId?.amount || 0;
  let profile = useSelector((state) => state?.user?.profile);

  const starContent = ({ content, contentStyles, styles }) => {
    return `<div class="rowC" style="${styles || ''}">
							<p style="font-size: 16px; margin-bottom: -8px">***</p>
							&ensp;
							<p style="${contentStyles || ''}">${content}</p>
							&ensp;
							<p style="font-size: 16px; margin-bottom: -8px">***</p>
						</div>`;
  };

  return `
    <!DOCTYPE html>
    <head>
      <style>
        * { margin: 0; padding: 0; }
        .separator {border-bottom: 2px dashed gray; margin: 10px 0px;}
        .contentPadding {padding: 0px 15px;}
        .aboutOrder {padding-bottom: 5px;}
        .subHeading {width: 100px; font-size: 15px;}
        .row {display: flex; align-items: center;}
        .rowSB {display: flex; align-items: center; justify-content: space-between;}
        .rowC {display: flex; align-items: center; justify-content: center;}
        .sellerName {font-size: 20px; padding-bottom: 5px; text-transform: uppercase; font-weight: bold;}
        .sellerRegisteredName {font-size: 16px; padding: 5px 0px;}
      </style>
    </head>
    <body>
      <div style="padding: 20px;">
        <div style="text-align: center;">
          <p class="sellerName">${MynyfyEnums.COMPANY}</p>
					<p>${MynyfyEnums.PART1}</p>
					<p>${MynyfyEnums.PART2}</p>
					<p>${MynyfyEnums.PART3}</p>
					<p style="padding-top: 5px;">GSTIN NO: ${MynyfyEnums.GST_NO}</p>
          ${starContent({
            content: 'Tax Invoice',
            contentStyles: 'font-size: 18px; padding-top: 5px;',
          })}
        </div>
        <div class="separator"></div>
        <div class="contentPadding rowSB" style="align-items: flex-start;">
          <div>
            ${
              details?.invoice
                ? `<div class="aboutOrder row">
                    <p class="subHeading">Invoice No</p>
                    <p style="font-size: 15px;"> : ${details?.invoice}</p>
                  </div>`
                : ''
            }
            ${
              details?.createdAt
                ? `<div class="aboutOrder row">
                    <p class="subHeading">Date & Time</p>
                    <p class="font-size: 15px;"> : ${moment(details?.createdAt).format(
                      'DD MMMM YYYY, hh:mm:ss A'
                    )}</p>
                  </div>`
                : ''
            }
          </div>
          <div>
            <div class="aboutOrder row">
              <p class="subHeading">Name</p>
              <p style="font-size: 15px;"> : ${
                profile?.profile.firstName + ' ' + (profile?.profile.lastName || '')
              }</p>
            </div>
            <div class="aboutOrder row">
              <p class="subHeading">Mobile Number</p>
              <p class="font-size: 15px;"> : ${profile?.profile.mobile}</p>
            </div>
            <div class="aboutOrder row">
              <p class="subHeading">Email-Id</p>
              <p class="font-size: 15px;"> : ${profile?.profile.email || '---'}</p>
            </div>
          </div>
        </div>
        <div class="separator"></div>
        <div class="rowSB contentPadding">
          <p style="font-size: 15px; flex: 4;">Particulars</p>
          <p style="font-size: 15px; flex: 1.5; text-align: center;">Rate</p>
          <p style="font-size: 15px; flex: 1.5; text-align: center;">GST</p>
          <p style="font-size: 15px; flex: 1.5; text-align: center;">GST Amount</p>
          <p style="font-size: 15px; flex: 1.5; text-align: right;">Total Amount</p>
        </div>
        <div class="separator"></div>
        <div class="contentPadding">
          <div class="rowSB">
            <p style="font-size: 15px; flex: 4; text-transform: capitalize;">
              <span>Regular Ad - </span>
              <b>${details.name}</b>
            </p>
            <p style="font-size: 15px; flex: 1.5; text-align: center;">${
              amount ? theme.RUPEE + (amount / (1 + MynyfyEnums.GST)).toFixed(2) : 0
            }</p>
            <p style="font-size: 15px; flex: 1.5; text-align: center;">${
              MynyfyEnums.GST * 100 + '%'
            }</p>
            <p style="font-size: 15px; flex: 1.5; text-align: center;">${
              theme.RUPEE + (amount - amount / (1 + MynyfyEnums.GST)).toFixed(2)
            }</p>
            <p style="font-size: 15px; flex: 1.5; text-align: right;">${
              theme.RUPEE + amount.toFixed(2)
            }</p>
          </div>
        </div>
        <div class="separator"></div>
        ${starContent({
          content: 'Thank you, please post again',
          contentStyles: 'font-size: 15px; text-align: center;',
          styles: 'padding-bottom: 20px;',
        })}
      </div>
    </body>`;
};

export default RegularAdInvoice;
