import { ContestType } from '../../utils/enums';

export const contestDetailsOptions = (type, isHistory) => {
  let options = {
    isName: false,
    isDates: false,
    isNoOfWinners: false,
    isParticipants: true,
    isCats: false,
    isTerms: isHistory ? false : true,
    isWinners: isHistory,
  };
  if (type === ContestType.SHOP_AND_WIN || type === ContestType.JACKPOT) {
    options.isName = true;
    options.isDates = true;
    options.isNoOfWinners = true;
    options.isCats = true;
  }
  if (type === ContestType.SCAN_AND_WIN || type === ContestType.SHOP_AND_SCAN) {
    options.isNoOfWinners = true;
    options.isParticipants = false;
  }
  return options;
};
