import React from 'react';
import theme from '../utils/theme';

const MynyfyLoader = ({ paddingTop, color }) => {
  return (
    <div style={{ paddingTop: paddingTop || '45vh' }}>
      <div className='loader' style={{ color: color || theme.PRIMARY_BLUE }} />
    </div>
  );
};

export default MynyfyLoader;
