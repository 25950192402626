import { fork, put, call, takeLatest, select } from '@redux-saga/core/effects';
import authService from '../../services/authService';
import { WATCH_PROFILE } from '../actionTypes';
import { userProfile } from '../actions/user.action';
import { updateLocation } from '../actions/location.action';

function* fetchProfile() {
  const location = yield select((state) => state?.location?.location);
  try {
    const res = yield call(authService.profile);
    if (res.success) {
      if (!location && res.data?.profile) {
        let state = res.data?.profile?.state;
        let city = res.data?.profile?.city;
        let area = res.data?.profile?.area;
        if (state && city && area) {
          yield put(updateLocation({ state, city, area }));
        }
      }
      yield put(userProfile(res.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchProfile() {
  yield takeLatest(WATCH_PROFILE, fetchProfile);
}

const ProfileSagas = [fork(watchProfile)];
export default ProfileSagas;
