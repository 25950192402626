import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import MynyfyText from '../../components/MynyfyText';
import contestService from '../../services/contestService';
import { useLocation, useParams } from 'react-router';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfySpan from '../../components/MynyfySpan';
import { CallRounded, GpsFixedRounded } from '@mui/icons-material';
import MynyfyLoader from '../../components/MynfyfLoader';
import { Card } from '@mui/material';
import MynyfyHeader from '../../components/MynyfyHeader';

const PAGE_LIMIT = 25;

const InterestedUsers = () => {
  const urlParams = useParams();
  const route = useLocation();

  const [interestedData, setInterestedData] = useState(null);
  const [canInfinity, setInfinity] = useState(true);
  const [isInfinityApiCall, setIsInfinityApiCal] = useState(true);

  useEffect(() => {
    getInterestedUsers(0);
  }, []);

  const getInterestedUsers = (existingCount) => {
    let params = { existingCount, reqCount: PAGE_LIMIT };
    contestService
      .getInterestedUsers(urlParams?.id, params)
      .then((res) => {
        if (res.success) {
          if (_.isEmpty(res.data)) {
            setInfinity(false);
            if (_.isEmpty(interestedData)) {
              setInterestedData([]);
            }
          } else {
            if (res.data?.length < PAGE_LIMIT) {
              setInfinity(false);
            }
            let oldData = interestedData || [];
            setInterestedData([...oldData, ...res.data]);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsInfinityApiCal(true));
  };

  const infinityScrolling = () => {
    if (canInfinity && isInfinityApiCall) {
      setIsInfinityApiCal(false);
      getInterestedUsers(_.isEmpty(interestedData) ? 0 : interestedData.length);
    }
  };

  return interestedData ? (
    <div style={{ backgroundColor: '#fff' }}>
      <MynyfyHeader title={route?.state?.name} />
      {_.isEmpty(interestedData) ? (
        <MynyfyEmptyMsg message={'No one shared their interest yet'} />
      ) : (
        <div id='PublishAd' style={{ padding: '0px 5px' }}>
          <Card className='row interestedDetailsContainer'>
            <div style={{ flex: 1 }}>
              <MynyfyText title={'Name'} bold />
            </div>
            <div style={{ flex: 0.3, textAlign: 'center' }}>
              <MynyfyText title={'Mobile'} bold />
            </div>
          </Card>
          {interestedData.map((item, i) => (
            <Card className='interestedDetailsContainer' key={i}>
              <div className='row'>
                <div style={{ flex: 1 }}>
                  <div>
                    <MynyfySpan title={item.buyerId?.profile?.firstName} />
                    &nbsp;
                    {item.buyerId?.profile?.lastName ? (
                      <MynyfySpan title={item.buyerId?.profile?.lastName} />
                    ) : null}
                  </div>
                </div>
                <div style={{ flex: 0.3, textAlign: 'center' }}>
                  <CallRounded
                    color='primary'
                    onClick={() => window.open('tel:' + item.buyerId?.profile?.mobile)}
                  />
                </div>
              </div>

              <div className='row' style={{ marginTop: 7 }}>
                <GpsFixedRounded fontSize='' color='primary' style={{ marginLeft: -3 }} />
                <div>
                  <MynyfySpan title={item.state?.name} />
                  ,&nbsp;
                  <MynyfySpan title={item.city?.name} />
                  ,&nbsp;
                  <MynyfySpan title={item.area?.name} />
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default InterestedUsers;
