import React from 'react';
import MynyfyText from './MynyfyText';
import theme from '../utils/theme';

const MynyfyRadioButton = ({
  isSelected,
  onClick,
  style,
  disabled,
  title,
  LableComponent,
  card,
  bold,
}) => {
  return (
    <div id='RadioBtn'>
      <div
        className={`row link ${card && 'radioBtnCard'}`}
        style={{ margin: '7px 3px', ...style }}
        onClick={() => {
          if (!disabled) onClick();
        }}>
        <div className='radioBtn itemCenter'>
          {isSelected ? <div className='selectedRadioBtn' /> : null}
        </div>
        {LableComponent ? <LableComponent /> : <MynyfyText title={title} p bold={bold} />}
      </div>
    </div>
  );
};

export default MynyfyRadioButton;
