import React from 'react';
import './downloadApp.scss';
import MynyfyText from '../../components/MynyfyText';
import { KeyboardBackspaceRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const DownloadApp = () => {
  const navigate = useNavigate();

  const storeHandler = (os) => {
    if (os === 'ios') {
      window.open('https://apps.apple.com/in/app/mynyfy-local-simplified/id1500374826');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.mynyfyxbuyer');
    }
  };

  return (
    <div id='DownloadApp'>
      <KeyboardBackspaceRounded className='backBtn' onClick={() => navigate(-1)} />
      <img
        src={require('../../assets/imgs/LandingPage/mynyfy_White.png')}
        className='logo'
        alt='Mynyfy'
      />
      <MynyfyText
        title={'All features in website will be available soon'}
        h6
        className='infoText'
      />
      <MynyfyText title={'Until then, request you to use mobile App'} h6 className='infoText' />
      <div className='row'>
        <img
          src={require('../../assets/imgs/icons/appStore.png')}
          className='store'
          alt='Mynyfy IOS'
          onClick={() => storeHandler('ios')}
        />
        <img
          src={require('../../assets/imgs/icons/playStore.png')}
          className='store'
          alt='Mynyfy Android'
          onClick={() => storeHandler('android')}
        />
      </div>
    </div>
  );
};

export default DownloadApp;
