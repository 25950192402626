import _ from 'lodash';
import { objectReplace } from '../../utils/CommonMethods';
import storeService from '../../services/storeService';

export const likeStore = ({
  storeId,
  setLiked,
  profile,
  data,
  dispatch,
  action,
  userProfile,
  snackbarInfo,
}) => {
  setLiked(true);
  storeService
    .likeStore(storeId)
    .then((res) => {
      if (res.success) {
        let likedStores = profile?.likedSellers;
        if (likedStores) {
          if (!likedStores.includes(storeId)) {
            likedStores.push(storeId);
            profile.likedSellers = likedStores;
          }
        } else {
          let likedStores = [storeId];
          profile.likedSellers = likedStores;
        }
        if (!_.isEmpty(data)) {
          let storesData = data.filter((e) => e._id == storeId);
          storesData[0].likesCount = storesData[0].likesCount + 1;
          let newArr = objectReplace(data, storesData[0], '_id');
          dispatch(action(newArr));
        }
        dispatch(userProfile(profile));
        dispatch(snackbarInfo({ open: true, type: 'success', message: res.data }));
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    })
    .finally(() => setLiked(false));
};

export const unlikeStore = ({
  storeId,
  setLiked,
  profile,
  data,
  dispatch,
  action,
  userProfile,
  snackbarInfo,
}) => {
  setLiked(true);
  storeService
    .unlikeStore(storeId)
    .then((res) => {
      if (res.success) {
        let likedStores = profile?.likedSellers;
        if (likedStores.includes(storeId)) {
          profile.likedSellers = likedStores.filter((e) => e !== storeId);
        }
        if (!_.isEmpty(data)) {
          let storesData = data.filter((e) => e._id == storeId);
          storesData[0].likesCount = storesData[0].likesCount - 1;
          let newArr = objectReplace(data, storesData[0], '_id');
          dispatch(action(newArr));
        }
        dispatch(userProfile(profile));
        dispatch(snackbarInfo({ open: true, type: 'success', message: res.data }));
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    })
    .finally(() => setLiked(false));
};
