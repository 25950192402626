import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { MuiTheme } from './utils/MuiTheme';
import { MynyfyRoutes } from './MynyfyRoutes';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux';
import './App.scss';
import { setAuthToken } from './utils/apiHelper';
import { MynyfyEnums } from './utils/MynyfyEnums';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function App() {
  useEffect(() => {
    let version = localStorage.getItem('version');
    if (version !== MynyfyEnums.VERSION) {
      localStorage.setItem('version', MynyfyEnums.VERSION);
      window.location.reload(true);
    }
    let token = localStorage.getItem('login_token');
    if (token) {
      setAuthToken(token);
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={MuiTheme}>
          <MynyfyRoutes />
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
