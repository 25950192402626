import React, { useEffect, useState } from 'react';
import MynyfySelect from '../../components/MynyfySelect';
import { useSelector } from 'react-redux';
import commonService from '../../services/commonService';
import _ from 'lodash';

const LocationFields = ({ error = {}, sendData, menuPlacement }) => {
  const states = useSelector((state) => state.appData?.states);
  const location = useSelector((state) => state.location?.location);

  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedArea, setSelectedArea] = useState('');

  useEffect(() => {
    preLocation();
  }, []);

  const preLocation = async () => {
    if (location?.state) {
      let state = states.find((e) => e._id == location.state?._id);
      if (state) {
        setSelectedState({ label: state.name, value: state._id, data: state.data });
        let cityList = await appDataApi(state.data, setCities);
        let city = cityList.find((e) => e._id == location.city?._id);
        if (city) {
          setSelectedCity({ label: city.name, value: city._id, data: city.data });
          let areaList = await appDataApi(city.data, setAreas);
          let area = areaList.find((e) => e._id == location.area?._id);
          if (area) setSelectedArea({ label: area.name, value: area._id, data: area.data });
        }
      }
    }
  };

  const appDataApi = async (key, setData) => {
    return commonService.publicCategorySort(key).then((res) => {
      let sortedData = _.orderBy(res, ['name'], ['asc']);
      setData(sortedData);
      return sortedData;
    });
  };

  const onStateSelected = (e) => {
    setSelectedState(e);
    setSelectedCity(null);
    setCities([]);
    setSelectedArea(null);
    setAreas([]);
    appDataApi(e.data, setCities);
    sendData('state', { name: e?.label, _id: e?.value, data: e?.data });
    sendData('city', null);
    sendData('area', null);
  };

  const onCitySelected = (e) => {
    setSelectedCity(e);
    setSelectedArea(null);
    setAreas([]);
    appDataApi(e.data, setAreas);
    sendData('city', { name: e?.label, _id: e?.value, data: e?.data });
    sendData('area', null);
  };

  return (
    <div>
      <MynyfySelect
        placeholder={'State'}
        value={selectedState}
        error={error.selectedState ? true : false}
        helperText={error.selectedState}
        onChange={(e) => onStateSelected(e)}
        list={states.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
        menuPlacement={menuPlacement || 'auto'}
      />
      <MynyfySelect
        placeholder={'City/District'}
        value={selectedCity}
        error={error.selectedCity ? true : false}
        helperText={error.selectedCity}
        onChange={(e) => onCitySelected(e)}
        list={cities.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
        menuPlacement={menuPlacement || 'auto'}
      />
      <MynyfySelect
        placeholder={'Area/Constituency'}
        value={selectedArea}
        error={error.selectedArea ? true : false}
        helperText={error.selectedArea}
        onChange={(e) => {
          setSelectedArea(e);
          sendData('area', { name: e?.label, _id: e?.value, data: e?.data });
        }}
        list={areas.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
        menuPlacement={menuPlacement || 'auto'}
      />
    </div>
  );
};

export default LocationFields;
