import React from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfySpan from '../../components/MynyfySpan';

const AdTips = ({ step }) => {
  return (
    <div className='adTipContainer'>
      {step === 1 && (
        <>
          <MynyfyText title={'Tips :'} bold className='adTipHeading' />
          <MynyfyText
            title={"1. Choose a title that best describes your ad and grab users' attention."}
            className='adTipLabels'
          />
          <MynyfyText
            title={
              '2. Craft a compelling description that highlights the unique features of your product or service.'
            }
            className='adTipLabels'
          />
          <MynyfyText
            title={'3. Add a link to direct users to your website / product page / app store.'}
            className='adTipLabels'
          />
        </>
      )}
      {step === 2 && (
        <>
          <MynyfyText title={'Tips :'} bold className='adTipHeading' />
          <MynyfyText
            title={'1. The more specific your target audience is, the better your Ad will perform.'}
            className='adTipLabels'
          />
          <div>
            <MynyfySpan
              title={'2. If you dont make selection here the default Ad location will be '}
              className='adTipLabels'
            />
            <MynyfySpan title={'All States'} className='adTipLabels' bold />
          </div>
        </>
      )}
      {step === 3 && (
        <>
          <MynyfyText title={'Important :'} bold className='adTipHeading' />

          <div>
            <MynyfySpan title={'1. Maintain '} className='adTipLabels' />
            <MynyfySpan
              title={'1:1 ratio or 500px x 500px resolution'}
              className='adTipLabels'
              bold
            />
          </div>
          <div>
            <MynyfySpan title={'2. Maximum size allowed: '} className='adTipLabels' />
            <MynyfySpan title={'1MB'} className='adTipLabels' bold />
          </div>
          <div>
            <MynyfySpan title={'3. Supported Formats : '} className='adTipLabels' />
            <MynyfySpan title={'png, jpg, jpeg'} className='adTipLabels' bold />
          </div>
        </>
      )}
    </div>
  );
};

export default AdTips;
