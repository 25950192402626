import React from 'react';
import MynyfySpan from './MynyfySpan';
import { MynyfyEnums } from '../utils/MynyfyEnums';
import { getOS } from '../utils/CommonMethods';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../redux/actions/snackbar.action';

const MynyfyMobileSupport = () => {
  const dispatch = useDispatch();

  return (
    <MynyfySpan
      title={MynyfyEnums.SUPPORT_NUMBER}
      link
      onClick={() => {
        let platform = getOS();
        if (platform === 'android' || platform === 'ios' || platform === 'unknown') {
          window.open('tel:' + MynyfyEnums.SUPPORT_NUMBER);
        } else {
          navigator.clipboard.writeText(MynyfyEnums.SUPPORT_NUMBER);
          dispatch(
            snackbarInfo({ open: true, type: 'success', message: 'Support Number is Copied' })
          );
        }
      }}
    />
  );
};

export default MynyfyMobileSupport;
