import { myApi, myPubApi } from '../utils/apiHelper';

class PaymentService {
  async initiateRazorPayPayment(data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.post(`/razorPay/initiatePayment`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }

  async cancelRazorPayPayment(data) {
    try {
      let token = localStorage.getItem('login_token');
      let api = token ? myApi : myPubApi;
      return await api.put(`/razorPay/cancelPayment`, data).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err.response.data;
    }
  }
}

const paymentService = new PaymentService();
export default paymentService;
export { paymentService };
