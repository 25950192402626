import { STATES, CATEGORIES, AD_TIMER, ORDER_MYNTS } from '../actionTypes';

const INITIAL_STATE = {
  states: [],
  categories: [],
  adTimer: null,
  orderMynts: null,
};

const appDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STATES:
      return {
        ...state,
        states: action.payload,
      };
    case CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case AD_TIMER:
      return {
        ...state,
        adTimer: action.payload,
      };
    case ORDER_MYNTS:
      return {
        ...state,
        orderMynts: action.payload,
      };
    default:
      return state;
  }
};

export default appDataReducer;
