import { myApi, myPubApi } from '../utils/apiHelper';

class BrandService {
  async getBrands(params) {
    try {
      return await myPubApi.get(`/getBrand`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getBrandById(params) {
    try {
      return await myPubApi.get(`/brandById`, { params }).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async updateBrand(id) {
    try {
      return await myApi.put(`/updateBrand/${id}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async likeBrand(brandId) {
    try {
      return await myApi.get(`/likeBrand?id=${brandId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async unlikeBrand(brandId) {
    try {
      return await myApi.get(`/unlikeBrand?id=${brandId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getLikedBrands() {
    try {
      return await myApi.get(`/likedBrands`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async viewBrand(brandId) {
    try {
      return await myApi.get(`/viewBrand?id=${brandId}`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const brandService = new BrandService();
export default brandService;
export { brandService };
