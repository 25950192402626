import { createTheme } from '@mui/material';
import theme from './theme';

export const MuiTheme = createTheme({
  palette: {
    primary: { main: theme.PRIMARY_BLUE },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: { boxShadow: theme.SHADOW },
      },
    },
  },
});
