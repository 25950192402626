import { PAYMENT_INITIALIZED } from '../actionTypes';

const INITIAL_STATE = false;

const paymentInitializedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_INITIALIZED:
      return action.payload;
    default:
      return state;
  }
};

export default paymentInitializedReducer;
