import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyEmailSupport from '../../components/MynyfyEmailSupport';
import MynyfyMobileSupport from '../../components/MynyfyMobileSupport';

const ContactUs = () => {
  return (
    <Fragment>
      <MynyfyHeader title={'Contact Us'} />
      <div style={{ padding: 20 }}>
        <MynyfyText title={'MYNYFY India Pvt. Ltd.'} bold p />
        <MynyfyText
          title={'Our Business Hours'}
          style={{ paddingTop: 20, paddingBottom: 3 }}
          bold
        />
        <MynyfyText title={'Monday - Saturday: 11:00 am - 8:00 pm'} />

        <MynyfyText
          title={'Give us a call for any queries to our support number'}
          style={{ paddingTop: 20, paddingBottom: 3 }}
        />
        <div className='row'>
          <MynyfyText title={'Support Number:'} bold />
          &ensp;
          <MynyfyMobileSupport />
        </div>

        <MynyfyText
          title={'Drop a mail for any queries to the following mail Id'}
          style={{ paddingTop: 20, paddingBottom: 3 }}
        />
        <div className='row'>
          <MynyfyText title={'Email:'} bold />
          &ensp;
          <MynyfyEmailSupport />
        </div>

        <MynyfyText
          title={'Visit us for any queries at following address'}
          style={{ paddingTop: 20, paddingBottom: 3 }}
        />
        <div className='row' style={{ alignItems: 'flex-start' }}>
          <MynyfyText title={'Address:'} bold />
          &ensp;
          <MynyfyText
            title={
              'RO: 203, JP Classic, SBH Officers Colony, Saidabad, Hyderabad, Telangana-500059'
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
