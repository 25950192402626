import React, { Fragment } from 'react';
import MynyfyHeader from '../../components/MynyfyHeader';
import { useLocation } from 'react-router-dom';
import MynyfyText from '../../components/MynyfyText';

const PrivacyPolicy = ({ customHeader }) => {
  let data = useLocation();

  return (
    <Fragment>
      {customHeader ? customHeader() : <MynyfyHeader title={data.state || 'Privacy Policy'} />}
      <div style={{ padding: 20 }} id='policy'>
        <div>
          <small>
            M/s. MYNYFY India Private Limited (&ldquo;Mynyfy&rdquo;, &ldquo;Company&rdquo;,
            &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;) owns and controls the website,
            [●] ("Website&rdquo;) and a [●] mobile application (&ldquo;App&rdquo;/
            &ldquo;Application&rdquo;) which is a pioneering, technology-powered integrated
            aggregator service provider with a unique model to connect the buyers with the sellers
            by using the Order process, utilizing its Web Application and/or the Application
            (&ldquo;Services&rdquo;).
          </small>
          <br />
          <br />
          <small>
            Mynyfy and its affiliates respect your privacy. This Privacy Policy describes in brief
            the manner in which your data is collected and used by Application. You are advised to
            please read the Privacy Policy carefully. By downloading, accessing or securing access
            to the Website/Application, you agree to the collection and use of your data by the
            Company in the manner provided in this Privacy Policy.
          </small>
          <br />
          <br />
          <small>
            This Policy covers both the &ldquo;online&rdquo; (through Website and App) and
            &ldquo;offline&rdquo; (e.g., through telephone or in person) collection of data by us.
            If you interact with our Services whether through Website or App or any other platform,
            this Privacy Policy applies to you. It is recommended that you read this Privacy Policy
            carefully so that you understand our policies and practices.
          </small>
        </div>

        <MynyfyText title={'WE WANT YOU TO'} pBig bold style={{ padding: '20px 0px 0px' }} />

        <div>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>Feel comfortable using our Website/App.</small>
            </li>
            <li>
              <small>Feel absolutely secure submitting information to us.</small>
            </li>
            <li>
              <small>Contact us with your questions or concerns about privacy; and</small>
            </li>
            <li>
              <small>
                Know that by using our App, Website, you are consenting to the collection of certain
                data by the Company.
              </small>
            </li>
          </ul>
        </div>

        <MynyfyText
          title={'WHAT INFORMATION IS, OR MAY BE, COLLECTED FROM YOU?'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <small>
            We will automatically receive and collect certain anonymous information in standard
            usage logs through our Web server, including computer-identification information
            obtained from "cookies," sent to your browser from a
          </small>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>web server cookie stored on your hard drive</small>
            </li>
            <li>
              <small>an IP address, assigned to the computer which you use</small>
            </li>
            <li>
              <small>the domain server through which you access our service</small>
            </li>
            <li>
              <small>the type of computer you're using</small>
            </li>
            <li>
              <small>the type of web browser you're using</small>
            </li>
            <li>
              <small>the device id</small>
            </li>
          </ul>
        </div>

        <MynyfyText
          title={'We may collect the following Personal Information about you'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small> Name- including First &amp; Last Name</small>
            </li>
            <li>
              <small> Email id</small>
            </li>
            <li>
              <small> Mobile no, phone no</small>
            </li>
            <li>
              <small> Contact Details</small>
            </li>
            <li>
              <small> Residential/office address</small>
            </li>
            <li>
              <small> Complete Address with pin code</small>
            </li>
            <li>
              <small> Demographic Details</small>
            </li>
            <li>
              <small>
                Vehicle Details like Make, Model, Previous Service History, Registration Number
                etc..,
              </small>
            </li>
            <li>
              <small> Company or retail shop details like Name, PAN, GST Number etc.</small>
            </li>
          </ul>
          <p>
            &ldquo;Personal Information&rdquo; means any information that relates to a user which
            directly or indirectly, is capable of identifying such user and also includes sensitive
            personal data or information.
          </p>
        </div>

        <MynyfyText
          title={'We may also collect the following information:'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>about the pages you visit / access</small>
            </li>
            <li>
              <small>the links you click on our App/Website</small>
            </li>
            <li>
              <small>the number of times you access our App, pages on our App/Website</small>
            </li>
            <li>
              <small>the date and time you access our Services;</small>
            </li>
            <li>
              <small>the website address, if any, that linked you to our Website/App.</small>
            </li>
          </ul>
        </div>

        <MynyfyText
          title={'WHO COLLECTS THE INFORMATION'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <small>
            The Personal Information may be collected, received, possessed, stored, dealt with or
            handled by the Company in the manner laid down herein. We may also obtain information
            about you from a third party with whom you have booked your appointment for performing
            the Services.
          </small>
          <br />
          <br />
          <small>
            We may collect Personal Information about you as a part of a voluntary registration
            process.
          </small>
          <br />
          <br />
          <small>
            We believe in making our Services user friendly. This means that from time to time we
            will keep bringing in/adding new features and accordingly we might need to collect some
            additional Personal Information from you.
          </small>
          <br />
          <br />
          <small>
            We may collect Personal Information about you as a part of a voluntary registration
            process.
          </small>
        </div>

        <div style={{ padding: '20px 0px 0px' }}>
          <MynyfyText title={'HOW IS THE INFORMATION USED?'} pBig bold />
          <MynyfyText title={'We use your Personal Information to:'} p />
        </div>

        <div>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>provide you Services as requested by you</small>
            </li>
            <li>
              <small>help us provide personalized features</small>
            </li>
            <li>
              <small>
                to send you information about our new initiatives or other relevant information that
                we think you might find useful or interesting
              </small>
            </li>
            <li>
              <small>
                to provide you with feedback, newsletters and for other marketing activities.
              </small>
            </li>
            <li>
              <small>tailor our sites to your interest</small>
            </li>
            <li>
              <small>to get in touch with you when necessary</small>
            </li>
            <li>
              <small>to preserve social history as governed by existing law or policy</small>
            </li>
            <li>
              <small>to send you reminders on the schedule of your vehicle servicing</small>
            </li>
          </ul>
        </div>

        <MynyfyText
          title={'We use information internally to:'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>direct our efforts for service improvement</small>
            </li>
            <li>
              <small>our internal training purpose and data analysis</small>
            </li>
            <li>
              <small>
                for research, analysis and other business-related purposes, subject to not directly
                disclosing any of your Personal Information
              </small>
            </li>
          </ul>
        </div>

        <MynyfyText
          title={'Generally, we use anonymous traffic information to:'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>
                remind us of who you are in order to deliver to you a better and more personalized
                service;
              </small>
            </li>
            <li>
              <small>recognize your access privileges to our App/Website;</small>
            </li>
            <li>
              <small>track your entries in some of our promotions;</small>
            </li>
            <li>
              <small>help diagnose problems with our server;</small>
            </li>
            <li>
              <small>administer our App/Website;</small>
            </li>
            <li>
              <small>administer our App/Website;</small>
            </li>
          </ul>
        </div>

        <MynyfyText
          title={'DISCLOSURE POLICY IN COMPLIANCE WITH RULE 6 OF THE IT RULES 2011'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <small>
            The Personal Information may be disclosed to any person, if such disclosure is required
            for a lawful purpose connected with a function or activity of Website/ App.
          </small>
          <br />
          <br />
          <small>
            The Personal Information may be disclosed, where the disclosure is necessary for
            compliance of a legal obligation.
          </small>
          <br />
          <br />
          <small>
            We do not rent, sell, publish or share your Personal Information and we will not
            disclose any of your personally identifiable information unless:
          </small>
          <br />
          <br />
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>we have your permission</small>
            </li>
            <li>
              <small>
                to help investigate, prevent or take action regarding unlawful and illegal
                activities, suspected fraud, including cyber incidents potential threat, punishment
                of offences and to the safety or security of any person, violations of
                Company&lsquo;s terms of use or to defend against legal claims;
              </small>
            </li>
            <li>
              <small>
                special circumstances such as compliance with subpoenas, court orders,
                requests/order from legal authorities or law enforcement agencies requiring such
                disclosure.
              </small>
            </li>
          </ul>
        </div>

        <MynyfyText
          title={
            'WHAT CHOICES ARE AVAILABLE TO YOU REGARDING COLLECTION, USE AND DISTRIBUTION OF YOUR INFORMATION?'
          }
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />

        <div>
          <small>
            Sharing your Personal Information is entirely voluntary. You need not register with us
            in order to use our app, website. However, we offer some services only to visitors who
            register. You may change your interests at any time and may opt-in or opt-out of any
            marketing / promotional / newsletters mailings. You may update your information and
            change your account settings at any time.
          </small>
          <br />
          <br />
          <small>
            Upon request, we will remove/block your sensitive personal identifiable information from
            our database, thereby cancelling your registration. However, your information may remain
            stored in archive on our servers even after the deletion or the termination of your
            account.
          </small>
          <br />
          <br />
          <small>
            If we plan to use your sensitive personal identifiable information for any commercial
            purposes, we may notify you at the time we collect that information and allow you to
            opt-out of having your information used for those purposes.
          </small>
        </div>

        <MynyfyText
          title={
            'WHAT SECURITY PROCEDURES ARE IN PLACE TO PROTECT INFORMATION FROM LOSS, MISUSE OR ALTERATION?'
          }
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />
        <div>
          <small>
            To protect against the loss, misuse and alteration of the information under our control,
            and to comply with Rule 8 of IT Rules, 2011 (Reasonable security practices and
            procedures and sensitive personal data or information), we have in place appropriate
            physical, electronic, technical, operational and managerial procedures.
          </small>
          <br />
          <br />
          <small>
            Our servers are accessible only to authorized personnel and your information is shared
            with respective personnel to complete the transaction and to provide the Services
            requested by you.
          </small>
          <br />
          <br />
          <small>
            Although we will endeavor to safeguard the confidentiality of your sensitive personal
            identifiable information, transmissions made by means of the Internet cannot be made
            absolutely secure. No such measures are competent enough to safeguard your Personal
            Information in a complete 100% way. Hence, you are also encouraged to take steps to
            prevent your Personal Information from being misused by logging off after using a shared
            computer, choosing a robust password , not sharing the password with anyone and changing
            it periodically. We in no way can are to be held responsible for the unauthorized use of
            your Personal Information or for any suspicious activity on your account. By using our
            App, Website, you agree that we will have no liability for disclosure of your
            information due to errors in transmission or unauthorized acts of third parties.
          </small>
        </div>

        <MynyfyText
          title={'HOW CAN YOU CORRECT INACCURACIES IN THE INFORMATION?'}
          pBig
          bold
          style={{ padding: '20px 0px 0px' }}
        />
        <div>
          <small>
            To correct or update any information you have provided, our App/Website allows you to do
            it online. In the event of loss of access details you may send an e-mail to
            support@mynyfy.com
          </small>
        </div>

        <MynyfyText title={'YOUR CONSENT:'} pBig bold style={{ padding: '20px 0px 0px' }} />
        <div>
          <small>
            By agreeing to the terms of this Privacy Policy, you agree to our processing of your
            Personal Information for the purposes given herein.
          </small>
        </div>

        <MynyfyText title={'COOKIES:'} pBig bold style={{ padding: '20px 0px 0px' }} />
        <div>
          <small>
            We use cookies and other tracking technologies. Cookies are used to store user
            preferences so as to help us understand the nature of usage of Services and generally
            improve our Services to you. Some cookies and other technologies may serve to recall
            SPDI previously indicated by a user. Most browsers allow you to control cookies,
            including whether or not to accept them and how to remove them.
          </small>
          <br />
          <br />
          <small>
            We use cookies and other tracking technologies. Cookies are used to store user
            preferences so as to help us understand the nature of usage of Services and generally
            improve our Services to you. Some cookies and other technologies may serve to recall
            SPDI previously indicated by a user. Most browsers allow you to control cookies,
            including whether or not to accept them and how to remove them.
          </small>
          <br />
          <br />
          <small>
            Our use of cookies and other tracking technologies allows us to improve our Website/App
            and your experience with the Website/App.
          </small>
        </div>

        <MynyfyText title={'POLICY UPDATES'} pBig bold style={{ padding: '20px 0px 0px' }} />
        <div>
          <small>
            We reserve the right to change or update this policy at any time and we may place a
            prominent notice on our App/Website. Such changes shall be effective immediately upon
            posting to this App/Website.
          </small>
        </div>

        <MynyfyText title={'USER DECLARATIONS:'} pBig bold style={{ padding: '20px 0px 0px' }} />
        <div>
          <ul style={{ paddingLeft: 20 }}>
            <li>
              <small>
                I understand and have the knowledge that Personal Information is being collected.
              </small>
            </li>
            <li>
              <small>
                I understand and have the knowledge of the purpose for which my Personal Information
                is being collected.
              </small>
            </li>
            <li>
              <small>
                I understand and have the knowledge of the indented recipients of the information.
              </small>
            </li>
            <li>
              <small>
                I understand that I have the option not to provide the data or information sought to
                be collected by Company, Application and the Website.
              </small>
            </li>
            <li>
              <small>
                I understand that I also have an option (while availing the services of Application,
                Website or otherwise) to withdraw my consent given earlier to Company and understand
                and accept that such withdrawal of the consent shall be sent in writing and in such
                case Company shall have the option not to provide the services for which the said
                information was sought
              </small>
            </li>
            <li>
              <small>
                I read and have understood this Privacy Policy and all other policies governing the
                usage of the Application and Website.
              </small>
            </li>
          </ul>
        </div>

        <MynyfyText title={'DISCLAIMER:'} pBig bold style={{ padding: '20px 0px 0px' }} />
        <div>
          <small>
            The Company shall not be liable for any loss or damage sustained by reason of any
            disclosure (inadvertent or otherwise) of any information concerning the account and /
            omission or inaccuracy with respect to any information so disclosed and used whether or
            not in pursuance of a legal process or otherwise.
          </small>
        </div>

        <MynyfyText title={'GRIEVANCE REDRESSAL'} pBig bold style={{ padding: '20px 0px 0px' }} />
        <div>
          <small>
            In accordance with Information Technology Act 2000 and rules made there under, the name
            and contact details of the Grievance Officer are provided below:
          </small>
          <br />
          <br />
          <small>Mr.</small>
          <br />
          <small>Email: support@mynyfy.com</small>
          <br />
          <small>Time: Mon &ndash; Fri (10:00AM to 5:00PM)</small>
          <br />
          <br />
          <small>
            If you have any grievance, then you may intimate the same to above e-mail address in the
            manner explained below:-
          </small>
          <br />
          <br />
          <small>
            a. Sending a request in writing or through email signed with electronic signature
            identifying the grievance;
          </small>
          <br />
          <br />
          <small>
            b. Your contact information like address, telephone number, email address, etc. where
            you can be contacted.
          </small>
          <br />
          <br />
          <br />
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
