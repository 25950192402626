import React from 'react';
import MynyfyText from '../../components/MynyfyText';

const SwipeReminder = ({ moment }) => {
  return (
    <div className='swipeContainer' moment={moment}>
      <MynyfyText
        title={moment === 'horizontal' ? '<< Swipe Side >>' : 'Swipe Up'}
        small
        bold
        noSelect
      />
    </div>
  );
};

export default SwipeReminder;
