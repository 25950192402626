import React from 'react';
import MynyfyText from '../../components/MynyfyText';
import { Badge, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AccountCircleRounded, CircleNotificationsRounded } from '@mui/icons-material';
import theme from '../../utils/theme';
import { getGreeting } from '../../utils/CommonMethods';

const TopBar = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const user = useSelector((state) => state.user?.user);
  const notificationsCount = useSelector((state) => state.notifications?.count);

  return (
    <div style={{ position: 'relative', height: 61, zIndex: 1 }}>
      <Card className='rowSB topBar'>
        <img
          src={require('../../assets/imgs/LandingPage/mynyfy.png')}
          className='logo'
          alt='Mynyfy'
          onClick={() => navigate('/')}
        />
        {isLoggedIn ? (
          <div className='row'>
            <div
              className='row'
              onClick={() => navigate('/Profile')}
              style={{ cursor: 'pointer', marginRight: 10 }}>
              <div style={{ textAlign: 'end' }}>
                <MynyfyText title={getGreeting()} light italic />
                <MynyfyText title={user?.firstName} />
              </div>
              <AccountCircleRounded
                style={{ color: theme.PRIMARY_BLUE, fontSize: 35, marginLeft: 5 }}
              />
            </div>
            <div>
              <CircleNotificationsRounded
                style={{ color: theme.PRIMARY_BLUE, fontSize: 35, cursor: 'pointer' }}
                onClick={() => navigate('/Notifications')}
              />
              {notificationsCount ? (
                <Badge
                  badgeContent={notificationsCount}
                  slotProps={{
                    badge: {
                      style: {
                        backgroundColor: 'white',
                        boxShadow: theme.SHADOW,
                        border: `1px solid ${theme.PRIMARY_BLUE}`,
                      },
                    },
                  }}
                  style={{ top: -25 }}
                  max={1000}
                />
              ) : null}
            </div>
          </div>
        ) : (
          <MynyfyText
            title={'Login / SignUp'}
            pBig
            link
            style={{ padding: 8.5 }}
            onClick={() => navigate('SignIn')}
          />
        )}
      </Card>
    </div>
  );
};

export default TopBar;
