import { NEW_ORDER } from '../actionTypes';

const INITIAL_STATE = null;

const newOrderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEW_ORDER:
      return action.payload;
    default:
      return state;
  }
};

export default newOrderReducer;
