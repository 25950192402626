export const returnPolicy = [
  {
    heading: 'Return/Refund Policy',
    content: [
      'We do not accept any returns, goods once sold cannot be returned and money will not be refunded. Please validate the product with the seller before you place the order.',
    ],
  },
];

export const exchangePolicy = [
  {
    heading: 'Exchange Policy',
    content: [
      'We do not accept any exchange services. Please validate the product with the seller before you place the order.',
    ],
  },
];
