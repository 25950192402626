import React, { useEffect, useState, Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyDoubleCard from '../../components/MynyfyDoubleCard';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import './stores.scss';
import {
  AddCircleRounded,
  FavoriteBorderRounded,
  FavoriteRounded,
  SearchRounded,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { userProfile } from '../../redux/actions/user.action';
import _ from 'lodash';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { useLocation, useNavigate } from 'react-router-dom';
import storeService from '../../services/storeService';
import { likeStore, unlikeStore } from './StoresHelper';
import { FEATURE_TYPES } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import { Box } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfySelect from '../../components/MynyfySelect';
import MynyfyButton from '../../components/MynyfyButton';

const PAGE_LIMIT = 20;

const StoresList = ({
  isFav,
  data,
  action,
  emptyListMsg,
  brandDetails,
  online,
  offline,
  isRestaurant,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const StoreLocations = offline
    ? [
        { label: 'In my Area', value: 'stores in my area' },
        { label: 'In my City/District', value: 'stores in my city' },
      ]
    : [
        { label: 'Serviceable to my area', value: 'stores serviceable to my area' },
        { label: 'In my Area', value: 'stores in my area' },
      ];

  const location = useSelector((state) => state?.location?.location);
  const profile = useSelector((state) => state?.user?.profile);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const categories = useSelector((state) => state.appData?.categories);
  const route = useLocation();

  const [isLiked, setLiked] = useState(false);
  const [search, setSearch] = useState(null);
  const [selectedCategory, setCategory] = useState(null);
  const [sortedStores, setSortedStores] = useState(null);
  const [canInfinity, setCanInfinity] = useState(true);
  const [isInfinityApiCall, setIsInfinityApiCal] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [filterType, setFilterType] = useState('');
  const [storeLocation, setStoreLocation] = useState(StoreLocations[0]);

  // Add event listener for scrolling
  useEffect(() => {
    // Function to check if the user has reached the bottom of the page (80%)
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollY + clientHeight >= scrollHeight * 0.8 && isInfinityApiCall) {
        if (!isFav) {
          infinityScrolling();
        }
      }
    };

    // Adjust the debounce time as needed
    const debounceScroll = debounce(handleScroll, 500);

    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [isInfinityApiCall]);

  // Debounce function to delay the infinity API call
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    let isFavApiCall = isFav
      ? profile?.likedSellers?.length !== data?.length
        ? true
        : false
      : false;

    if (_.isEmpty(data) || isFavApiCall) {
      if (isFav) {
        if (isLoggedIn) {
          getFavStores();
        } else {
          storeDataHandler([]);
        }
      } else {
        getStores();
      }
    }
  }, []);

  const getFavStores = () => {
    storeService.getLikedStores().then((res) => {
      apiResponseHandler(res);
    });
  };

  const getStores = (state, city, area) => {
    let params = {
      pageLimit: PAGE_LIMIT,
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
      online,
      offline,
    };
    if (route?.state?.brandId) {
      params.brand = route?.state?.brandId;
    } else if (brandDetails) {
      params.brand = brandDetails._id;
    }
    if (params.brand) {
      delete params.area;
    }
    if (state && city) {
      params.state = state;
      params.city = city;
      if (area) {
        params.area = area;
      } else {
        delete params.area;
      }
    }
    if (isRestaurant) {
      params.category = process.env.REACT_APP_FOOD_CAT_ID;
    } else {
      params.notCategory = process.env.REACT_APP_FOOD_CAT_ID;
    }
    if (selectedCategory) {
      params.category = selectedCategory?.value;
    }
    if (search?.length >= 3) {
      params.q = search;
    }
    if (storeLocation?.value) {
      params.storeLocation = storeLocation?.value;
    }
    if (storeLocation?.value == 'stores in my city') {
      delete params.area;
    }

    storeService.getStores(params).then((res) => {
      apiResponseHandler(res);
    });
  };

  const apiResponseHandler = (res) => {
    if (res.success) {
      storeDataHandler(res.data?.orgList || res.data);
    } else {
      storeDataHandler([]);
      setCanInfinity(false);
      dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
    }
  };

  const storeDataHandler = (storeData) => {
    if (action) {
      dispatch(action(storeData));
    } else {
      setSortedStores(storeData);
    }
    setIsInfinityApiCal(true);
  };

  const infinityScrolling = () => {
    let reqData = sortedStores || data;
    if (canInfinity && isInfinityApiCall && reqData) {
      setIsInfinityApiCal(false);

      let params = {
        pageLimit: PAGE_LIMIT,
        timeCutoff: reqData?.[reqData?.length - 1].createdAt,
        state: location?.state?._id,
        city: location?.city?._id,
        area: location?.area?._id,
        online,
        offline,
      };
      if (route?.state?.brandId) {
        params.brand = route?.state?.brandId;
      } else if (brandDetails) {
        params.brand = brandDetails._id;
      }
      if (params.brand) {
        delete params.area;
      }
      // if (state && city) {
      //   params.state = state;
      //   params.city = city;
      //   if (area) {
      //     params.area = area;
      //   } else {
      //     delete params.area;
      //   }
      // }
      if (isRestaurant) {
        params.category = process.env.REACT_APP_FOOD_CAT_ID;
      } else {
        params.notCategory = process.env.REACT_APP_FOOD_CAT_ID;
      }
      if (selectedCategory) {
        params.category = selectedCategory?.value;
      }
      if (search?.length >= 3) {
        params.q = search;
      }
      if (storeLocation?.value) {
        params.storeLocation = storeLocation?.value;
      }
      if (storeLocation?.value == 'stores in my city') {
        delete params.area;
      }

      storeService
        .getStores(params)
        .then((res) => {
          if (res.success) {
            if (_.isEmpty(res?.data?.orgList)) {
              setCanInfinity(false);
            } else {
              let newData = [...reqData, ...res?.data?.orgList];
              storeDataHandler(newData);
              setCanInfinity(true);
            }
          } else {
            storeDataHandler([]);
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          }
        })
        .finally(() => setIsInfinityApiCal(true));
    }
  };

  const likeHandler = (storeId, key) => {
    if (isLoggedIn) {
      let options = {
        storeId,
        setLiked,
        profile,
        data,
        dispatch,
        action,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeStore(options);
      } else {
        unlikeStore(options);
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const footer = (item) => {
    return (
      <Fragment>
        <MynyfyText title={item.name} style={{ padding: '7px 10px' }} />
        <div className='rowSB footer'>
          {storeLocation?.value === 'stores serviceable to my area' ? (
            <div />
          ) : (
            <MynyfyText title={item?.area?.name} link onClick={() => navHandler(item)} />
          )}
          <div className='row'>
            {isLiked ? (
              <CircularProgress size={16} />
            ) : profile?.likedSellers?.includes(item._id) ? (
              <FavoriteRounded
                color='error'
                fontSize=''
                style={{ cursor: 'pointer' }}
                onClick={() => likeHandler(item._id, 'unlike')}
              />
            ) : (
              <FavoriteBorderRounded
                color='error'
                fontSize=''
                style={{ cursor: 'pointer' }}
                onClick={() => likeHandler(item._id, 'like')}
              />
            )}
            <MynyfyText title={item.likesCount} style={{ marginLeft: 2 }} />
          </div>
        </div>
      </Fragment>
    );
  };

  const navHandler = (item) => {
    if (isLoggedIn) {
      storeService.viewStore({ orgId: item._id, buyerId: profile._id });
    }
    navigate('/Stores/' + item.urlId, { state: { isFav, storeDetails: item } });
  };

  const handelFilterType = (key) => {
    setFilterType((filterType) => (filterType === key ? null : key));
  };

  const searchHandler = () => {
    if (search?.length > 2 || _.isEmpty(search)) {
      getStores();
    } else {
      dispatch(
        snackbarInfo({
          type: 'warning',
          open: true,
          message: 'Minimum 3 characters are required',
        })
      );
    }
  };

  return sortedStores || data ? (
    <div id='Stores'>
      <div className='filterContainer' style={{ display: 'flex' }}>
        <div className='filterOption'>
          <Box>
            <SearchOutlinedIcon
              color='primary'
              className='cursor'
              onClick={() => handelFilterType('Search')}
            />
          </Box>
        </div>

        <div className='filterOption'>
          <Box>
            <FilterListIcon
              color='primary'
              className='cursor'
              onClick={() => handelFilterType('Filter')}
            />
          </Box>
        </div>
        {/* <div className='filterOption' style={{ borderRight: 0 }}>
          <Box>
            <AddCircleRounded
              color='primary'
              className='cursor'
              onClick={() => window.open(process.env.REACT_APP_SELLER_WEB_HOST)}
            />
          </Box>
        </div> */}
      </div>
      {filterType === 'Search' ? (
        <div className='row' style={{ marginTop: 10 }}>
          <MynyfyInput
            type={'text'}
            fullWidth={true}
            placeHolder={'Search by Stores'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchRounded
            style={{ marginRight: 5, marginBottom: 5, fontSize: 30, cursor: 'pointer' }}
            onClick={() => searchHandler()}
          />
        </div>
      ) : null}
      {filterType === 'Filter' ? (
        <div style={{ margin: '10px 0px 0px' }}>
          <MynyfySelect
            fullWidth={true}
            placeholder={'Stores'}
            value={storeLocation}
            onChange={(e) => setStoreLocation(e)}
            list={StoreLocations}
          />
          {isRestaurant ? null : (
            <MynyfySelect
              fullWidth={true}
              placeholder={'Category'}
              value={selectedCategory}
              onChange={(e) => setCategory(e)}
              list={categories.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
              isClear
            />
          )}
          <MynyfyButton
            containerStyle={{ textAlign: 'end' }}
            label={'Apply filter'}
            onClick={() => getStores()}
          />
          <div style={{ borderBottom: '0.5px solid #e3e3e3' }} />
        </div>
      ) : null}
      {_.isEmpty(sortedStores || data) ? (
        <MynyfyEmptyMsg paddingTop={brandDetails ? '18vh' : '45vh'} message={emptyListMsg} />
      ) : (
        <MynyfyDoubleCard
          data={sortedStores || data}
          type={FEATURE_TYPES.STORES}
          onClick={(item) => navHandler(item)}
          footer={(item) => footer(item)}
        />
      )}
      {canInfinity && (sortedStores || data)?.length >= PAGE_LIMIT ? (
        <div style={{ padding: '20px' }}>
          <div className='loader' />
        </div>
      ) : null}

      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </div>
  ) : (
    <MynyfyLoader paddingTop={brandDetails ? '18vh' : '45vh'} />
  );
};

export default StoresList;
