import { myApi } from '../utils/apiHelper';

class ProfileService {
  async getUserById(id) {
    return myApi.get(`/profile/${id}`).then((res) => res.data.data);
  }

  async profileImgupdate(data, id, profileId) {
    try {
      return await myApi.put(`/profile/update/pic/${id}/${profileId}`, data).then((res) => {
        return res;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async profileUpdate(data, id, profileId) {
    try {
      const response = await myApi.put(`/profile/update/basic/${id}/${profileId}`, data);
      return response.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async profileDelete(id) {
    try {
      const response = await myApi.put(`/deleteProfile/${id}`);
      return response.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async profileAddressUpdate(data, id, profileId) {
    try {
      const response = await myApi.put(`/profile/update/address/${id}/${profileId}`, data);
      return response.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async profileAddressGet(profileId) {
    try {
      return await myApi.get(`/profile/address/${profileId}`).then((res) => {
        if (res) {
          return res.data.data;
        } else {
          return [];
        }
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async mobileUpdateOtp(number) {
    return await myApi
      .put(`/profile/update/mobile`, { newMobile: number })
      .then((res) => {
        if (res.data.success) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return err?.response?.data;
      });
  }

  async verifyAndUpdateMobile(data) {
    try {
      const res = await myApi.put(`/profile/update/verifyMobile`, data);
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return err?.response?.data;
    }
  }

  async changePassword(profileId, data) {
    try {
      const res = await myApi.put(`profile/changePassword/${profileId}`, data);
      return res.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async showMobile(data) {
    try {
      const res = await myApi.put(`profile/showMobile`, data);
      return res;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getPoints() {
    try {
      return await myApi.get(`/buyer/points`).then((res) => {
        return res.data;
      });
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getRefferalCode() {
    try {
      const res = await myApi.put(`/profile/referralCode`);
      return res.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async sendFirebaseToken(data) {
    try {
      const res = await myApi.put(`/profile/deviceId`, data);
      return res.data;
    } catch (err) {
      return err?.response?.data;
    }
  }

  async getAddressForDelivery(queryParam) {
    try {
      const res = await myApi.get(`/getAddressForDelivery`, { params: queryParam });
      return res.data;
    } catch (err) {
      return err?.response?.data;
    }
  }
}

const profileService = new ProfileService();
export default profileService;
export { profileService };
