import {
  JACKPOT,
  SEE_AND_WIN,
  SHOP_AND_WIN,
  SCAN_AND_WIN,
  JACKPOT_HISTORY,
  SEE_AND_WIN_HISTORY,
  SHOP_AND_WIN_HISTORY,
  SCAN_AND_WIN_HISTORY,
  FOOD_SHOP_AND_WIN,
  FOOD_SHOP_AND_WIN_HISTORY,
} from '../actionTypes';

export const fetchJackpot = (data) => ({
  type: JACKPOT,
  payload: data,
});

export const fetchSeeAndWin = (data) => ({
  type: SEE_AND_WIN,
  payload: data,
});

export const fetchScanAndWin = (data) => ({
  type: SCAN_AND_WIN,
  payload: data,
});

export const fetchShopAndWin = (data) => ({
  type: SHOP_AND_WIN,
  payload: data,
});

export const fetchFoodShopAndWin = (data) => ({
  type: FOOD_SHOP_AND_WIN,
  payload: data,
});

export const fetchJackpotHistory = (data) => ({
  type: JACKPOT_HISTORY,
  payload: data,
});

export const fetchSeeAndWinHistory = (data) => ({
  type: SEE_AND_WIN_HISTORY,
  payload: data,
});

export const fetchScanAndWinHistory = (data) => ({
  type: SCAN_AND_WIN_HISTORY,
  payload: data,
});

export const fetchShopAndWinHistory = (data) => ({
  type: SHOP_AND_WIN_HISTORY,
  payload: data,
});

export const fetchFoodShopAndWinHistory = (data) => ({
  type: FOOD_SHOP_AND_WIN_HISTORY,
  payload: data,
});
