import _ from 'lodash';
import brandService from '../../services/brandService';
import { objectReplace } from '../../utils/CommonMethods';

export const likeBrand = ({
  brandId,
  setLiked,
  profile,
  data,
  actualData,
  dispatch,
  action,
  actualAction,
  userProfile,
  snackbarInfo,
}) => {
  setLiked(true);
  brandService
    .likeBrand(brandId)
    .then((res) => {
      if (res.success) {
        let likedBrands = profile?.likedBrands;
        if (likedBrands) {
          if (!likedBrands.includes(brandId)) {
            likedBrands.push(brandId);
            profile.likedBrands = likedBrands;
          }
        } else {
          let likedBrands = [brandId];
          profile.likedBrands = likedBrands;
        }
        let brandsData = data.filter((e) => e._id == brandId);
        brandsData[0].likesCount = brandsData[0].likesCount + 1;
        let newArr = objectReplace(data, brandsData[0], '_id');
        dispatch(action(newArr));
        // Actual Data
        let actualBrandsData = actualData.filter((e) => e._id == brandId);
        actualBrandsData[0].likesCount = actualBrandsData[0].likesCount + 1;
        let newActualArr = objectReplace(actualData, actualBrandsData[0], '_id');
        dispatch(actualAction(newActualArr));
        dispatch(userProfile(profile));
        dispatch(snackbarInfo({ open: true, type: 'success', message: res.data }));
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    })
    .finally(() => setLiked(false));
};

export const unlikeBrand = ({
  brandId,
  setLiked,
  profile,
  data,
  actualData,
  dispatch,
  action,
  actualAction,
  userProfile,
  snackbarInfo,
}) => {
  setLiked(true);
  brandService
    .unlikeBrand(brandId)
    .then((res) => {
      if (res.success) {
        let likedBrands = profile?.likedBrands;
        if (likedBrands.includes(brandId)) {
          profile.likedBrands = likedBrands.filter((e) => e !== brandId);
        }
        let brandsData = data.filter((e) => e._id == brandId);
        brandsData[0].likesCount = brandsData[0].likesCount - 1;
        let newArr = objectReplace(data, brandsData[0], '_id');
        dispatch(action(newArr));
        // Actual Data
        let actualBrandsData = actualData.filter((e) => e._id == brandId);
        actualBrandsData[0].likesCount = actualBrandsData[0].likesCount - 1;
        let newActualArr = objectReplace(actualData, actualBrandsData[0], '_id');
        dispatch(actualAction(newActualArr));
        dispatch(userProfile(profile));
        dispatch(snackbarInfo({ open: true, type: 'success', message: res.data }));
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    })
    .finally(() => setLiked(false));
};
