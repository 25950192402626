import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import WebMynyfyHeader from '../../components/WebMynyfyHeader';
import './webbrands.scss';
import { CircularProgress, Card, IconButton, Drawer } from '@mui/material';
import {
  FavoriteBorderRounded,
  FavoriteRounded,
  ChevronLeftRounded,
  Menu,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { likeBrand, unlikeBrand } from './BrandsHelper';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import {
  fetchActualBrands,
  fetchActualFavBrands,
  fetchBrands,
  fetchFavBrands,
} from '../../redux/actions/brand.action';
import MynyfyTab from '../../components/MynyfyTab';
import AboutBrand from './AboutBrand';
import AllContests from '../Contest/AllContests';
import WebStoresList from '../Stores/WebStoresList';
import brandService from '../../services/brandService';
import Products from '../Products';
import Promos from '../Promos';
import { PromoType } from '../../utils/enums';
import { fetchPromos, fetchSortedPromos } from '../../redux/actions/promo.action';
import SignInModal from '../Auth/SignInModal';
import promoService from '../../services/promoService';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import ProductCartHelper from '../Products/ProductCartHelper';
import aboutUsImage from '../../../src/assets/aboutUs.jpg';
import Policies from '../Stores/Policies';
import { s3ToCloudFront } from '../../utils/CommonMethods';

const WebBrandDetails = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const urlParams = useParams();
  const navigate = useNavigate();

  const profile = useSelector((state) => state.user?.profile);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const brands = useSelector((state) => state.brands);
  const promos = useSelector((state) => state.promos);
  const bag = useSelector((state) => state.bag);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [details, setDetails] = useState(null);
  const [isLiked, setLiked] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [showShippingPolicy, setShowShippingPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    let urlId = urlParams?.brand;
    setBrandName(urlId || '');
    if (state?.brandDetails) {
      setDetails(state?.brandDetails);
    } else if (urlParams?.brand) {
      brandService.getBrandById({ urlId: urlId }).then((res) => {
        if (res.success) {
          setDetails(res.data);
        } else {
          setDetails([]);
        }
      });
    } else {
      setDetails([]);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      if (bag?.id === details._id) {
        // do nothing
      } else {
        dispatch(fetchBagSellingId(details._id));
        dispatch(
          fetchBagAbout({
            type: 'Store',
            name: details.brand,
            url: process.env.REACT_APP_WEB_HOST + '/Brands/' + details.urlId,
          })
        );
        getBuyerPromos(details._id);
        dispatch(fetchBagCalculation(null));
        dispatch(fetchBagProducts(null));
      }
    }
  }, [details]);

  const getBuyerPromos = (id) => {
    let body = { brand: id };
    promoService.getBuyerClaimedPromos(body).then((res) => {
      if (res.success) {
        dispatch(fetchBagOffers(res.data));
      }
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handlePolicyClick = (policy) => {
    setShowRefundPolicy(false);
    setShowShippingPolicy(false);
    setShowPrivacyPolicy(false);
    setShowTerms(false);

    switch (policy) {
      case 'Refund':
        setShowRefundPolicy(true);
        break;
      case 'Shipping':
        setShowShippingPolicy(true);
        break;
      case 'Privacy':
        setShowPrivacyPolicy(true);
        break;
      case 'Terms':
        setShowTerms(true);
        break;
      default:
        break;
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'About Us':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'About Us'} h2 left bold style={{ paddingleft: 30 }} />
            </div>
            <div className='aboutus-container'>
              <div className='content'>
                <MynyfyText title={details.description} center h6 />
              </div>
            </div>
          </div>
        );
      case 'Contact Us':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Contact Us'} h2 center bold style={{ paddingBottom: 5 }} />
            </div>
            <div className='aboutus-container'>
              <div className='content'>
                <div className='contact-content'>
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                    <MynyfyText title={'Address:'} bold h5 />
                    <MynyfyText title={details.address} h6 style={{ marginLeft: 10 }} />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                    <MynyfyText title={'Contact Brand:'} bold h5 />
                    <MynyfyText title={details.customerCareNumber} h6 style={{ marginLeft: 10 }} />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                    <MynyfyText title={'Support Email:'} bold h5 />
                    <MynyfyText title={details.emailId} h6 style={{ marginLeft: 10 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'Policies':
        return (
          <div>
            <div className='Heading'>
              <MynyfyText title={'Our Policies'} h2 center bold style={{ paddingBottom: 5 }} />
            </div>
            <div className='policy-container'>
              <div className='sidebar-menu'>
                <button className='menu-button' onClick={() => handlePolicyClick('Refund')}>
                  <MynyfyText title={'Refund Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Shipping')}>
                  <MynyfyText title={'Shipping Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Privacy')}>
                  <MynyfyText title={'Privacy Policy'} h6 />
                </button>
                <button className='menu-button' onClick={() => handlePolicyClick('Terms')}>
                  <MynyfyText title={'Terms & Conditions'} h6 />
                </button>
              </div>
              {details?.policies ? (
                _.isEmpty(details?.policies?.privacy) &&
                _.isEmpty(details?.policies?.refund) &&
                _.isEmpty(details?.policies?.shipping) &&
                _.isEmpty(details?.policies?.terms) ? (
                  <MynyfyText title={'No Policies Added'} />
                ) : (
                  <div className='content-area'>
                    {showPrivacyPolicy && details.policies.privacy?.length > 0 && (
                      <div>
                        <MynyfyText title={'Privacy Policy'} h3 />
                        <MynyfyText
                          title={details.policies.privacy[0]?.heading}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                        <MynyfyText
                          title={details.policies.privacy[0]?.content}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                      </div>
                    )}

                    {showRefundPolicy && details.policies.refund?.length > 0 && (
                      <div>
                        <MynyfyText title={'Refund Policy'} h3 />
                        <MynyfyText
                          title={details.policies.refund[0]?.heading}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                        <MynyfyText
                          title={details.policies.refund[0]?.content}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                      </div>
                    )}

                    {showShippingPolicy && details.policies.shipping?.length > 0 && (
                      <div>
                        <MynyfyText title={'Shipping Policy'} h3 />
                        <MynyfyText
                          title={details.policies.shipping[0]?.heading}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                        <MynyfyText
                          title={details.policies.shipping[0]?.content}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                      </div>
                    )}

                    {showTerms && details.policies.terms?.length > 0 && (
                      <div>
                        <MynyfyText title={'Terms & Conditions'} h3 />
                        <MynyfyText
                          title={details.policies.terms[0]?.heading}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                        <MynyfyText
                          title={details.policies.terms[0]?.content}
                          h5
                          style={{ paddingTop: 10 }}
                        />
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div className='nopolicy'>
                  <MynyfyText title={'No Policies Found'} h2 center />
                </div>
              )}
            </div>
          </div>
        );
      case 'Landing Page':
        setActiveComponent(null);
        return null;
    }
  };

  const likeHandler = (brandId, key) => {
    if (isLoggedIn) {
      let options = {
        brandId,
        setLiked,
        profile,
        data: state?.isFav ? brands?.favBrands : brands?.brands,
        actalData: state?.isFav ? brands?.actualFavBrands : brands?.actualBrands,
        dispatch,
        action: state?.isFav ? fetchFavBrands : fetchBrands,
        actualAction: state?.isFav ? fetchActualFavBrands : fetchActualBrands,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeBrand(options);
      } else {
        unlikeBrand(options);
      }
    } else {
      setOpenSignIn(true);
    }
  };

  return details ? (
    _.isEmpty(details) ? (
      <Fragment>
        <WebMynyfyHeader title={brandName || 'Brand Page'} noBack />
        <MynyfyEmptyMsg message={['We are unable to fetch the', brandName]} bold={[1]} />
      </Fragment>
    ) : (
      <div id='WebBrands'>
        <Card className='rowSB mynyfyHeader' style={{ boxShadow: 'none' }}>
          <div style={{ display: 'flex' }}>
            {/* {state?.brandDetails ? (
              <ChevronLeftRounded
                style={{ width: 30, height: 30, cursor: 'pointer' }}
                onClick={() => navigate(-1)}
              />
            ) : null} */}
            <div style={{ paddingTop: 4 }}>
              <MynyfyText
                title={details?.brand}
                onClick={() => setActiveComponent('Landing Page')}
                h4
                bold
                style={{ paddingLeft: 30, cursor: 'pointer' }}
              />
            </div>
          </div>
          <div className='MenuItems'>
            <MynyfyText
              title={'About Us'}
              onClick={() => setActiveComponent('About Us')}
              h6
              color={'black'}
              className='menu-item'
              style={{ cursor: 'pointer' }}
            />

            <MynyfyText
              title={'Contact Us'}
              onClick={() => setActiveComponent('Contact Us')}
              h6
              color={'black'}
              className='menu-item'
              style={{ cursor: 'pointer' }}
            />
            <MynyfyText
              title={'Policies'}
              onClick={() => setActiveComponent('Policies')}
              h6
              color={'black'}
              className='menu-item'
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className='right-side'>
            <IconButton onClick={handleDrawerOpen} className='burger-menu'>
              <Menu />
            </IconButton>
          </div>

          {/* /*{' '}
          <div className='right-side' style={{ paddingRight: 30 }}>
            <ProductCartHelper navFrom='brand' brandDetails={details} />
          </div>{' '}
          */}
        </Card>
        <Drawer
          anchor='top'
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{
            '& .MuiDrawer-paper': {
              width: 200,
              top: 60,
              left: 220,
              margin: '0 auto',
              height: 'auto',
            },
          }}
          id='WebBrands'>
          <div
            style={{ width: '100%', cursor: 'pointer' }}
            role='presentation'
            onClick={handleDrawerClose}
            onKeyDown={handleDrawerClose}>
            <div className='row drawerOption' onClick={() => setActiveComponent('About Us')}>
              <MynyfyText title={'About Us'} h5 color={'black'} />
            </div>
            <div className='row drawerOption' onClick={() => setActiveComponent('Contact Us')}>
              <MynyfyText title={'Contact Us'} h5 color={'black'} />
            </div>
            <div className='row drawerOption' onClick={() => setActiveComponent('Policies')}>
              <MynyfyText title={'Policies'} h5 color={'black'} />
            </div>
          </div>
        </Drawer>
        {activeComponent ? (
          <div className='container'>{renderComponent()}</div>
        ) : (
          <div className='container'>
            <div style={{ position: 'relative' }}>
              {details.banner?.[0]?.url ? (
                <img src={s3ToCloudFront(details.banner?.[0].url)} className='banner' />
              ) : (
                <div className='banner noBanner'>
                  <MynyfyText title={details.brand} italic h2 className='noBannerText' />
                </div>
              )}
              <div className='logoContainer'>
                <img src={s3ToCloudFront(details.logo?.url)} className='logo' />
              </div>
            </div>

            {/* <div style={{ margin: '0px 20px 20px' }}>
            <MynyfyText title={details?.brand} className='brandName' bold h5 />
            <div className='rowSB' style={{ marginTop: 10, padding: 0 }}>
              <div className='row' style={{ gap: 0 }}>
                <MynyfyText title={details.likesCount} bold pBig />
                &nbsp;
                <MynyfyText title={details.likesCount == 1 ? 'follower' : 'followers'} />
              </div>
              <div className='row'>
                {isLiked ? (
                  <CircularProgress size={16} />
                ) : profile?.likedBrands?.includes(details._id) ? (
                  <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                    <FavoriteRounded fontSize='small' className='followBtn' />
                    <MynyfyText title={'Following'} />
                  </div>
                ) : (
                  <div
                    className='row'
                    style={{ gap: 0 }}
                    onClick={() => likeHandler(details._id, 'like')}>
                    <FavoriteBorderRounded fontSize='small' className='followBtn' />
                    <MynyfyText title={'Follow'} />
                  </div>
                )}
              </div>
            </div>
          </div> */}
            {/* <div className='divider' /> */}

            <div className='brandtabs'>
              <MynyfyText title={'OUR STORES'} h3 bold center style={{ paddingTop: 30 }} />
              <WebStoresList
                emptyListMsg={'No Stores available currently in your area'}
                brandDetails={details}
              />
            </div>
          </div>
        )}
        <div className='Brandfooter'>
          <div className='footer-menu'>
            <MynyfyText
              title={'About Us'}
              onClick={() => setActiveComponent('About Us')}
              h6
              color={'black'}
              className='menu-item'
              style={{ cursor: 'pointer' }}
            />
            <MynyfyText
              title={'Contact Us'}
              onClick={() => setActiveComponent('Contact Us')}
              h6
              color={'black'}
              className='menu-item'
              style={{ cursor: 'pointer' }}
            />
            <MynyfyText
              title={'Policies'}
              onClick={() => setActiveComponent('Policies')}
              h6
              color={'black'}
              className='menu-item'
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ padding: 10 }}>© 2024 {details?.brand} All rights reserved</div>
        </div>

        <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
      </div>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default WebBrandDetails;
