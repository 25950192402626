import React from 'react';
import Slide from '@mui/material/Slide';
import { Modal } from '@mui/material';
import './common.scss';

const MynyfyModal = ({ open, close, children, style, position }) => {
  return (
    <Modal open={open} onClose={close} id='MynyfyModal'>
      <div
        className='mainContainer'
        onClick={(event) => {
          if (event.target !== event.currentTarget) {
            return;
          }
          close();
        }}>
        <Slide direction={'up'} in={open}>
          <div
            className='subContainer'
            style={{
              ...(position === 'center' && { margin: 'auto' }),
              ...(position === 'top' && { margin: '0px auto auto' }),
            }}>
            <div className='childContainer' style={style}>
              {children}
            </div>
          </div>
        </Slide>
      </div>
    </Modal>
  );
};

export default MynyfyModal;
