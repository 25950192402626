import React from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import theme from '../utils/theme';
import ProgressBarAnimation from '../assets/animations/progressBar.json';

const ProgressBar = ({ currPosition, itemIndex }) => {
  const adTimer = useSelector((state) => state.adTimer);
  const animeTimer = adTimer ?? 3;

  return (
    <div>
      {currPosition == itemIndex ? (
        <Lottie
          options={{ autoplay: true, loop: false, animationData: ProgressBarAnimation }}
          style={{ width: theme.WIDTH, height: 20 }}
          speed={2 / animeTimer}
        />
      ) : null}
    </div>
  );
};

export default ProgressBar;
