import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_REGISTER,
  USER_DEVICE_ID,
  USER_TOKEN,
  USER_PROFILE,
} from '../actionTypes';

const INITIAL_STATE = {
  isLoggedIn: false,
  user: null,
  deviceId: null,
  token: null,
  profile: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case USER_REGISTER:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    case USER_DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    case USER_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
