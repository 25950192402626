import React, { useState } from 'react';
import MynyfyButton from '../../../components/MynyfyButton';
import { useDispatch, useSelector } from 'react-redux';
import MynyfyText from '../../../components/MynyfyText';
import '../contest.scss';
import _ from 'lodash';
import { MsgEnums } from '../../../utils/MsgEnums';
import MynyfyModal from '../../../components/MynyfyModal';
import contestService from '../../../services/contestService';
import transactionService from '../../../services/transactionService';
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import { fetchMynts } from '../../../redux/actions/mynts.action';
import { objectReplace } from '../../../utils/CommonMethods';
import { fetchJackpot } from '../../../redux/actions/contest.action';
import CloseIcon from '@mui/icons-material/Close';

const JoinJackpot = ({ isJoinDrawModal, joinDrawObj, close, errMsg }) => {
  const dispatch = useDispatch();
  const jackpots = useSelector((state) => state.contest?.jackpot);
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;

  const [isClicked, setIsClicked] = useState(false);

  const closeModal = () => {
    setIsClicked(false);
    close();
  };

  const joinDraw = async () => {
    setIsClicked(true);
    if (joinDrawObj) {
      let data = {
        _id: joinDrawObj._id,
        buyerId: id,
        image: null,
        amount: 0,
      };
      contestService.enterContest(data).then((res) => {
        if (res.success) {
          let reqObj = jackpots?.find((e) => e._id == joinDrawObj._id);
          if (reqObj) {
            reqObj.isContested = true;
            let newData = objectReplace(jackpots, reqObj, '_id');
            dispatch(fetchJackpot(newData));
          }
          getPoints();
          dispatch(
            snackbarInfo({
              open: true,
              type: 'success',
              message: `Thank you for Participating in ${joinDrawObj.name}`,
            })
          );

          errMsg(false);
          closeModal();
        } else {
          if (res?.message?.toLowerCase().includes('Insufficient MYNTS'.toLowerCase())) {
            errMsg(true);
          }
          setIsClicked(false);
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
          close();
        }
      });
    } else {
      setIsClicked(false);
      dispatch(snackbarInfo({ open: true, type: 'error', message: MsgEnums.FILL_DETAILS }));
    }
  };

  const getPoints = () => {
    transactionService.getPoints().then((res) => {
      if (res?.data?.points) {
        dispatch(fetchMynts(res?.data?.points));
      }
    });
  };

  return joinDrawObj && isJoinDrawModal ? (
    <MynyfyModal open={isJoinDrawModal} close={() => closeModal()}>
      <div className='modalContainer'>
        <div style={{ textAlign: 'end' }}>
          <CloseIcon
            style={{ color: 'grey' }}
            onClick={() => {
              if (!isClicked) close();
            }}
            className='cursor'
          />
        </div>
        <MynyfyText
          title={`Join this Jackpot for ${joinDrawObj.myntsToJoin} Mynts`}
          primary
          bold
          h6
          style={{ textAlign: 'center', padding: 10, paddingBottom: 20 }}
        />
        <div className='rowSA'>
          {/* <MynyfyButton
            label={'Cancel'}
            style={{
              borderRadius: 50,
              paddingVertical: 8,
              paddingHorizontal: 15,
              backgroundColor: 'red',
            }}
            textStyle={{ fontSize: 14 }}
            onClick={() => close()}
            disabled={isClicked}
          /> */}
          <MynyfyButton
            label={'Join'}
            center
            style={{ borderRadius: 50, paddingVertical: 8, paddingHorizontal: 15 }}
            onClick={() => joinDraw()}
            disabled={isClicked}
            loading={isClicked}
          />
        </div>
      </div>
    </MynyfyModal>
  ) : null;
};

export default JoinJackpot;
