import React, { useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyInput from '../../components/MynyfyInput';
import MynyfyDoubleCard from '../../components/MynyfyDoubleCard';
import brandService from '../../services/brandService';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import './brands.scss';
import { AddCircleRounded, FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { userProfile } from '../../redux/actions/user.action';
import _ from 'lodash';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { useNavigate } from 'react-router-dom';
import { likeBrand, unlikeBrand } from './BrandsHelper';
import { MynyfyMails } from '../../utils/MynyfyEnums';
import SignInModal from '../Auth/SignInModal';
import { Box } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import MynyfySelect from '../../components/MynyfySelect';
import { MyLocationRounded } from '@mui/icons-material';
import theme from '../../utils/theme';

const BrandsList = ({ isFav, data, actualData, action, actualAction, emptyListMsg }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useSelector((state) => state?.location?.location);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const categories = useSelector((state) => state.appData?.categories);
  const profile = useSelector((state) => state?.user?.profile);
  const [filterType, setFilterType] = useState('');

  const [isLiked, setLiked] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [category, setCategory] = useState('');
  const [searchBrand, setSearchBrand] = useState('');

  const [isLocationModal, setIsLocationModal] = useState(false);

  useEffect(() => {
    let isFavApiCall = isFav
      ? profile?.likedBrands?.length !== data?.length
        ? true
        : false
      : false;

    if (_.isEmpty(data) || isFavApiCall) {
      if (isFav) {
        if (isLoggedIn) {
          getFavBrands();
        } else {
          dispatch(action([]));
          dispatch(actualAction([]));
        }
      } else {
        getBrands();
      }
    } else if (!_.isEmpty(data) && !isFav && data?.length !== actualData?.length) {
      dispatch(action(actualData));
    }
  }, []);

  const getFavBrands = () => {
    brandService.getLikedBrands().then((res) => {
      apiResponseHandler(res);
    });
  };

  const getBrands = () => {
    let params = {
      isActive: true,
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
    };

    brandService.getBrands(params).then((res) => {
      apiResponseHandler(res);
    });
  };

  const apiResponseHandler = (res) => {
    if (res.success) {
      dispatch(action(res.data));
      dispatch(actualAction(res.data));
    } else {
      dispatch(action([]));
      dispatch(actualAction([]));
      dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
    }
  };

  const likeHandler = (brandId, key) => {
    if (isLoggedIn) {
      let options = {
        brandId,
        setLiked,
        profile,
        data,
        actualData,
        dispatch,
        action,
        actualAction,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeBrand(options);
      } else {
        unlikeBrand(options);
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const footer = (item) => {
    return (
      <div className='rowSB footer'>
        <MynyfyText title={'Stores'} link onClick={() => navHandler(item)} />
        <div className='row'>
          {isLiked ? (
            <CircularProgress size={16} />
          ) : profile?.likedBrands?.includes(item._id) ? (
            <FavoriteRounded
              color='error'
              fontSize=''
              style={{ cursor: 'pointer' }}
              onClick={() => likeHandler(item._id, 'unlike')}
            />
          ) : (
            <FavoriteBorderRounded
              color='error'
              fontSize=''
              style={{ cursor: 'pointer' }}
              onClick={() => likeHandler(item._id, 'like')}
            />
          )}
          <MynyfyText title={item.likesCount} style={{ marginLeft: 2 }} />
        </div>
        <MynyfyText title={'Details'} link onClick={() => navHandler(item)} />
      </div>
    );
  };

  const navHandler = (item) => {
    navigate('/Brands/' + item.urlId, { state: { isFav, brandDetails: item } });
  };

  const searchBrandsHandler = (key) => {
    if (key) {
      let modData = [];
      actualData.map((res) => {
        if (res.brand.toLowerCase().includes(key.toLowerCase())) {
          modData.push(res);
        }
      });
      dispatch(action(modData));
    } else {
      dispatch(action(actualData));
    }
  };

  const filterBrandsByCategory = (id) => {
    if (id) {
      let modData = [];
      actualData.map((res) => {
        res.category.map((e) => {
          if (e._id == id) modData.push(res);
        });
      });
      dispatch(action(modData));
    } else {
      dispatch(action(actualData));
    }
  };

  const handelFilterType = (key) => {
    setFilterType((filterType) => (filterType === key ? null : key));
  };

  return data ? (
    <div id='Brands'>
      <div onClick={() => setIsLocationModal(true)} className='cursor'>
        <div className='row' style={{ justifyContent: 'end', marginTop: '15px' }}>
          <MyLocationRounded fontSize='small' color='primary' />
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
            <MynyfyText title={'Current Location:'} color={theme.MUTED_BLUE} pBig style={{}} />
            <MynyfyText
              title={location?.area?.name}
              color={theme.NAVY_BLUE}
              pBig
              style={{ marginLeft: 10, marginRight: 20 }}
            />
          </div>
        </div>
      </div>
      <div className='divider' />
      <div className='row' style={{ justifyContent: 'flex-end' }}>
        <div className='filterContainer' style={{ display: 'flex' }}>
          <div className='filterOption'>
            <Box>
              <SearchOutlinedIcon
                color='primary'
                className='cursor'
                onClick={() => handelFilterType('Search')}
              />
            </Box>
          </div>

          <div className='filterOption'>
            <Box>
              <FilterListIcon
                color='primary'
                className='cursor'
                onClick={() => handelFilterType('Filter')}
              />
            </Box>
          </div>
          {/* <div className='filterOption' style={{ borderRight: 0 }}>
            <Box>
              <AddCircleRounded
                color='primary'
                className='cursor'
                onClick={() => window.open(MynyfyMails.brandMail)}
              />
            </Box>
          </div> */}
        </div>
      </div>
      {filterType === 'Search' ? (
        <MynyfyInput
          type={'text'}
          fullWidth={true}
          placeHolder={'Search by Brands'}
          value={searchBrand}
          onChange={(e) => {
            setSearchBrand(e.target.value);
            searchBrandsHandler(e.target.value);
          }}
          style={{ marginTop: 10 }}
        />
      ) : null}
      {filterType === 'Filter' ? (
        <div style={{ margin: '10px 0px' }}>
          <MynyfySelect
            placeholder={'Category'}
            value={category}
            onChange={(e) => {
              setCategory(e);
              filterBrandsByCategory(e?.value);
            }}
            isClear
            list={categories.map((res) => ({ label: res.name, value: res._id, data: res.data }))}
          />
        </div>
      ) : null}
      {_.isEmpty(data) ? (
        <MynyfyEmptyMsg message={emptyListMsg} />
      ) : (
        <MynyfyDoubleCard
          data={data}
          onClick={(item) => navHandler(item)}
          footer={(item) => footer(item)}
        />
      )}
      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default BrandsList;
