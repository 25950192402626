import React, { Fragment } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyHeader from '../../components/MynyfyHeader';

const AboutUs = () => {
  return (
    <Fragment>
      <MynyfyHeader title={'About Us'} />
      <div style={{ padding: 20 }}>
        <MynyfyText
          title={
            'MYNYFY, a retail tech hyperlocal sales & marketing platform provides brands with a brand page where they can showcase about itself and their product line, offline stores for a user to buy from a nearby store & marketing features to engage their customers through contests, ads, jackpots, coupons. It is like a website in itself.'
          }
          style={{ paddingBottom: 20 }}
        />
        <MynyfyText
          title={"For MYNYFY users is a marketplace of all brand's websites at one place"}
          style={{ paddingBottom: 20 }}
        />
        <MynyfyText
          title={
            'It is one platform to manage their operations (inventory, POS etc.),  local O&O marketplace eCommerce to receive & deliver orders within a location, and to engage their customers effectively through various marketing services within the platform.'
          }
          style={{ paddingBottom: 20 }}
        />
        <MynyfyText
          title={
            'One platform to manage all their online & offline store network be it franchises or company-owned.'
          }
          style={{ paddingBottom: 20 }}
        />
      </div>
    </Fragment>
  );
};

export default AboutUs;
