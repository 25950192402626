import { MYNYFY_STATS } from '../actionTypes';

const INITIAL_STATE = null;

const statsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MYNYFY_STATS:
      return action.payload;
    default:
      return state;
  }
};

export default statsReducer;
