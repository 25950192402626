import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import theme from '../../utils/theme';
import { websiteClick } from './AdsHelper';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import {
  LanguageRounded,
  ShareRounded,
  StarOutlineRounded,
  StarRounded,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MynyfyAlert from '../../components/MynyfyAlert';
import { s3ToCloudFront } from '../../utils/CommonMethods';

const AdItemFooter = ({ item, onShare, handleInterest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const shareInterest = () => {
    if (item.isUserInterested) {
      dispatch(
        snackbarInfo({
          type: 'warning',
          open: true,
          message: ['Cheers!', 'Your interest already has been shared with the Ad publisher'],
        })
      );
    } else {
      setOpen(true);
    }
  };

  return (
    <Fragment>
      <div className='rowSB' style={{ padding: '0px 20px' }}>
        <div onClick={onShare} className='btnHolder'>
          <ShareRounded className='feedFooterIcon cursor' />
          <MynyfyText title={'Share'} className='cursor' small />
        </div>
        {item.isInterested ? (
          <div className='btnHolder' onClick={shareInterest}>
            {item.isUserInterested ? (
              <StarRounded
                className='feedFooterIcon cursor feedFooterStarIcon'
                style={{ color: theme.WARNING }}
              />
            ) : (
              <StarOutlineRounded className='feedFooterIcon cursor feedFooterStarIcon' />
            )}
            <MynyfyText
              title={item.isUserInterested ? 'Interest Shared' : 'Share Interest'}
              className='cursor'
              small
            />
          </div>
        ) : (
          <div className='btnHolder' />
        )}
        {item?.brand ? (
          <div className='btnHolder' onClick={() => navigate('/Brands/' + item.brand?.urlId)}>
            <img
              src={s3ToCloudFront(item.brand.logo ? item.brand.logo.url : item.image)}
              className='brandLogo cursor'
            />
            <MynyfyText title={'Go to Page'} className='cursor' small />
          </div>
        ) : (
          <div className='btnHolder' />
        )}
        {item?.url ? (
          <div className='btnHolder' onClick={() => websiteClick(item._id, item.url)}>
            <LanguageRounded className='feedFooterIcon cursor' />
            <MynyfyText title={'Visit Us'} className='cursor' small />
          </div>
        ) : (
          <div className='btnHolder' />
        )}
      </div>

      <MynyfyAlert
        open={isOpen}
        close={() => setOpen(false)}
        title={'Are you sure?'}
        content={
          'Your details including mobile number, email id & location will be shared with the Ad publisher.'
        }
        action1={{
          label: 'No',
          handler: () => setOpen(false),
          color: 'black',
        }}
        action2={{
          label: 'Yes',
          handler: () => {
            handleInterest(item._id);
            setOpen(false);
          },
        }}
      />
    </Fragment>
  );
};

export default AdItemFooter;
