import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MynyfyText from '../../components/MynyfyText';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyHeader from '../../components/MynyfyHeader';
import { ContentCopyRounded } from '@mui/icons-material';
import MynyfyButton from '../../components/MynyfyButton';
import profileService from '../../services/profileService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import ReferAnime from '../../assets/animations/refer.json';
import { WATCH_PROFILE } from '../../redux/actionTypes';
import { onShare } from './referHelper';
import Lottie from 'react-lottie';
import './refer.scss';

const Refer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const profile = user.profile;
  const referPoints = useSelector((state) => state.referPoints);

  const getCode = () => {
    profileService.getRefferalCode().then((res) => {
      if (res.success) {
        dispatch({ type: WATCH_PROFILE });
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  return (
    <Fragment>
      <MynyfyHeader title={'Refer'} />
      <div id='Refer'>
        <Lottie
          options={{ animationData: ReferAnime, loop: true, autoplay: true }}
          style={{ width: 'min(80vw, 340px)', height: 'min(80vw, 340px)' }}
        />
        <div style={{ textAlign: 'center' }}>
          <MynyfySpan title={'Refer your friend and earn '} pBig />
          <MynyfySpan title={referPoints + ' Mynts '} primary pBig />
          <MynyfySpan title={'per referral'} pBig />
        </div>
        {profile?.referralCode ? (
          <Fragment>
            <MynyfyText title={'Your Refferal Code'} pBig center style={{ marginTop: 20 }} />
            <div
              className='row codeContainer link'
              onClick={() => {
                navigator.clipboard.writeText(profile?.referralCode);
                dispatch(
                  snackbarInfo({ type: 'success', open: true, message: 'Copied Referral Code' })
                );
              }}>
              <MynyfyText title={profile?.referralCode} bold h6 style={{ marginRight: 10 }} />
              <ContentCopyRounded fontSize='small' />
            </div>
          </Fragment>
        ) : (
          <MynyfyButton
            label={'Get Referral Code'}
            containerStyle={{ textAlign: 'center', margin: 20 }}
            onClick={() => getCode()}
          />
        )}
        <MynyfyButton
          label={'Share with your friends'}
          containerStyle={{ textAlign: 'center' }}
          onClick={() => onShare(profile?.referralCode)}
        />
      </div>
    </Fragment>
  );
};
export default Refer;
