import React from 'react';
import { useSelector } from 'react-redux';
import MynyfyTab from '../../components/MynyfyTab';
import StoresList from '../Stores/StoresList';
import BrandsList from '../Brands/BrandsList';
import { fetchFavStores } from '../../redux/actions/store.action';
import { fetchFavBrands } from '../../redux/actions/brand.action';

const Favourites = () => {
  const favBrands = useSelector((state) => state.brands?.favBrands);
  const favStores = useSelector((state) => state.stores?.favStores);

  return (
    <MynyfyTab
      tabs={['Stores', 'Brands']}
      tabComponent={[
        <StoresList
          isFav={true}
          data={favStores}
          action={fetchFavStores}
          emptyListMsg={"You haven't added any Stores to your favourites"}
        />,
        <BrandsList
          isFav={true}
          data={favBrands}
          action={fetchFavBrands}
          emptyListMsg={"You haven't added any Brands to your favourites"}
        />,
      ]}
    />
  );
};

export default Favourites;
