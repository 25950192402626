import React from 'react';
import MynyfyTab from '../../../components/MynyfyTab';
import Contest from '..';
import { ContestType } from '../../../utils/enums';
import { useSelector } from 'react-redux';
import { fetchScanAndWin, fetchScanAndWinHistory } from '../../../redux/actions/contest.action';

const ScanAndWin = ({ noBackBtn }) => {
  const scanAndWin = useSelector((state) => state.contest?.scanAndWin);
  const scanAndWinHistory = useSelector((state) => state.contest?.scanAndWinHistory);

  return (
    <MynyfyTab
      noBackBtn={noBackBtn}
      tabs={['Active', 'Winners']}
      tabComponent={[
        <Contest
          type={ContestType.SCAN_AND_WIN}
          status={'active'}
          isCategories={false}
          emptyListTitle={'Scan to Join'}
          draws={scanAndWin}
          action={fetchScanAndWin}
        />,
        <Contest
          type={ContestType.SCAN_AND_WIN}
          status={'NOACTIVE'}
          isCategories={false}
          emptyListTitle={'Scan to Join'}
          draws={scanAndWinHistory}
          action={fetchScanAndWinHistory}
          isHistory={true}
        />,
      ]}
    />
  );
};

export default ScanAndWin;
