import React, { Fragment, useState } from 'react';
import theme from '../utils/theme';
import '../components/common.scss';

import Home from '../screens/Home';
import DayDeal from '../screens/Promos/DayDeal';
import Orders from '../screens/Orders';
import AdsFeed from '../screens/AdsFeed';
import Jackpot from '../screens/Contest/Jackpot';
import Rewards from '../screens/Promos/Rewards';
import ShopAndWin from '../screens/Contest/ShopAndWin';
import ScanAndWin from '../screens/Contest/ScanAndWin';

import { ReactComponent as HomeIcon } from '../assets/svg/home.svg';
import { ReactComponent as OrdersIcon } from '../assets/svg/basket.svg';
import { ReactComponent as JackpotIcon } from '../assets/svg/jackpot.svg';
import { ReactComponent as SeeAndWinIcon } from '../assets/svg/see_and_win.svg';
import { ReactComponent as RewardsIcon } from '../assets/svg/reward.svg';
import { ReactComponent as DealIcon } from '../assets/svg/deals.svg';
import { ReactComponent as EarnIcon } from '../assets/svg/earn.svg';
import { ReactComponent as ScanAndWinIcon } from '../assets/svg/scan_and_win.svg';
import ShopAndWinInActiveIcon from '../assets/imgs/BottomBar/Shop_Win_inactive.png';
import ShopAndWinActiveIcon from '../assets/imgs/BottomBar/Shop_Win_active.png';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import MynyfyText from '../components/MynyfyText';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBottomTab } from '../redux/actions/bottomTab.action';
import { AddCircleRounded } from '@mui/icons-material';
import SelectAdType from '../components/SelectAdType';

const bottomNavList = [
  {
    key: 'home',
    label: 'Home',
    icon: HomeIcon,
    component: <Home />,
  },
  {
    key: 'Ads',
    label: 'See & Earn',
    icon: AdsClickOutlinedIcon,
    iconStyle: { width: 23, height: 25 },
    component: <AdsFeed goHome />,
  },
  {
    key: 'PublishAds',
    label: 'Publish Ads',
    icon: AddCircleRounded,
    iconStyle: { width: 23, height: 25 },
  },
  {
    key: 'Deals',
    label: 'Deals',
    icon: DealIcon,
    iconStyle: { width: 27, height: 27 },
    component: <DayDeal goHome />,
  },
  // {
  //   key: 'jackpots',
  //   label: 'Jackpots',
  //   icon: JackpotIcon,
  //   iconStyle: { width: 35, height: 35, paddingTop: 1, marginBottom: -5 },
  //   component: <Jackpot goHome />,
  // },
  // {
  //   key: 'Rewards',
  //   label: 'Rewards',
  //   icon: RewardsIcon,
  //   component: <Rewards goHome />,
  // },
  {
    key: 'orders',
    label: 'Orders',
    icon: OrdersIcon,
    iconStyle: { width: 27, height: 27 },
    component: <Orders goHome />,
  },

  // {
  //   key: 'shop',
  //   label: 'Shop to Join',
  //   activeIcon: ShopAndWinActiveIcon,
  //   inActiveIcon: ShopAndWinInActiveIcon,
  //   isImg: true,
  //   iconStyle: { height: 32, paddingTop: 2, marginBottom: -2 },
  //   component: <ShopAndWin goHome />,
  // },

  // {
  //   key: 'scan',
  //   label: 'Scan to Join',
  //   icon: ScanAndWinIcon,
  //   iconStyle: { width: 25, height: 25 },
  //   component: <ScanAndWin goHome />,
  // },
];

const BottomTabs = () => {
  const dispatch = useDispatch();
  const bottomTab = useSelector((state) => state.bottomTab);

  const [openAdModal, setOpenAdModal] = useState(false);

  const bottomTabHandler = (key) => {
    if (key == 'PublishAds') {
      setOpenAdModal(true);
    } else {
      dispatch(fetchBottomTab(key));
    }
  };

  return (
    <Fragment>
      <div id='BottomTabs'>
        {bottomTab ? (
          bottomNavList.map((res, i) =>
            bottomTab == res.key ? <Fragment key={i}>{res.component}</Fragment> : null
          )
        ) : (
          <Home />
        )}
        <div style={{ position: 'relative', height: 60 }}>
          <div className='rowSA bottomNav'>
            {bottomNavList.map((res, i) => (
              <div key={i} className='bottomTabOption' onClick={() => bottomTabHandler(res.key)}>
                {res.isImg ? (
                  <img
                    src={bottomTab === res.key ? res.activeIcon : res.inActiveIcon}
                    className='bottomTabIcon'
                    style={res.iconStyle}
                  />
                ) : (
                  <res.icon
                    className='bottomTabIcon'
                    style={{
                      ...res.iconStyle,
                      fill: bottomTab === res.key ? theme.PRIMARY_BLUE : '#000',
                    }}
                  />
                )}
                <MynyfyText
                  title={res.label}
                  bold
                  small
                  primary={bottomTab === res.key}
                  className='bottomTabLabel'
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <SelectAdType
        isVisible={openAdModal}
        closeModal={() => setOpenAdModal(false)}
        flashAdScreen={'/FlashAds/Create'}
        adScreen={'/PublishAds/Create'}
      />
    </Fragment>
  );
};

export default BottomTabs;
