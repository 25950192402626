import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { fetchOnlineFoodStores } from '../../redux/actions/store.action';
import StoresList from './StoresList';
import MynyfyHeader from '../../components/MynyfyHeader';

const OnlineFoodStores = () => {
  const onlineFoodStores = useSelector((state) => state.stores?.onlineFoodStores);

  return (
    <Fragment>
      <MynyfyHeader title={'Home Delivery Only'} />
      <StoresList
        isFav={false}
        online={true}
        data={onlineFoodStores}
        action={fetchOnlineFoodStores}
        isRestaurant={true}
        emptyListMsg={'We are bringing Home Delivery stores to your location'}
      />
    </Fragment>
  );
};
export default OnlineFoodStores;
