import {
  FETCH_STORES,
  FETCH_STORES_DETAILS,
  FETCH_FAV_STORES,
  FETCH_FAV_OFFLINE_STORES,
  FETCH_OFFLINE_STORES,
  FETCH_OFFLINE_FOOD_STORES,
  FETCH_ONLINE_FOOD_STORES,
} from '../actionTypes';

export const fetchStores = (stores) => ({
  type: FETCH_STORES,
  payload: stores,
});

export const fetchFavStores = (favStores) => ({
  type: FETCH_FAV_STORES,
  payload: favStores,
});

export const fetchOfflineStores = (offlineStores) => ({
  type: FETCH_OFFLINE_STORES,
  payload: offlineStores,
});

export const fetchFavOfflineStores = (favOfflineStores) => ({
  type: FETCH_FAV_OFFLINE_STORES,
  payload: favOfflineStores,
});

export const fetchOfflineFoodStores = (offlineFoodStores) => ({
  type: FETCH_OFFLINE_FOOD_STORES,
  payload: offlineFoodStores,
});

export const fetchOnlineFoodStores = (onlineFoodStores) => ({
  type: FETCH_ONLINE_FOOD_STORES,
  payload: onlineFoodStores,
});

export const fetchStoreDetails = (storeDetails) => ({
  type: FETCH_STORES_DETAILS,
  payload: storeDetails,
});
