import {
  FETCH_FAV_OFFLINE_STORES,
  FETCH_FAV_STORES,
  FETCH_OFFLINE_FOOD_STORES,
  FETCH_OFFLINE_STORES,
  FETCH_ONLINE_FOOD_STORES,
  FETCH_STORES,
  FETCH_STORES_DETAILS,
} from '../actionTypes';

const INITIAL_STATE = {
  stores: null,
  favStores: null,
  offlineStores: null,
  favOfflineStores: null,
  offlineFoodStores: null,
  onlineFoodStores: null,
  details: null,
};

const storeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STORES:
      return {
        ...state,
        stores: action.payload,
      };
    case FETCH_FAV_STORES:
      return {
        ...state,
        favStores: action.payload,
      };
    case FETCH_OFFLINE_STORES:
      return {
        ...state,
        offlineStores: action.payload,
      };
    case FETCH_FAV_OFFLINE_STORES:
      return {
        ...state,
        favOfflineStores: action.payload,
      };
    case FETCH_OFFLINE_FOOD_STORES:
      return {
        ...state,
        offlineFoodStores: action.payload,
      };
    case FETCH_ONLINE_FOOD_STORES:
      return {
        ...state,
        onlineFoodStores: action.payload,
      };
    case FETCH_STORES_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};

export default storeReducer;
