import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyModal from '../../components/MynyfyModal';
import MynyfyButton from '../../components/MynyfyButton';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../services/authService';
import validate from '../../utils/validate';
import { locationValidator, profileValidator, registrationValidator } from '../../utils/validators';
import _ from 'lodash';
import { SyncProfile } from '../../utils/enums';
import { MsgEnums } from '../../utils/MsgEnums';
import { setAuthToken } from '../../utils/apiHelper';
import jwtDecode from 'jwt-decode';
import profileService from '../../services/profileService';
import { userLogin, userToken } from '../../redux/actions/user.action';
import { WATCH_PROFILE } from '../../redux/actionTypes';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyInput from '../../components/MynyfyInput';
import { Card, Checkbox } from '@mui/material';
import theme from '../../utils/theme';
import MynyfySpan from '../../components/MynyfySpan';
import { useNavigate } from 'react-router-dom';
import LocationFields from '../Location/LocationFields';
import { updateLocation } from '../../redux/actions/location.action';
import { s3ToCloudFront } from '../../utils/CommonMethods';

const SignInModal = ({ open, close, isGuest, guestLoginForDineIn, sellerDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useSelector((state) => state.location?.location);

  const [mobile, setMobile] = useState('');
  const [firstName, setFirstName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');
  const [step, setStep] = useState('verify');
  const [isClicked, setClicked] = useState(false);
  const [error, setError] = useState({});
  const [isOtpSent, setOtpSent] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isProfile, setIsProfile] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [isSetPassword, setIsSetPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const checkProfile = async () => {
    setClicked(true);
    let body = { mobile };
    let errors = validate(body, registrationValidator);
    setError(errors);

    if (_.isEmpty(errors)) {
      await authService
        .profileExist(mobile)
        .then((res) => {
          if (res.profile === null) {
            setOTP('');
            sendOtp();
          } else if (
            res.profile.syncedWith === SyncProfile.BUYER ||
            res.profile.syncedWith === SyncProfile.BUYER_SELLER ||
            res.profile.syncedWith === SyncProfile.BUYER_PARTNER
          ) {
            setStep('login');
            setIsProfile(true);
          } else if (
            res.profile.syncedWith === SyncProfile.PARTNER ||
            res.profile.syncedWith === SyncProfile.SELLER
          ) {
            snackbarHandler({
              type: 'warning',
              message: [
                `This mobile number is registed with ${res.profile.syncedWith}`,
                'Account Sync feature will be comming soon',
                'Please SignUp with new number',
              ],
            });
          }
        })
        .finally(() => setClicked(false));
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const sendOtp = () => {
    let body = { mobile };
    let errors = validate(body, registrationValidator);
    setError(errors);

    if (_.isEmpty(errors)) {
      authService.sendOtp(mobile).then((res) => {
        if (res) {
          setOtpSent(true);
          setStep('register');
          setIsProfile(false);
          snackbarHandler({ type: 'success', message: 'OTP has been sent successfully' });
        } else {
          snackbarHandler({ type: 'error', message: 'Failed to send OTP' });
        }
      });
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const sendOtpForForgotPassword = () => {
    if (mobile) {
      authService.forgotPasswordSendOTP({ mobile }).then((res) => {
        if (res.success) {
          dispatch(snackbarInfo({ open: true, type: 'success', message: 'Otp sent successfully' }));
          setIsForgotPassword(true);
          setOtpSent(true);
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'warning',
          message: ['Mobile number cannot be empty', 'Enter your mobile number'],
        })
      );
    }
  };

  const registerHandler = async () => {
    let obj = { mobile, otp, firstName };
    if (email) obj.email = email;
    // if (_.isEmpty(location?.state)) {
    //   obj.selectedState = selectedState;
    //   obj.selectedCity = selectedCity;
    //   obj.selectedArea = selectedArea;
    // }
    let errors = validate(obj, {
      ...registrationValidator,
      ...profileValidator,
      // ...locationValidator,
    });
    setError(errors);
    setClicked(true);
    if (_.isEmpty(errors)) {
      setSubmitCount(submitCount + 1);
      if (submitCount > 2) {
        setClicked(false);
        setOpen(true);
      } else {
        let body = {
          profile: { mobile: mobile, password: password || mobile },
          otp: otp,
        };
        await authService
          .register(body)
          .then((res) => {
            if (res?.data?.message === 'Profile Created Successfully') {
              loginHandler('register');
            } else if (res?.data?.message === 'OTP Verification Failure.') {
              snackbarHandler({
                type: 'error',
                message: ['OTP Verification Failed', 'please enter correct OTP'],
              });
            } else {
              snackbarHandler({ type: 'error', message: res?.data?.message });
            }
          })
          .catch((err) => {
            snackbarHandler({
              type: 'error',
              message: [MsgEnums.SERVER_ISSUE, MsgEnums.TRY_AGAIN_LATER],
            });
          });
      }
    } else {
      snackbarHandler({ type: 'warning', message: MsgEnums.FILL_DETAILS });
    }
  };

  const loginHandler = (from) => {
    let body = { mobile, password };
    let errObj = { mobile, password };
    if (from === 'register') {
      errObj.password = password || mobile;
      body.password = password || mobile;
    }

    // if (_.isEmpty(location?.state)) {
    //   errObj.selectedState = selectedState;
    //   errObj.selectedCity = selectedCity;
    //   errObj.selectedArea = selectedArea;
    // }
    // let errors = validate(errObj, { ...registrationValidator, ...locationValidator });
    let errors = validate(errObj, registrationValidator);
    setError(errors);
    setClicked(true);
    if (_.isEmpty(errors)) {
      authService
        .login(body)
        .then(async (res) => {
          if (res?.data?.message === 'Authenticated!') {
            if (from === 'register') {
              setAuthToken(res?.data?.token);
              updateProfile(res?.data?.token);
            } else {
              await authService._setToken(res?.data?.token);
              const user = jwtDecode(res?.data?.token);
              dispatch(userToken(res?.data?.token));
              dispatch({ type: WATCH_PROFILE });
              dispatch(userLogin(user));
              // locationApi(true, user);
              close();
            }
          } else {
            snackbarHandler({ type: 'error', message: res?.data?.message });
          }
        })
        .catch((err) => {
          snackbarHandler({
            type: 'error',
            message: ['Unable to login automatically', 'Please login on your own'],
          });
        });
    } else {
      snackbarHandler({ type: 'error', message: MsgEnums.FILL_DETAILS });
    }
  };

  const updateProfile = (token) => {
    const userDetails = jwtDecode(token);
    let data = { firstName };
    if (email) data.email = email;
    const errors = validate(data, profileValidator);
    setError(errors);
    if (_.isEmpty(errors)) {
      // if (_.isEmpty(location?.state) ) {
      //   data.state = selectedState?._id;
      //   data.city = selectedCity?._id;
      //   data.area = selectedArea?._id;
      // }
      profileService.profileUpdate(data, userDetails.id, userDetails.profile).then(async (res) => {
        if (res.success) {
          userDetails.firstName = firstName;
          if (email) userDetails.email = email;
          await authService._setToken(token);
          dispatch(userToken(token));
          dispatch(userLogin(userDetails));
          dispatch({ type: WATCH_PROFILE });
          // locationApi();
          close();
        } else {
          snackbarHandler({ type: 'error', message: res.message });
        }
      });
    }
  };

  const forgotPasswordHandler = () => {
    if (mobile && otp && password) {
      if (password.length >= 6) {
        setClicked(true);
        let body = { number: mobile, code: otp, newPassword: password };
        authService
          .forgotPassword(body)
          .then((res) => {
            if (res.success) {
              setOTP('');
              setOtpSent(false);
              setIsForgotPassword(false);
              dispatch(
                snackbarInfo({
                  open: true,
                  type: 'success',
                  message: ['Password changed successfully', 'Please login'],
                })
              );
            } else {
              dispatch(
                snackbarInfo({ open: true, type: 'error', message: 'Please enter correct otp' })
              );
            }
          })
          .finally(() => setClicked(false));
      } else {
        dispatch(
          snackbarInfo({
            open: true,
            type: 'warning',
            message: 'Password should have minimum 6 characters',
          })
        );
      }
    } else {
      if (!mobile) {
        dispatch(
          snackbarInfo({ open: true, type: 'warning', message: 'Please enter Mobile Number' })
        );
      } else if (!otp) {
        dispatch(snackbarInfo({ open: true, type: 'warning', message: 'Please enter OTP' }));
      } else if (!password) {
        dispatch(snackbarInfo({ open: true, type: 'warning', message: 'Please enter Password' }));
      }
    }
  };

  const snackbarHandler = ({ type, message }) => {
    dispatch(snackbarInfo({ open: true, type, message }));
    setClicked(false);
  };

  const signInBtnHandler = () => {
    if (step === 'verify') {
      checkProfile();
    } else if (step === 'login') {
      if (isForgotPassword) forgotPasswordHandler();
      else loginHandler();
    } else if (step === 'register') {
      registerHandler();
    }
  };

  const setDataHandler = (key, value) => {
    if (key === 'state') {
      setSelectedState(value);
    } else if (key === 'city') {
      setSelectedCity(value);
    } else if (key === 'area') {
      setSelectedArea(value);
    }
  };

  const locationApi = (isApi, userDetails) => {
    if (_.isEmpty(location?.state)) {
      dispatch(updateLocation({ state: selectedState, city: selectedCity, area: selectedArea }));
      if (isApi) {
        let data = { state: selectedState?._id, city: selectedCity?._id, area: selectedArea?._id };
        profileService.profileUpdate(data, userDetails.id, userDetails.profile).catch((e) => {});
      }
    }
  };

  return (
    <MynyfyModal open={open} close={close}>
      <div>
        {_.isEmpty(sellerDetails) ? null : (
          <MynyfyText
            title={sellerDetails?.storename}
            h3
            center
            style={{ paddingTop: 5, paddingBottom: 50 }}
          />
        )}

        <Card
          className='container'
          style={{
            position: 'relative',
            overflow: 'visible',
            paddingTop: _.isEmpty(sellerDetails) ? 5 : 30,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 25,
          }}>
          {_.isEmpty(sellerDetails) ? null : (
            <div
              style={{
                position: 'absolute',
                top: '-35px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}>
              <img
                src={s3ToCloudFront(sellerDetails?.image)}
                alt='logo'
                style={{ borderRadius: '50%', width: 70, height: 70, objectFit: 'cover' }}
              />
            </div>
          )}
          <div style={{ marginTop: 20, paddingBottom: 15 }}>
            <MynyfyText
              title={'Provide Mobile Number'}
              center
              bold
              style={{ padding: '0px 5px 10px' }}
            />
            <MynyfyInput
              fullWidth={true}
              placeHolder={'Phone Number*'}
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              helperText={error?.mobile}
              error={error?.mobile ? true : false}
              disabled={isOtpSent}
            />

            {isOtpSent ? (
              <MynyfyInput
                fullWidth={true}
                placeHolder={'OTP*'}
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                helperText={error?.otp}
                error={error?.otp ? true : false}
              />
            ) : null}

            {isProfile ? (
              <Fragment>
                <MynyfyInput
                  fullWidth={true}
                  placeHolder={'Password*'}
                  type={'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText={error?.password}
                  error={error?.password ? true : false}
                />
                {isForgotPassword ? null : (
                  <MynyfyText
                    title={'Forgot Password?'}
                    link
                    center
                    style={{ margin: 15 }}
                    onClick={() => sendOtpForForgotPassword()}
                  />
                )}
              </Fragment>
            ) : null}

            {isProfile === false ? (
              <Fragment>
                <MynyfyInput
                  fullWidth={true}
                  placeHolder={'First Name / Last Name*'}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  helperText={error?.firstName}
                  error={error?.firstName ? true : false}
                />
                <MynyfyInput
                  fullWidth={true}
                  placeHolder={'Email (Optional)'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  helperText={error?.email}
                  error={error?.email ? true : false}
                />
              </Fragment>
            ) : null}

            {isOtpSent ? (
              <Fragment>
                {isForgotPassword ? null : isSetPassword ? (
                  <MynyfyInput
                    fullWidth={true}
                    placeHolder={'Set Password*'}
                    type={'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    helperText={error?.password}
                    error={error?.password ? true : false}
                  />
                ) : (
                  <div style={{ padding: 7 }}>
                    <MynyfySpan title={'Password will be your Mobile Number.'} pSmall />
                    &nbsp;
                    <MynyfySpan
                      title={'Click Here'}
                      link
                      pSmall
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => setIsSetPassword(true)}
                    />
                    &nbsp;
                    <MynyfySpan title={'to change'} pSmall />
                  </div>
                )}
                {isForgotPassword ? null : (
                  <div style={{ padding: '0px 7px 10px' }}>
                    <MynyfySpan title={'You are agreeing to '} pSmall />
                    <MynyfySpan
                      title={'Terms & Conditions and Data Privacy Policies'}
                      link
                      onClick={() => {
                        navigate('/Policies/Terms&Conditions', { state: 'Terms & Conditions' });
                      }}
                      pSmall
                    />
                    <MynyfySpan title={' of Mynyfy Platform'} pSmall />
                  </div>
                )}
              </Fragment>
            ) : null}

            {/* {_.isEmpty(location?.state) && (isProfile || isProfile == false) ? (
              <div style={{ marginTop: 20 }}>
                <MynyfyText title={'Set your location'} bold pBig style={{ margin: '0px 5px' }} />
                <LocationFields error={error} sendData={setDataHandler} />
              </div>
            ) : null} */}

            <MynyfyButton
              label={
                isProfile
                  ? isForgotPassword
                    ? 'Change Password'
                    : 'Sign In'
                  : isOtpSent
                  ? 'Verify'
                  : 'Get OTP'
              }
              center
              disabled={isClicked}
              onClick={() => signInBtnHandler()}
            />

            {isGuest ? (
              <div>
                <MynyfyText title={'OR'} center style={{ padding: 10 }} />
                <MynyfyText
                  title={'Continue as Guest'}
                  link
                  bold
                  center
                  h6
                  style={{ marginBottom: 10 }}
                  onClick={() => guestLoginForDineIn()}
                />
              </div>
            ) : null}
          </div>
        </Card>
        <div style={{ marginTop: 20, padding: 10 }}>
          <div
            style={{ gap: 3, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <MynyfySpan title={'Powered by'} style={{ marginBottom: -2, fontSize: 10 }} />
            <img
              src={require('../../assets/imgs/LandingPage/mynyfy.png')}
              alt='Mynyfy'
              style={{ width: 50 }}
            />
          </div>

          {/* <MynyfyText title={'A CNDC Platform'} style={{ fontSize: 10 }} /> */}
        </div>
      </div>
    </MynyfyModal>
  );
};

export default SignInModal;
