import axios from 'axios';
import _ from 'lodash';

const baseConfigV1 = {
  baseURL: `${process.env.REACT_APP_API_HOST}/api/v1/`,
  timeout: 150000,
};

const baseConfigPub = {
  baseURL: `${process.env.REACT_APP_API_HOST}/api/p1/`,
  timeout: 150000,
};

const mynyfyApiInstance = axios.create({ ...baseConfigPub });
const mynyfyApiAuthInstance = axios.create({ ...baseConfigV1 });

const defaultErrorInterceptor = (error) => {
  return Promise.reject(error);
};

mynyfyApiAuthInstance.interceptors.request.use((request) => {
  return request;
});

mynyfyApiAuthInstance.interceptors.response.use((response) => {
  return response;
});

mynyfyApiInstance.interceptors.response.use(null, defaultErrorInterceptor);
mynyfyApiAuthInstance.interceptors.response.use(null, defaultErrorInterceptor);

function setAuthToken(token) {
  const headersV1 = _.cloneDeep(mynyfyApiAuthInstance.defaults.headers);

  if (!token) {
    delete headersV1.common['Authorization'];
    headersV1.common['Profile'] = process.env.REACT_APP_PROFILE;
  } else {
    headersV1.common['Authorization'] = `Bearer ${token}`;
    headersV1.common['Profile'] = process.env.REACT_APP_PROFILE;
  }
  mynyfyApiAuthInstance.defaults.headers = headersV1;
}

const headersP1 = _.cloneDeep(mynyfyApiInstance.defaults.headers);
headersP1.common['Profile'] = process.env.REACT_APP_PROFILE;
mynyfyApiInstance.defaults.headers = headersP1;

export { mynyfyApiInstance as myPubApi, mynyfyApiAuthInstance as myApi, setAuthToken };
