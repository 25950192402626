/* eslint-disable no-loop-func */
import React, { Fragment } from 'react';
import Resizer from 'react-image-file-resizer';
import { Button, Icon } from '@mui/material';
import MynyfyText from './MynyfyText';

export default class MynyfyUploadImg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    // get the files
    let files = e.target.files;

    let tagName = e.target.name;
    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        Resizer.imageFileResizer(
          file,
          2250,
          1500,
          'JPEG',
          80,
          0,
          (uri) => {
            // Make a fileInfo Object
            let fileInfo = uri;
            fileInfo.name = file.name;

            allFiles.push(fileInfo);

            let single = {
              size: Math.round(file.size / 1000),
              tagName: tagName,
              imgFile: allFiles[0],
            };
            let multiple = [];
            for (let i = 0; i < allFiles.length; i++) {
              const element = allFiles[i];
              multiple.push({
                tagName: tagName,
                imgFile: element,
              });
            }
            // If all files have been proceed
            if (allFiles.length === files.length) {
              // Apply Callback function
              if (this.props.multiple) this.props.onChange(multiple);
              else this.props.onChange(single);
            }
          },
          'blob'
        );
      }; // reader.onload
    } // for
  };

  render() {
    return (
      <div style={{ margin: this.props.containerMargin }}>
        <input
          style={{ display: 'none' }}
          id={this.props.id || this.props.name}
          type='file'
          accept={this.props.accept ? 'image/*' : false}
          onChange={this.handleChange}
          multiple={this.props.multiple}
          name={this.props.name}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <label
          id='MyUploadImg'
          htmlFor={this.props.id || this.props.name}
          style={{
            textAlign: 'center',
            fontSize: this.props.size,
            width: this.props.totalWidth,
          }}>
          {this.props.type === 'button' ? (
            <div>
              <Button
                color={this.props.color || 'primary'}
                style={{
                  width: this.props.width || '-webkit-fill-available',
                  marginTop: this.props.marginTop,
                  boxShadow: 'none',
                  borderRadius: 8,
                  padding: 9,
                }}
                variant='contained'
                component='span'>
                <p className='MyUploadImgBtn'>{this.props.label}</p>
              </Button>
            </div>
          ) : null}
          {this.props.type === 'text' ? (
            <div className={this.props.className}>
              <MynyfyText
                title={this.props.label}
                link
                color={this.props.color}
                style={this.props.textStyles}
                className='MyUploadImgLabel'
                bold={this.props.bold}
              />
            </div>
          ) : null}
          {this.props.type === 'icon' ? (
            <Fragment>
              <Icon
                style={{
                  cursor: 'pointer',
                  color: this.props.iconColor || '#1d89cc',
                  marginTop: this.props.marginTop,
                }}>
                {this.props.icon}
              </Icon>
              <p
                className='MyUploadImgLabel'
                style={{
                  color: this.props.labelColor || '#1d89cc',
                  marginTop: this.props.labelMarginTop || '-5px',
                }}>
                {this.props.label}
              </p>
            </Fragment>
          ) : null}
        </label>
      </div>
    );
  }
}

MynyfyUploadImg.defaultProps = {
  multiple: false,
};
