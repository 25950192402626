import React, { Fragment, useEffect } from 'react';
import AppStack from './AppStack';
import CommonStack from './CommonStack';
import AuthStack from './AuthStack';
import MynyfySnackbar from '../components/MynyfySnackbar';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../redux/actions/snackbar.action';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import EditProfile from '../screens/Profile/EditProfile';
import {
  INITIAL_RELOAD_WATCHER,
  RELOAD_WATCHER,
  RELOAD_WITH_LOC_WATCHER,
} from '../redux/actionTypes';
import { fetchAppState } from '../redux/actions/appState.action';
import { messaging, requestForToken } from '../utils/firebase';
import { userDeviceId } from '../redux/actions/user.action';
import { onMessage } from 'firebase/messaging';
import { getOS } from '../utils/CommonMethods';
import WebAppStack from './WebAppStack';
import WebCommonStack from './WebCommonStack';

let platorm = getOS();
let isMobile = platorm == 'android' || platorm == 'ios' ? true : false;

const Routes = () => {
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const profileDetails = user?.user;

  return (
    <Fragment>
      {isLoggedIn ? (
        profileDetails?.firstName ? (
          process.env.REACT_APP_ENV == 'development' ? (
            isMobile ? (
              AppStack()
            ) : (
              WebAppStack()
            )
          ) : (
            AppStack()
          )
        ) : (
          <Route path='/' element={<EditProfile />} />
        )
      ) : (
        <Fragment>{AuthStack()}</Fragment>
      )}
      {process.env.REACT_APP_ENV == 'development'
        ? isMobile
          ? CommonStack()
          : WebCommonStack()
        : CommonStack()}
    </Fragment>
  );
};

export const MynyfyRoutes = () => {
  const dispatch = useDispatch();
  const snackbarKeys = useSelector((state) => state.snackbar);
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;

  useEffect(() => {
    dispatch({ type: INITIAL_RELOAD_WATCHER });
    dispatch({ type: RELOAD_WITH_LOC_WATCHER });
    if (isLoggedIn) {
      dispatch({ type: RELOAD_WATCHER });

      if (!('Notification' in window)) {
        console.log('Browser does not support desktop notification');
      } else if (Notification.permission === 'granted') {
        notificationHandler();
      } else {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') notificationHandler();
        });
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    dispatch(fetchAppState(!document.hidden));
  };

  const notificationHandler = async () => {
    await requestForToken({ dispatch, successAction: userDeviceId });

    onMessage(messaging, (payload) => {
      if (payload) {
        new Notification(payload?.notification?.title, { body: payload?.notification?.body });
      }
    });
  };

  let router = createBrowserRouter(createRoutesFromElements(Routes()));

  return (
    <div
      id={
        process.env.REACT_APP_ENV == 'development'
          ? isMobile
            ? 'appContainer'
            : 'webAppContainer'
          : 'appContainer'
      }>
      <MynyfySnackbar
        open={snackbarKeys.open}
        vertical={snackbarKeys.vertical}
        horizontal={snackbarKeys.horizontal}
        direction={snackbarKeys.direction}
        type={snackbarKeys.type}
        message={snackbarKeys.message}
        transition={snackbarKeys.transition}
        handleClose={() => dispatch(snackbarInfo({ open: false }))}
      />
      <RouterProvider router={router} />
    </div>
  );
};
