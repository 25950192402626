import React from 'react';
import theme from '../utils/theme';

const MynyfySpan = ({
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pSmall,
  pBig,
  small,
  bold,
  semiBold,
  italic,
  boldItalic,
  semiBoldItalic,
  light,
  primary,
  error,
  title,
  style,
  center,
  color,
  link,
  onClick,
  ...rest
}) => (
  <span
    style={{
      ...{ fontFamily: theme.DEFAULT_FONT, color: theme.DARK_COLOR, fontSize: 14 },
      ...(h1 && { fontSize: 50 }),
      ...(h2 && { fontSize: 34 }),
      ...(h3 && { fontSize: 22 }),
      ...(h4 && { fontSize: 20 }),
      ...(h5 && { fontSize: 18 }),
      ...(h6 && { fontSize: 16 }),
      ...(pBig && { fontSize: 15 }),
      ...(p && { fontSize: 14 }),
      ...(pSmall && { fontSize: 13 }),
      ...(small && { fontSize: 11 }),
      ...style,
      ...(link && { color: theme.PRIMARY_BLUE, cursor: 'pointer' }),
      ...(center && { textAlign: 'center' }),
      ...(bold && { fontFamily: theme.BOLD_FONT }),
      ...(semiBold && { fontFamily: theme.SEMI_BOLD_FONT }),
      ...(italic && { fontFamily: theme.ITALIC_FONT }),
      ...(boldItalic && { fontFamily: theme.BOLD_ITALIC_FONT }),
      ...(semiBoldItalic && { fontFamily: theme.SEMI_BOLD_ITALIC_FONT }),
      ...(light && { color: theme.MUTED_BLUE }),
      ...(primary && { color: theme.PRIMARY_BLUE }),
      ...(error && { color: theme.ERROR }),
      ...(color && { color: color }),
    }}
    onClick={onClick}
    {...rest}>
    {title}
  </span>
);

export default MynyfySpan;
