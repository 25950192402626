import { IS_FLASH_AD_SEEN } from '../actionTypes';

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_FLASH_AD_SEEN:
      return action.payload;
    default:
      return state;
  }
};
