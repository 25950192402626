import { myApi } from '../utils/apiHelper';

class TransactionService {
	async getTransactions(id, params) {
		try {
			return await myApi.get(`/getBuyerTxns/${id}`, { params }).then((res) => {
				return res.data;
			});
		} catch (err) {
			return err.response.data;
		}
	}

	async getBuyerPaymentTxns(id, params) {
		try {
			return await myApi.get(`/buyerPaymentTxns/${id}`, { params }).then((res) => {
				return res.data;
			});
		} catch (err) {
			return err.response.data;
		}
	}

	async getPoints() {
		try {
			return await myApi.get(`/buyer/points`).then((res) => {
				return res.data;
			});
		} catch (err) {
			return err.response.data;
		}
	}
}

const transactionService = new TransactionService();
export default transactionService;
export { transactionService };
