import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MynyfyLoader from '../../../components/MynfyfLoader';
import MynyfyButton from '../../../components/MynyfyButton';
import MynyfyText from '../../../components/MynyfyText';
import _ from 'lodash';
import {
  CallRounded,
  CancelRounded,
  CheckCircleRounded,
  CircleRounded,
  ExpandMoreRounded,
} from '@mui/icons-material';
import storeService from '../../../services/storeService';
import promoService from '../../../services/promoService';
import commonService from '../../../services/commonService';
import paymentService from '../../../services/paymentService';
import { ReactComponent as ClaimIcon } from '../../../assets/svg/claimed.svg';
import { fetchPaymentInitialization } from '../../../redux/actions/paymentInitialized.action';
import { fetchIsNewOrder } from '../../../redux/actions/newOrder.action';
import { snackbarInfo } from '../../../redux/actions/snackbar.action';
import { OrderType, PaymentGateways, PaymentMode, TransactionType } from '../../../utils/enums';
import ReferComponent from '../../../components/ReferComponent';
import SelectSeller from '../../../components/SelectSeller';
import SelectAddress from '../../../components/SelectAddress';
import TrackingDetails from '../../../components/TrackingDetails';
import SelectPaymentMode from '../../../components/SelectPaymentMode';
import { RazorPayOptions } from '../../../payments/RazorPay';
import { PhonePeGateway } from '../../../payments/PhonePe';
import { useLocation, useNavigate, useParams } from 'react-router';
import transactionService from '../../../services/transactionService';
import { fetchMynts } from '../../../redux/actions/mynts.action';
import MynyfySpan from '../../../components/MynyfySpan';
import theme from '../../../utils/theme';
import '../promos.scss';
import MynyfyHeader from '../../../components/MynyfyHeader';
import MynyfyAlert from '../../../components/MynyfyAlert';
import {
  merchantTxnId,
  objectReplace,
  s3ToCloudFront,
  sabpaisaPaymentGateway,
} from '../../../utils/CommonMethods';
import { fetchDayDeals, fetchSortedDayDeals } from '../../../redux/actions/promo.action';
import SignInModal from '../../Auth/SignInModal';
import { AES } from 'crypto-js';
import ShowSellerQr from '../../../components/ShowSellerQr';
import { fileUploadToS3WD } from '../../../utils/S3Uploader';
import productOrderService from '../../../services/productOrderService';
import sellerCustomerService from '../../../services/sellerCustomerService';

const DayDealDetails = () => {
  const isUnmounting = useRef(false);
  const paymentDetailsRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  const urlParams = useParams();

  const user = useSelector((state) => state.user);
  const mynts = useSelector((state) => state.mynts);
  const id = user?.user?.id || null;
  let profile = user?.profile?.profile;
  const isLoggedIn = user?.isLoggedIn;
  const appState = useSelector((state) => state.appState);
  const isPaymentInitialized = useSelector((state) => state.isPaymentInitialized);
  const dayDeals = useSelector((state) => state.promos?.dayDeals);

  const [isTerms, setIsTerms] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [address, setAddress] = useState(null);
  const [details, setDetails] = useState(null);
  const [isRefer, setIsRefer] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [shippingPrice, setShippingPrice] = useState(null);
  const [openPayModeModal, setOpenPayModeModal] = useState(false);
  const [selectedPaymentMode, setPaymentMode] = useState(false);
  const [availablePayModes, setAvailablePayModes] = useState([]);
  const [openSellersList, setOpenSellersList] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [txnDetails, setTxnDetails] = useState(null);
  const [isMyntsAlert, setIsMyntsAlert] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [productOrderId, setProductOrderId] = useState(null);
  const [openSellerUpiModal, setOpenSellerUpiModal] = useState(false);

  useEffect(() => {
    let promoId = urlParams?.id;
    if (route?.state?.details) {
      setDetails(route?.state?.details);
      initialCalls(route?.state?.details);
    } else if (promoId) {
      promoService.getPromoById(promoId).then((res) => {
        if (res.success) {
          setDetails(res.data);
          initialCalls(res.data);
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      });
    }
    if (route?.state?.details?.claimedBy?.includes(id)) {
      setSelectedAddress(route?.state?.details?.purchasedUsers?.[0]?.address);
      if (route?.state?.details?.purchasedUsers?.[0]?.address) {
        getAreaForShippingPrice(route?.state?.details?.purchasedUsers?.[0]?.address?.locality);
      }
    }
  }, []);

  const initialCalls = (promoDetails) => {
    getPaymentModes(promoDetails?.organization);
    if (promoDetails?.organization) {
      addressCall(promoDetails?.organization._id);
    }
  };

  // Sabpaisa callback
  // useEffect(() => {
  //   const messageHandler = (event) => {
  //     if (paymentDetailsRef.current?.isInitiated && paymentDetailsRef.current?.txnId) {
  //       if (
  //         event.data &&
  //         typeof event.data === 'string' &&
  //         !event.data?.includes('webpackHotUpdate')
  //       ) {
  //         let response = JSON.parse(event.data);
  //         if (
  //           response &&
  //           response?.clientTxnId === paymentDetailsRef.current?.txnId &&
  //           response.userId === id
  //         ) {
  //           if (response.status === 'SUCCESS' || response.statusCode === '0000') {
  //             orderConfirmation({
  //               txnId: response.txnId,
  //               extraData: {
  //                 cartId: paymentDetailsRef.current?.cartId,
  //                 payMode: paymentDetailsRef.current?.payMode,
  //                 promoData: paymentDetailsRef.current?.promoData,
  //               },
  //             });
  //           } else {
  //             dispatch(
  //               snackbarInfo({
  //                 open: true,
  //                 type: 'error',
  //                 message: [
  //                   'Sorry!, your transaction is ' + response.status.toLowerCase(),
  //                   'If any amount is debited please contact support',
  //                 ],
  //               })
  //             );
  //             setIsClicked(false);
  //           }
  //         } else if (response?.clientTxnId && paymentDetailsRef.current?.txnId) {
  //           paymentDetailsRef.current = null;
  //           dispatch(
  //             snackbarInfo({
  //               open: true,
  //               type: 'error',
  //               message: [
  //                 'Sorry!, something went wrong',
  //                 'If any amount is debited please contact support',
  //               ],
  //             })
  //           );
  //           setIsClicked(false);
  //         }
  //       } else {
  //         paymentDetailsRef.current = null;
  //         setIsClicked(false);
  //       }
  //     } else {
  //       paymentDetailsRef.current = null;
  //       setIsClicked(false);
  //     }
  //   };
  //   window.addEventListener('message', messageHandler);
  //   return () => {
  //     window.removeEventListener('message', messageHandler);
  //   };
  // }, []);

  useEffect(() => {
    if (appState && isPaymentInitialized && txnDetails?.txnId) {
      console.log('called in Deal payment');
      phonePePaymentCheck();
    }
  }, [appState]);

  useEffect(() => {
    return () => {
      if (isUnmounting.current) {
        deleteProductId();
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  const addressCall = (orgId) => {
    storeService.getStoreAddress(orgId).then((res) => {
      if (res.success) {
        setAddress(res.data);
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const addressHandler = (key) => {
    return key ? `${key}, ` : null;
  };

  const getPoints = () => {
    transactionService.getPoints().then((res) => {
      if (res?.data?.points) {
        dispatch(fetchMynts(res?.data?.points));
      }
    });
  };

  const orderValidation = () => {
    if (isLoggedIn) {
      let isPoints = mynts >= details.points;
      if (isPoints) {
        if (!selectedAddress) {
          dispatch(
            snackbarInfo({
              type: 'warning',
              open: true,
              message: 'Please select delivery address',
            })
          );
          setOpenAddressModal(true);
        } else if (!selectedSeller) {
          if (details.organization) {
            setOpenPayModeModal(true);
          } else {
            setOpenSellersList(true);
          }
        } else {
          setOpenPayModeModal(true);
        }
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: 'Insufficient Mynts' }));
        setIsRefer(true);
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const initiateRazorPayPayment = ({ price, cartId }) => {
    paymentService
      .initiateRazorPayPayment({ amount: Number(price), type: TransactionType.PROMO })
      .then((res) => {
        if (res.success) {
          razorPayPaymentHandler({
            amount: res.data.amount,
            orderId: res.data.orderId,
            cartId,
            payMode: PaymentMode.ONLINE,
          });
        } else {
          setIsClicked(false);
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      })
      .catch((e) => {
        console.log(e);
        setIsClicked(false);
      });
  };

  const razorPayPaymentHandler = ({ amount, orderId, cartId, payMode }) => {
    let options = RazorPayOptions({
      amount,
      orderId,
      description: 'Purchasing Deal value (includes GST)',
      user: user?.user,
    });
    options.handler = (res) => {
      res.type = PaymentGateways.RAZOR_PAY;
      if (res.razorpay_order_id && res.razorpay_payment_id && res.razorpay_signature) {
        orderConfirmation({ paymentDetails: res, extraData: { cartId, payMode } });
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: 'Payment Failed' }));
      }
    };
    options.modal = { ondismiss: () => setIsClicked(false) };

    const rzpay = new window.Razorpay(options);
    rzpay.open();
  };

  const initiatePhonePePayment = ({ price, cartId }) => {
    PhonePeGateway.phonePeUPIPaymentObj({
      userId: id,
      amount: price,
      mobileNumber: profile?.mobile,
      type: TransactionType.PROMO,
    }).then((res) => {
      if (res.success) {
        PhonePeGateway.initiatePayment(res.paymentObj)
          .then((res) => {
            if (res.success) {
              setTxnDetails({
                txnId: res.txnId,
                extraData: { cartId, payMode: PaymentMode.ONLINE },
              });
              dispatch(fetchPaymentInitialization(true));
              window.open(res.txnLink);
            }
          })
          .catch((e) => console.log(e))
          .finally(() => setIsClicked(false));
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const phonePePaymentCheck = () => {
    PhonePeGateway.transactionStatusCheck({
      txnId: txnDetails?.txnId,
      submitHandler: orderConfirmation,
      extraData: txnDetails.extraData,
      clickHandler: (loading) => {
        setIsClicked(loading);
        let clonedDetails = _.clone(txnDetails);
        clonedDetails.txnId = null;
        setTxnDetails(clonedDetails);
        dispatch(fetchPaymentInitialization(false));
      },
    });
  };

  const initiateSabpaisaPayment = ({ price, cartId }) => {
    setIsClicked(true);
    let myTxnId = merchantTxnId({
      userId: id,
      mobile: profile?.mobile,
      gateway: PaymentGateways.SAB_PAISA,
    });
    let body = {
      payerName: profile.firstName,
      payerEmail: profile.email,
      payerMobile: profile.mobile,
      clientTxnId: myTxnId,
      amount: price,
      udf1: 'Deal of the day Order',
      udf2: details.name,
      udf3: 'browser',
      udf4: 'Promo',
      udf5: cartId,
      udf6: id,
      udf7: 'BuyerProfile',
    };
    let encData = AES.encrypt(JSON.stringify(body), process.env.REACT_APP_PAYMENT_PAYLOAD_KEY);
    paymentDetailsRef.current = {
      isInitiated: true,
      txnId: myTxnId,
      payMode: PaymentMode.ONLINE,
      cartId,
      promoData: details,
    };
    sabpaisaPaymentGateway({ encData, loader: setIsClicked });
  };

  const checkCustomer = async () => {
    return sellerCustomerService
      .customerCheck({
        mobile: user?.user?.mobile,
        orgId: details?.organization?._id || selectedSeller?._id,
      })
      .then((res) => {
        if (res.success) {
          if (res.data > 0) return true;
          else return false;
        } else return true;
      })
      .catch(() => {
        return true;
      });
  };

  const submitOrderHandler = async (payMode, payImg) => {
    setIsClicked(true);
    setTxnDetails(null);
    dispatch(fetchPaymentInitialization(false));
    let body = {
      id: details._id,
      address: selectedAddress,
      paymentMode:
        payMode == PaymentMode.CASH
          ? PaymentMode.CASH
          : payMode == PaymentMode.UPI_QR
          ? PaymentMode.UPI_QR
          : PaymentMode.ONLINE,
      buyer: id,
      seller: details.organization?._id || selectedSeller?._id,
      priceDetails: {
        price: details.actualPrice,
        tax: 0,
        shippingCharges: shippingPrice || 0,
        discount: details.actualPrice - details.value,
        totalPrice: details.value + (shippingPrice || 0),
      },
      orderType: OrderType.HOME_DELIVERY,
    };
    if (details.brand) {
      body.brand = details.brand?._id;
    }
    if (details.value + (shippingPrice || 0) == 0) {
      body.paymentMode = PaymentMode.CASH;
    }

    let sellerCustomer = await checkCustomer();
    if (sellerCustomer) {
      body.isMyCustomer = true;
    } else {
      body.isMyCustomer = false;
      body.buyerProfileId = user?.user?.profile;
    }

    let s3ErrorMsg = null;
    if (payImg) {
      await fileUploadToS3WD(payImg, 'OrderPayment').then((res) => {
        if (res.status !== 403 && res.url) {
          body.paymentImg = res;
          s3ErrorMsg = null;
        } else {
          s3ErrorMsg = 'Unable to upload store image please try again';
        }
      });
    }
    if (s3ErrorMsg) {
      dispatch(snackbarInfo({ open: true, type: 'warning', message: s3ErrorMsg }));
      setIsClicked(false);
    } else {
      promoService
        .purchaseDeal(body)
        .then((res) => {
          if (res.success) {
            if (payMode == PaymentMode.CASH || payMode == PaymentMode.UPI_QR) {
              orderSuccessHandler();
            } else {
              setProductOrderId(res.data);
              let price = (details.value || details.actualPrice) + shippingPrice;
              if (payMode == PaymentMode.RAZOR_PAY) {
                initiateRazorPayPayment({ price, cartId: res.data });
              } else if (payMode == PaymentMode.UPI) {
                initiatePhonePePayment({ price, cartId: res.data });
              } else if (payMode == PaymentMode.SAB_PAISA) {
                initiateSabpaisaPayment({ price, cartId: res.data });
              }
            }
          } else {
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          }
        })
        .finally(() => setIsClicked(false));
    }
  };

  const orderConfirmation = ({ paymentDetails, extraData, txnId }) => {
    setIsClicked(true);
    setTxnDetails(null);
    dispatch(fetchPaymentInitialization(false));
    let body = { id: extraData.cartId, paymentMode: extraData.payMode };
    if (paymentDetails) body.paymentDetails = paymentDetails;
    if (txnId) body.txnId = txnId;
    productOrderService
      .confirmProductOrder(body)
      .then((res) => {
        if (res.success) {
          orderSuccessHandler(extraData.promoData);
        } else {
          if (res.message.includes('Payment Signature failed')) {
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
            dispatch(fetchIsNewOrder(true));
            navigate('/Orders', { replace: true });
          } else {
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          }
        }
      })
      .finally(() => setIsClicked(false));
  };

  const orderSuccessHandler = (promoData) => {
    updateDayDeals(details?._id || promoData?._id);
    if (details?.points || promoData?.points) {
      getPoints();
    }
    dispatch(
      snackbarInfo({
        type: 'success',
        open: true,
        message: ['Your order is placed successfully', 'Wiil be delivered soon'],
      })
    );
    dispatch(fetchIsNewOrder(true));
    navigate('/Orders', { replace: true });
  };

  const updateDayDeals = (promoId) => {
    let sortedDayDeal = dayDeals?.sorted?.find((e) => e._id == promoId);
    let actualDayDeal = dayDeals?.actual?.find((e) => e._id == promoId);
    sortedDayDeal.isRedeemed = true;
    actualDayDeal.isRedeemed = true;
    if (sortedDayDeal.claimedBy) {
      sortedDayDeal.claimedBy.push(id);
      actualDayDeal.claimedBy.push(id);
    } else {
      sortedDayDeal.claimedBy = [id];
      actualDayDeal.claimedBy = [id];
    }
    let modifiedData = objectReplace(dayDeals?.sorted, sortedDayDeal, '_id');
    let actualData = objectReplace(dayDeals?.actual, actualDayDeal, '_id');
    fetchSortedDayDeals(modifiedData);
    fetchDayDeals(actualData);
  };

  const getAreaForShippingPrice = (area) => {
    commonService.appDataByData(area).then((res) => {
      if (res.success) {
        setShippingPrice(res.data?.[0]?.deliveryPrice);
      }
    });
  };

  const selectedPaymentHandler = (e) => {
    setPaymentMode(e);
    let price = (details.value || details.actualPrice) + shippingPrice;
    if (price && selectedAddress && (shippingPrice || shippingPrice == 0)) {
      if (productOrderId) {
        if (e == PaymentMode.CASH) {
          orderConfirmation({ extraData: { cartId: productOrderId, payMode: PaymentMode.CASH } });
        } else if (e == PaymentMode.RAZOR_PAY) {
          initiateRazorPayPayment({ price, cartId: productOrderId });
        } else if (e == PaymentMode.UPI) {
          initiatePhonePePayment({ price, cartId: productOrderId });
        } else if (e == PaymentMode.UPI_QR) {
          orderConfirmation({ extraData: { cartId: productOrderId, payMode: PaymentMode.UPI_QR } });
        } else if (e == PaymentMode.SAB_PAISA) {
          initiateSabpaisaPayment({ price, cartId: productOrderId });
        }
      } else if (e == PaymentMode.UPI_QR) {
        setOpenPayModeModal(false);
        setTimeout(() => setOpenSellerUpiModal(true), 200);
      } else {
        submitOrderHandler(e);
      }
    } else {
      setIsClicked(false);
      if (!selectedAddress) {
        dispatch(
          snackbarInfo({ type: 'warning', open: true, message: 'Please select delivery address' })
        );
        setOpenAddressModal(true);
      } else if (!price) {
        dispatch(
          snackbarInfo({
            type: 'error',
            open: true,
            message: ['Sorry, Something went wrong', 'Please try after sometime'],
          })
        );
      } else if (!shippingPrice) {
        dispatch(
          snackbarInfo({
            type: 'error',
            open: true,
            message: ['Sorry, Unable to get shipping charges', 'Please try after sometime'],
          })
        );
      }
    }
  };

  const getPaymentModes = (org) => {
    let modes = [];
    if (!_.isEmpty(org)) {
      if (org.online) {
        modes.push(PaymentMode.ONLINE);
      }
      if (org.cash) {
        modes.push(PaymentMode.CASH);
      }
    } else {
      modes.push(PaymentMode.CASH);
    }
    setAvailablePayModes(modes);
  };

  const deleteProductId = () => {
    if (productOrderId) {
      productOrderService.deleteProductOrder(productOrderId).then((res) => console.log(res));
      setProductOrderId(null);
    }
  };

  return details ? (
    <Fragment>
      <div id='Promos' style={{ backgroundColor: '#f0f0f0' }}>
        <MynyfyHeader title={'Details'} />
        <div className='container'>
          <div style={{ position: 'relative' }}>
            <img src={s3ToCloudFront(details.image.url)} className='detailsImg' />
            {details.claimedBy?.includes(id) ? <ClaimIcon className='claimIcon' /> : null}
          </div>
          <div style={{ padding: '10px 0px' }}>
            <MynyfySpan
              title={theme.RUPEE + details.actualPrice}
              className='name'
              bold={details.value ? false : true}
              style={{ textDecoration: details.value ? 'line-through' : 'unset' }}
            />
            &nbsp;
            {details.value ? (
              <MynyfySpan title={theme.RUPEE + details.value} className='name' bold />
            ) : null}
          </div>
          <div className='rowSB'>
            <MynyfyText title={details.name} className='name' bold />
            {details.quantity ? (
              <MynyfyText
                title={`QTY: ${details.quantity} ${details.units || ''}`}
                className='qty'
              />
            ) : null}
          </div>
          <MynyfyText title={details.description} style={{ paddingTop: 10 }} />
        </div>

        {details.brand ? (
          <Fragment>
            <div
              className='container'
              style={{ padding: '13px 20px' }}
              onClick={() => navigate('/Brands/' + details.brand?.urlId)}>
              <MynyfyText title={'Sold & Delivered By'} bold style={{ marginBottom: 10 }} />
              <div className='row'>
                {details.brand?.logo ? (
                  <img src={s3ToCloudFront(details.brand?.logo?.url)} className='logo' />
                ) : null}
                <MynyfyText title={details.brand?.brand} bold link style={{ margin: '0px 10px' }} />
                <MynyfyText title={'Views Page'} link bold small className='brandBtn' />
              </div>
            </div>
          </Fragment>
        ) : null}

        <div className='container'>
          {details.isRedeemed ? (
            <div className='rowSB'>
              <MynyfyText title={'You have Bought'} p bold style={{ color: 'green' }} />
              <CheckCircleRounded fontSize='' color='success' style={{ marginRight: -1 }} />
            </div>
          ) : details.days <= 0 ? (
            <div className='rowSB'>
              <MynyfyText title={'Expired'} p bold style={{ color: 'red' }} />
              <CancelRounded fontSize='' color='error' />
            </div>
          ) : details.status == 'COMPLETED' ? (
            <div className='rowSB'>
              <MynyfyText title={'You Missed it'} p bold style={{ color: 'red' }} />
              <CancelRounded fontSize='' color='error' />
            </div>
          ) : details.days > 0 && details.count - (details.claims || 0) > 0 ? (
            <div className='rowSB'>
              <MynyfyText title={'Expires in'} bold p />
              <MynyfyText title={`${details.days} days`} p style={{ color: 'red' }} />
            </div>
          ) : (
            <div className='rowSB'>
              <MynyfyText title={'You Missed it'} p bold style={{ color: 'red' }} />
              <CancelRounded fontSize='' color='error' />
            </div>
          )}

          {details.points ? (
            <div className='rowSB' style={{ marginTop: 10 }}>
              <MynyfyText title={details.isRedeemed ? 'Mynts Spent' : 'Mynts required'} bold p />
              <MynyfyText title={details.points} p />
            </div>
          ) : null}

          {details.actualPrice ? (
            <>
              <div className='dashedLine' />
              <div className='rowSB' style={{ marginBottom: 10 }}>
                <div>
                  <MynyfySpan title={'Actual Price'} bold />
                  <MynyfySpan title={' (Including GST)'} small />
                </div>
                <MynyfyText title={`${theme.RUPEE}${details.actualPrice}`} />
              </div>
              {details.value ? (
                <div className='rowSB' style={{ marginBottom: 10 }}>
                  <MynyfyText title={'Saving'} bold />
                  <MynyfyText
                    title={`${theme.RUPEE}${details.actualPrice - details.value}`}
                    style={{ color: 'green' }}
                  />
                </div>
              ) : null}
            </>
          ) : null}

          <div className='rowSB'>
            <div>
              <MynyfyText title={'Shipping Charges'} bold p />
              {shippingPrice || shippingPrice == 0 ? null : (
                <MynyfyText
                  title={'(select delivery address)'}
                  style={{ fontSize: 10, marginTop: 2 }}
                />
              )}
            </div>
            <MynyfyText
              title={
                shippingPrice || shippingPrice == 0 ? `${theme.RUPEE}${shippingPrice}` : '----'
              }
            />
          </div>

          <div style={{ marginTop: 3 }}>
            <MynyfyText title={'(Order will be shipped in 1 to 15 working days)'} small />
          </div>

          <div className='dashedLine' />
          <div className='rowSB'>
            <MynyfyText title={'To pay'} bold />
            <MynyfyText
              title={`${theme.RUPEE}${
                (details.value || details.actualPrice) + (shippingPrice || 0)
              }`}
              style={{ color: 'orangered' }}
            />
          </div>
          <div style={{ marginTop: 3 }}>
            <MynyfyText title={'(No Return & Exchange for Deals)'} small />
          </div>
        </div>

        {details.organization ? (
          <div className='container'>
            <div className='rowSB'>
              <MynyfyText title={'Sold & Delivered By'} bold />
              {/* <div
                onClick={() => {
                  if (details.organization.contactNo) {
                    window.open('tel:' + details.organization.contactNo);
                  } else {
                    window.open('tel:' + details.organization.ownerId.profile.mobile);
                  }
                }}>
                <CallRounded color='primary' style={{ padding: '0px 10px', margin: '0px 10px' }} />
              </div> */}
            </div>
            <MynyfyText
              title={details.organization.name}
              style={{ margin: '10px 0px', color: theme.DARK_BLUE }}
              bold
            />
            {address ? (
              <div>
                <MynyfySpan title={addressHandler(address.lane1)} />
                <MynyfySpan title={addressHandler(address.lane2)} />
                <MynyfySpan title={addressHandler(address.locality)} />
                <MynyfySpan title={addressHandler(address.landMark)} />
                <MynyfySpan title={addressHandler(address.city)} />
                <MynyfySpan title={addressHandler(address.state)} />
                <MynyfySpan title={address.pincode} />
              </div>
            ) : null}
          </div>
        ) : null}

        {details.terms ? (
          <Fragment>
            <div className='container cursor' onClick={() => setIsTerms(!isTerms)}>
              <div className='rowSB'>
                <MynyfyText title={'Terms and Conditions'} bold />
                <ExpandMoreRounded fontSize='small' />
              </div>
            </div>

            {isTerms ? (
              <div className='container' style={{ marginTop: -20, paddingTop: 0 }}>
                {details.terms.split('$').map((res, i) => (
                  <div className='row' style={{ marginTop: 10 }} key={i}>
                    <CircleRounded className='dotIcon' />
                    <MynyfyText
                      title={res}
                      style={{ marginTop: 3, flexWrap: 'wrap', flexShrink: 1 }}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </Fragment>
        ) : null}

        {details.status == 'COMPLETED' ||
        details.count <= (details.claims || 0) ||
        details.claimedBy?.includes(id) ||
        details.days <= 0 ? null : (
          <Fragment>
            <div
              className='container link'
              onClick={() => {
                if (isLoggedIn) {
                  if (details.claimedBy?.includes(id)) {
                    // it is disabled
                  } else {
                    setOpenAddressModal(true);
                  }
                } else {
                  setOpenSignIn(true);
                }
              }}>
              <div className='rowSB'>
                <MynyfyText
                  title={selectedAddress ? 'Delivery Address' : 'Select Delivery Address'}
                  bold
                />
                <ExpandMoreRounded fontSize='small' style={{ color: '#000' }} />
              </div>
            </div>
            {selectedAddress ? (
              <div className='container' style={{ marginTop: -15, paddingTop: 0 }}>
                <MynyfySpan title={addressHandler(selectedAddress.lane1)} />
                <MynyfySpan title={addressHandler(selectedAddress.lane2)} />
                <MynyfySpan title={addressHandler(selectedAddress.locality)} />
                <MynyfySpan title={addressHandler(selectedAddress.landMark)} />
                <MynyfySpan title={addressHandler(selectedAddress.city)} />
                <MynyfySpan title={addressHandler(selectedAddress.state)} />
                <MynyfySpan title={selectedAddress.pincode} />
              </div>
            ) : null}
          </Fragment>
        )}

        {details.claimedBy?.includes(id) && !_.isEmpty(details.purchasedUsers) ? (
          <Fragment>
            <div className='container cursor' onClick={() => setIsTracking(!isTracking)}>
              <div className='rowSB'>
                <MynyfyText title={'Tracking Details'} bold p />

                <ExpandMoreRounded fontSize='small' />
              </div>
            </div>
            {isTracking ? (
              <div className='container' style={{ marginTop: -15, paddingTop: 0 }}>
                {_.isEmpty(details.purchasedUsers) || !details.purchasedUsers?.[0]?.trackingId ? (
                  <MynyfyText title={'Tracking details will be shared soon'} />
                ) : (
                  <TrackingDetails deatils={details.purchasedUsers[0]} />
                )}
              </div>
            ) : null}
          </Fragment>
        ) : null}

        {isRefer ? <ReferComponent /> : null}

        <div style={{ padding: 10, textAlign: 'center' }}>
          <MynyfyButton
            label={details.claimedBy?.includes(id) ? 'Go to Orders' : 'Buy Now'}
            className='statusBtn'
            onClick={() => {
              if (details.claimedBy?.includes(id)) {
                navigate('/Orders');
              } else {
                orderValidation();
              }
            }}
            loading={isClicked}
            disabled={
              details.isRedeemed
                ? false
                : isClicked ||
                  details.status == 'COMPLETED' ||
                  details.count <= (details.claims || 0) ||
                  details.days <= 0
            }
          />
        </div>
      </div>

      <SelectAddress
        isVisible={openAddressModal}
        closeModal={() => setOpenAddressModal(false)}
        selectedAddress={selectedAddress}
        isShippingPrice={true}
        from={(details.organization && 'seller') || (details.brand && 'brand')}
        fromId={details.organization?._id || details.brand?._id}
        fromName={details.organization?.name || details.brand?.brand}
        isDeal={true}
        setData={(e) => {
          setShippingPrice(e.shippingPrice);
          setSelectedAddress(e.address);
        }}
      />

      <SelectSeller
        isVisible={openSellersList}
        closeModal={() => setOpenSellersList(false)}
        brandId={details.brand?._id}
        selectedAddress={selectedAddress}
        selectedSeller={selectedSeller}
        setData={(e) => {
          setSelectedSeller(e);
          getPaymentModes(e);
          setTimeout(() => setOpenPayModeModal(true), 500);
        }}
      />

      <SelectPaymentMode
        isVisible={openPayModeModal}
        closeModal={() => setOpenPayModeModal(false)}
        selectedPaymentMode={selectedPaymentMode}
        setData={(e) => selectedPaymentHandler(e)}
        payModes={availablePayModes}
      />

      <ShowSellerQr
        isVisible={openSellerUpiModal}
        closeModal={() => setOpenSellerUpiModal(false)}
        submitOrder={(payImg) => submitOrderHandler(PaymentMode.UPI_QR, payImg)}
        upiId={route.state.upiId}
        upiQr={route.state.upiQr}
        name={route?.state?.name}
      />
      {/* 
      <MynyfyAlert
        open={isMyntsAlert}
        close={() => setIsMyntsAlert(false)}
        title={'Are you sure you want to buy the product?'}
        content={'Once Payment is done, deducted Mynts will not be refunded'}
        action1={{ label: 'No', handler: () => setIsMyntsAlert(false), color: 'black' }}
        action2={{
          label: 'Yes',
          handler: () => {
            setIsMyntsAlert(false);
          },
        }}
      /> */}

      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default DayDealDetails;
