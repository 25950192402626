import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useSelector } from 'react-redux';
import theme from '../../utils/theme';
import { Card } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import MynyfyPre from '../../components/MynyfyPre';
import PrivacyPolicy from '../Policies/PrivacyPolicy';
import LocationModal from '../Location/LocationModal';
import MynyfyAlert from '../../components/MynyfyAlert';
import { KeyboardArrowRight } from '@mui/icons-material';
import { ReactComponent as FavoritesIcon } from '../../assets/svg/favorites.svg';
import MynyfySpan from '../../components/MynyfySpan';
import DealsLayout from './DealsLayout';

const HomeLayout = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;
  const location = useSelector((state) => state.location?.location);
  const mynyfyStats = useSelector((state) => state.mynyfyStats);

  const [isLocationModal, setIsLocationModal] = useState(false);
  const [openLocationAlert, setOpenLocationAlert] = useState(false);

  const imgSize = 27;

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash.length && urlHash === '#PrivacyPolicy') {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
    // if (_.isEmpty(location)) {
    //   setOpenLocationAlert(true);
    // }
  }, []);

  return (
    <div>
      <Fragment>
        <div className='rowSB' style={{ margin: '15px 20px 0px' }}>
          {mynyfyStats?.buyers ? (
            <div>
              <MynyfySpan title={mynyfyStats?.buyers} bold />
              &nbsp;
              <MynyfySpan title={'Mynyfians!'} />
            </div>
          ) : (
            <MynyfyText title={' '} />
          )}
          <div
            className='row cursor'
            style={{ justifyContent: 'flex-end', flexWrap: 'wrap', paddingLeft: 10 }}
            onClick={() => setIsLocationModal(true)}>
            <MynyfyText
              title={_.isEmpty(location) ? 'Set Location' : 'Location:'}
              color={_.isEmpty(location) ? theme.PRIMARY_BLUE : theme.MUTED_BLUE}
              pBig
              style={{ whiteSpace: 'nowrap' }}
            />
            <MynyfyText
              title={location?.area?.name}
              color={theme.NAVY_BLUE}
              pBig
              style={{ paddingLeft: 4 }}
            />
          </div>
        </div>
        <div className='divider' style={{ marginInline: 10 }} />
      </Fragment>

      <DealsLayout noHeader />

      {isLoggedIn ? (
        <div style={{ paddingBottom: 30 }} />
      ) : (
        <Fragment>
          <div className='policyContainer' id='PrivacyPolicy'>
            <Card
              className='feature rowSB'
              style={{ backgroundColor: theme.PRIMARY_BLUE }}
              onClick={() => navigate('/Policies')}>
              <MynyfyText title={'Mynyfy Policies'} color={'#fff'} semiBold />
              <KeyboardArrowRight style={{ color: '#fff' }} />
            </Card>
          </div>
          {window?.location?.hash === '#PrivacyPolicy' ? (
            <div>
              <PrivacyPolicy
                customHeader={() => (
                  <MynyfyText title={'Privacy Policy'} h4 bold style={{ marginLeft: 20 }} />
                )}
              />
            </div>
          ) : null}
        </Fragment>
      )}

      <LocationModal
        isOpen={isLocationModal}
        close={(e) => {
          if (!_.isEmpty(location) || e) setIsLocationModal(false);
        }}
        closeBtn={() => setIsLocationModal(false)}
      />

      <MynyfyAlert
        open={openLocationAlert}
        close={() => {}}
        content={'Please set your location to get personalized data'}
        action1={{
          label: 'Set Location',
          handler: () => {
            setOpenLocationAlert(false);
            setIsLocationModal(true);
          },
        }}
      />
    </div>
  );
};

export default HomeLayout;
