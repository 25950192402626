import React from 'react';
import _ from 'lodash';
import AdTips from './AdTips';
import { Checkbox } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import MynyfyText from '../../components/MynyfyText';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfySelect from '../../components/MynyfySelect';
import { ChevronRight } from '@mui/icons-material';

const StepTwoPublishAd = ({
  states,
  setSelectedState,
  selectedState,
  cities,
  setCities,
  selectedCity,
  setSelectedCity,
  areas,
  setAreas,
  selectedArea,
  setSelectedArea,
  categories,
  selectedCategory,
  setSelectedCategory,
  isInterested,
  setIsInterested,
  buyersCount,
  totalBuyers,
  getStepTwoDisabled,
  setStep,
}) => {
  return (
    <div>
      <MynyfyText title={'Select Ad location '} bold />
      {totalBuyers || buyersCount ? (
        <div>
          <MynyfySpan title={'Target audience : '} pSmall />
          <NumericFormat
            value={buyersCount || totalBuyers}
            displayType={'text'}
            thousandSeparator={true}
            prefix={''}
            renderText={(formattedValue) => (
              <MynyfySpan bold title={formattedValue} pSmall primary />
            )}
          />
          <MynyfySpan title={` users`} pSmall />
        </div>
      ) : null}
      <div style={{ margin: '0px -5px' }}>
        <MynyfySelect
          type='multi'
          placeholder={'State'}
          value={selectedState}
          onChange={async (e) => {
            setSelectedState(e);
            setSelectedCity(null);
            setCities([]);
            setSelectedArea(null);
            setAreas([]);
          }}
          list={states.map((res) => ({ label: res.name, value: res._id, data: res.data })) || []}
          className='dropField'
        />
        <MynyfySelect
          type='multi'
          placeholder={'City/District'}
          value={selectedCity}
          onChange={(e) => {
            setSelectedCity(e);
            setSelectedArea(null);
            setAreas([]);
          }}
          list={cities?.map((res) => ({ label: res.name, value: res._id, data: res.data })) || []}
          className='dropField'
        />
        <MynyfySelect
          type='multi'
          placeholder={'Area/Constituency'}
          value={selectedArea}
          onChange={(e) => setSelectedArea(e)}
          list={areas?.map((res) => ({ label: res.name, value: res._id, data: res.data })) || []}
          className='dropField'
        />
      </div>
      <MynyfyText title={'Select Ad Category'} bold small style={{ marginTop: 15 }} />
      <div style={{ margin: '0px -5px' }}>
        <MynyfySelect
          type='multi'
          placeholder={'Category'}
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e)}
          list={
            categories?.map((res) => ({ label: res.name, value: res._id, data: res.data })) || []
          }
          className='dropField'
        />
      </div>

      <div
        className='row'
        style={{ marginLeft: -12 }}
        onClick={() => setIsInterested(!isInterested)}>
        <Checkbox
          disableRipple
          checked={isInterested}
          value={'Need leads'}
          onClick={() => setIsInterested(!isInterested)}
        />
        <MynyfyText title={'Need leads?'} className='cursor' />
      </div>

      <AdTips step={2} />
      <MynyfyButton
        label={<ChevronRight fontSize='large' />}
        containerStyle={{ textAlign: 'end' }}
        className='stepBtn'
        disabled={getStepTwoDisabled()}
        onClick={() => setStep(3)}
      />
    </div>
  );
};

export default StepTwoPublishAd;
