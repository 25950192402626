import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Slide, Snackbar } from '@mui/material';
import MynyfyText from './MynyfyText';
import './common.scss';

const MynyfySnackbar = ({ open, vertical, horizontal, direction, message, type, handleClose }) => {
  const [transition, setTransition] = useState(undefined);

  function SlideTransition(props) {
    return <Slide {...props} direction={direction || 'down'} />;
  }

  useEffect(() => {
    if (open) {
      setTransition(() => SlideTransition);
    }
  }, [open]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: vertical || 'top', horizontal: horizontal || 'center' }}
        open={open}
        onClose={() => handleClose()}
        key={vertical + horizontal}
        autoHideDuration={5000}
        TransitionComponent={transition}
        id='SnackBar'>
        <Alert onClose={() => handleClose()} severity={type || 'success'} className='snackBar'>
          {message
            ? Array.isArray(message)
              ? message?.map((res, i) => (
                  <Fragment key={i}>
                    {i ? <MynyfyText title={res} p /> : <MynyfyText title={res} p semiBold />}
                  </Fragment>
                ))
              : message
            : null}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MynyfySnackbar;
