import { PUBLISH_AD } from '../actionTypes';

const INITIAL_STATE = null;

const publishAdsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUBLISH_AD:
      return action.payload;
    default:
      return state;
  }
};

export default publishAdsReducer;
