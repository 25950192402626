import React, { Fragment, useState } from 'react';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyInput from '../../components/MynyfyInput';
import IconButton from '@mui/material/IconButton';
import { VisibilityRounded, VisibilityOffRounded } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import validate from '../../utils/validate';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { MsgEnums } from '../../utils/MsgEnums';
import _ from 'lodash';
import profileService from '../../services/profileService';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const profileId = user?.user?.profile;

  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const [error, setError] = useState({});

  let VALIDATOR = {
    currentPassword: [(value) => (value ? null : 'Current Password required')],
    newPassword: [
      (value) =>
        value
          ? value.length > 5
            ? null
            : 'Password should be alteast 6 characters'
          : 'New Password required',
    ],
    confirmNewPassword: [
      (value) =>
        value
          ? value == newPassword
            ? null
            : 'Confirm Password is incorrect'
          : 'Current Password required',
    ],
  };

  const submitHandler = () => {
    let errObj = {
      currentPassword,
      newPassword,
      confirmNewPassword,
    };
    let isErrors = validate(errObj, VALIDATOR);
    setError(isErrors);
    if (_.isEmpty(isErrors)) {
      setClicked(true);
      let body = {
        oldPassword: currentPassword,
        newPassword: newPassword,
      };
      profileService
        .changePassword(profileId, body)
        .then((res) => {
          if (res.success) {
            dispatch(
              snackbarInfo({
                open: true,
                type: 'success',
                message: 'Password updated successfully',
              })
            );
            navigate(-1);
          } else {
            dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
          }
        })
        .finally(() => setClicked(false));
    } else {
      dispatch(snackbarInfo({ open: true, type: 'warning', message: MsgEnums.FILL_DETAILS }));
    }
  };

  return (
    <Fragment>
      <MynyfyHeader title={'Change Password'} />

      <div style={{ padding: '20px 15px' }}>
        <div className='rowSB'>
          <MynyfyInput
            fullWidth={true}
            type={showCurrentPassword ? 'text' : 'password'}
            placeHolder={'Current Password'}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            helperText={error?.currentPassword}
            error={error?.currentPassword ? true : false}
          />
          <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
            {showCurrentPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
          </IconButton>
        </div>
        <div className='rowSB'>
          <MynyfyInput
            fullWidth={true}
            type={showNewPassword ? 'text' : 'password'}
            placeHolder={'New Password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            helperText={error?.newPassword}
            error={error?.newPassword ? true : false}
          />
          <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
            {showNewPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
          </IconButton>
        </div>
        <MynyfyInput
          fullWidth={true}
          type={'password'}
          placeHolder={'Confirm New Password'}
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          helperText={error?.confirmNewPassword}
          error={error?.confirmNewPassword ? true : false}
        />
        <div style={{ textAlign: 'end', marginRight: -12 }}>
          {isClicked ? (
            <CircularProgress size={30} style={{ marginRight: 20, marginTop: 20 }} />
          ) : (
            <IconButton size='large' onClick={() => submitHandler()}>
              <CheckCircleIcon style={{ color: '#1d89cc', fontSize: '40px' }} />
            </IconButton>
          )}
        </div>
      </div>
    </Fragment>
  );
}
