import {
  JACKPOT,
  SEE_AND_WIN,
  SHOP_AND_WIN,
  SCAN_AND_WIN,
  JACKPOT_HISTORY,
  FOOD_SHOP_AND_WIN,
  SEE_AND_WIN_HISTORY,
  SHOP_AND_WIN_HISTORY,
  SCAN_AND_WIN_HISTORY,
  FOOD_SHOP_AND_WIN_HISTORY,
} from '../actionTypes';

const INITIAL_STATE = {
  jackpot: null,
  scanAndWin: null,
  shopAndWin: null,
  foodShopAndWin: null,
  seeAndWin: null,
  jackpotHistory: null,
  scanAndWinHistory: null,
  shopAndWinHistory: null,
  foodShopAndWinHistory: null,
  seeAndWinHistory: null,
};

const contestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JACKPOT:
      return {
        ...state,
        jackpot: action.payload,
      };
    case SCAN_AND_WIN:
      return {
        ...state,
        scanAndWin: action.payload,
      };
    case SHOP_AND_WIN:
      return {
        ...state,
        shopAndWin: action.payload,
      };
    case FOOD_SHOP_AND_WIN:
      return {
        ...state,
        foodShopAndWin: action.payload,
      };
    case SEE_AND_WIN:
      return {
        ...state,
        seeAndWin: action.payload,
      };
    case JACKPOT_HISTORY:
      return {
        ...state,
        jackpotHistory: action.payload,
      };
    case SCAN_AND_WIN_HISTORY:
      return {
        ...state,
        scanAndWinHistory: action.payload,
      };
    case SHOP_AND_WIN_HISTORY:
      return {
        ...state,
        shopAndWinHistory: action.payload,
      };
    case FOOD_SHOP_AND_WIN_HISTORY:
      return {
        ...state,
        foodShopAndWinHistory: action.payload,
      };
    case SEE_AND_WIN_HISTORY:
      return {
        ...state,
        seeAndWinHistory: action.payload,
      };
    default:
      return state;
  }
};

export default contestReducer;
