import React, { useEffect, useState } from 'react';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfyText from '../../components/MynyfyText';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import commonService from '../../services/commonService';
import contestService from '../../services/contestService';
import paymentService from '../../services/paymentService';
import { PaymentGateways, TransactionType } from '../../utils/enums';
import MynyfyLoader from '../../components/MynfyfLoader';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { useLocation, useNavigate } from 'react-router';
import { MynyfyEnums } from '../../utils/MynyfyEnums';
import { RazorPayOptions } from '../../payments/RazorPay';
import MynyfySpan from '../../components/MynyfySpan';
import './publishAd.scss';
import MynyfyHeader from '../../components/MynyfyHeader';
import { Card } from '@mui/material';
import { fileUploadToS3WD } from '../../utils/S3Uploader';

const PreviewAd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();

  const user = useSelector((state) => state.user);
  let profile = user.profile?.profile;

  const [details, setDetails] = useState(null);
  const [isClicked, setClicked] = useState(false);
  const [isFirstAd, setFirstAd] = useState(false);

  useEffect(() => {
    setDetails(route?.state?.details);
    // checkFirstAd();
  }, []);

  const checkFirstAd = () => {
    contestService.checkFirstAd().then((res) => {
      if (res.data == 'Good to go') setFirstAd(true);
      else setFirstAd(false);
    });
  };

  const initiatePayment = () => {
    setClicked(true);
    let price = 1000;
    commonService
      .categorySort('AdPrice')
      .then((priceRes) => {
        let amount = Number(priceRes[0].data) || price;
        initiateRazorPayPayment(amount);
      })
      .catch((e) => console.log(e));
  };

  const initiateRazorPayPayment = (amount) => {
    paymentService
      .initiateRazorPayPayment({
        // TODO
        amount: amount,
        type: TransactionType.LUCKY_DRAW,
      })
      .then((res) => {
        if (res.success) {
          razorPayPaymentHandler({ amount: res.data.amount, orderId: res.data.orderId });
        } else {
          setClicked(false);
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      })
      .catch((e) => setClicked(false));
  };

  const razorPayPaymentHandler = ({ amount, orderId }) => {
    let options = RazorPayOptions({
      amount,
      orderId,
      user: profile,
      description: 'Ad publishing Charges (includes GST)',
    });
    options.handler = (res) => {
      res.type = PaymentGateways.RAZOR_PAY;
      if (res.razorpay_order_id && res.razorpay_payment_id && res.razorpay_signature) {
        submitHandler({ paymentDetails: res });
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: 'Payment Failed' }));
      }
    };
    options.modal = { ondismiss: () => setClicked(false) };

    const rzpay = new window.Razorpay(options);
    rzpay.open();
  };

  const submitHandler = async ({ paymentDetails }) => {
    setClicked(true);
    let body = {
      name: details.luckyDrawName,
      terms: details.termsConditions,
      image: details.adImage,
      paymentDetails,
    };
    if (!_.isEmpty(details.state)) body.state = details.state.map((e) => e.value);
    if (!_.isEmpty(details.city)) body.city = details.city.map((e) => e.value);
    if (!_.isEmpty(details.area)) body.area = details.area.map((e) => e.value);
    if (!_.isEmpty(details.category)) body.category = details.category.map((e) => e.value);
    if (details.url) body.url = details.url;
    if (details.isInterested) body.isInterested = details.isInterested;

    let s3ErrorMsg = null;
    if (body.image) {
      await fileUploadToS3WD(body.image, 'LuckyDraw').then((res) => {
        if (res.status !== 403 && res.url) {
          body.image = res.url;
          s3ErrorMsg = null;
        } else {
          delete body.image;
          s3ErrorMsg = [
            'We have recieved your payment',
            'Unable to upload image, please upload the image again to newly created Ad below',
          ];
        }
      });
    }
    contestService
      .createAd(body)
      .then((res) => {
        if (res.success) {
          dispatch(
            snackbarInfo({
              open: true,
              type: s3ErrorMsg ? 'warning' : 'success',
              message: s3ErrorMsg || [
                'Ad Payment is successfull',
                'Your Ad is submitted and will be approved in 4hours',
              ],
            })
          );
          navigate(-3);
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setClicked(false));
  };

  return details ? (
    <div id='PublishAd'>
      <MynyfyHeader title={'Ad Preview'} />
      <Card style={{ margin: 10, borderRadius: 13 }}>
        <div style={{ marginTop: 5, textAlign: 'center' }}>
          <img src={details.imgUrl} className='previewImg' />
        </div>
        <div style={{ padding: 10 }}>
          <MynyfyText title={details.luckyDrawName} p bold />
          <MynyfyText title={details.termsConditions} p style={{ paddingTop: 5 }} />

          {details.category ? (
            <div className='row' style={{ paddingTop: 5 }}>
              <MynyfyText title={'Category :'} pSmall className='subHeading' bold />
              <MynyfyText
                title={details.category?.map((res) => res?.label).join(', ')}
                pSmall
                className='content'
              />
            </div>
          ) : null}

          {details.url ? (
            <div className='row' style={{ paddingTop: 6 }} onClick={() => window.open(details.url)}>
              <MynyfyText title={'Website :'} pSmall className='subHeading' bold />
              <MynyfyText title={'View Website'} primary pSmall bold className='content' />
            </div>
          ) : null}

          {!details.isInterested ? (
            <MynyfyText
              title={'Leads information will be shared'}
              style={{ paddingTop: 8 }}
              pSmall
            />
          ) : null}

          <div className='adLocation'>
            <MynyfySpan title={'Your Ad will be Published for'} />
            <MynyfySpan title={' 3 days '} bold />
            <MynyfySpan title={'from the approval date in'} />
            &nbsp;
            <MynyfySpan title={details.area ? '' : 'entire '} />
            <MynyfySpan
              title={
                details.state
                  ? details.city
                    ? details.area
                      ? `${details.area?.map((res) => res?.label)?.join(', ')} `
                      : `${details.city?.map((res) => res?.label)?.join(', ')} `
                    : `${details.state?.map((res) => res?.label)?.join(', ')} `
                  : `India`
              }
              bold
            />
            <MynyfySpan
              title={
                details.state ? (details.city ? (details.area ? 'area' : 'city') : 'state') : ''
              }
            />
          </div>
        </div>
      </Card>
      <div style={{ padding: '10px 20px' }}>
        <MynyfySpan title={'By clicking on '} p />
        <MynyfySpan title={isFirstAd ? 'Try for free' : 'Pay to publish'} bold p />
        <MynyfySpan title={', you will be accepted '} p />
        <MynyfySpan
          title={'Mynyfy Guidelines For Advertisements'}
          link
          onClick={() => navigate('/Policies/PublishAdPolicy')}
        />
      </div>
      <div style={{ paddingBottom: 20 }}>
        <MynyfyButton
          label={isFirstAd ? 'Try for free' : 'Pay to publish'}
          center
          style={{ borderRadius: 50 }}
          containerStyle={{ marginTop: 10 }}
          onClick={() => {
            if (isFirstAd) submitHandler({ paymentDetails: { type: 'FIRST_TIME' } });
            else initiatePayment();
          }}
          loading={isClicked}
          disabled={isClicked}
        />
      </div>
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default PreviewAd;
