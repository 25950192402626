import React, { Fragment } from 'react';
import MynyfyHeader from '../../components/MynyfyHeader';
import { useLocation } from 'react-router';
import MynyfyText from '../../components/MynyfyText';
import { CircleRounded } from '@mui/icons-material';
import { MynyfyEnums } from '../../utils/MynyfyEnums';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyEmailSupport from '../../components/MynyfyEmailSupport';

const unacceptableContent1 = [
  'Content that is illegal or otherwise considered unacceptable to people who use our platform are unacceptable.',
  'Ads must not constitute, facilitate or promote illegal products, services or activities, vaccine discouragement.',
  'Ads must not discriminate or encourage discrimination against people based on personal attributes such as race, ethnicity, colour, national origin, religion, age, sex, sexual orientation, gender identity, family status, disability, medical or genetic condition.',
  'Ads must not contain claims that a person or group of people are a threat to the physical safety, health or survival of others on the basis of race, ethnicity, national origin, religious affiliation, sexual orientation, caste, sex, gender, gender identity, serious disease, disability or immigration status.',
  "Ads must not promote statements of inferiority, contempt or disgust, or other content identified in tier 2 of the Community Standards' Hate Speech policy, based on legal immigration status.",
  'Ads must not contain spyware, malware or any software that results in an unexpected or deceptive experience.',
  "Content that may negatively affect people's health and safety.",
  'Ads must not promote the sale or use of weapons, ammunition or explosives. This includes ads for weapon modification accessories.',
  'Ads must not promote the sale or use of tobacco or nicotine products and related paraphernalia unless they are cessation products. Ads must not promote delivery devices such as electronic cigarettes, vaporisers or any other products that simulate smoking or are otherwise designed for use with tobacco or nicotine products.',
  'Ads must not contain adult content. This includes nudity, depictions of people in explicit or suggestive positions, or activities that are overly suggestive or sexually provocative.',
  'Ads that assert or imply the ability to meet someone, connect with them or view content created by them must not be positioned in a sexual way or with an intent to sexualise the person featured in the ad.',
  'Ads with external misleading and prohibited landing pages that provide an unexpected or disruptive experience.',
  "Ads must not contain content that asserts or implies personal attributes. This includes direct or indirect assertions or implications about a person's race, ethnicity, religion, beliefs, age, sexual orientation or practices, gender identity, disability, physical or mental health, vulnerable financial status, voting status, membership in a trade union, criminal record or name.",
  'Ads must not contain shocking, sensational or excessively violent content.',
  'Ads must not contain content that exploits crises or controversial events for commercial purposes.',
  'Ads must not promote the sale of human body parts or fluids.',
  "Ads that promote or reference alcohol must comply with all applicable local laws, required or established industry codes, guidelines, licences and approvals, and include age and country targeting criteria consistent with Mynyfy's targeting requirements and applicable local laws. Note that our policies prohibit ads promoting or referencing alcohol in some countries, based on local law.",
  'Ads must not promote the sale or use of adult products or services. Ads promoting sexual and reproductive health products or services, such as contraception and family planning, must be targeted to people aged 18 or older and must not focus on sexual pleasure.',
  'Ads for dating services are not allowed.',
  'Ads for online pharmacies are only allowed with prior written permission. Mynyfy requires online pharmacies who wish to run ads to be certified with Legit Script.',
];

const unacceptableContent2 = [
  'Advertisers promoting financial products and services must demonstrate that they are authorised by the relevant regulatory authorities where this is a requirement; and any such authorisation may be subject to review by Mynyfy. Advertisers are also required to comply with disclosure requirements set by law.',
];

const extraPoints = [
  "Mynyfy defines online gambling and gaming as any product or service where anything of monetary value is included as part of a method of entry and prize. Ads that promote online gambling and gaming are only allowed with our prior written permission. Authorised advertisers must follow all applicable laws and include targeting criteria consistent with Mynyfy's targeting requirements. At a minimum, ads may not be targeted to people under 18 years of age.",
  "Ads for social casino games, which are online games that simulate casino gambling (e.g. poker, slots, roulette etc.) where there is no opportunity to win money or money's worth, are only allowed if they are targeted to people 18 years or older.",
  'Ads for subscription services must disclose information on pricing and recurrent billing.',
];

const AdPolicy = ({}) => {
  let data = useLocation();

  return (
    <Fragment>
      <MynyfyHeader title={data.state || 'Mynyfy guidelines for Advertisements'} />
      <div style={{ padding: 20 }} id='policy'>
        <MynyfyText title={'Mynyfy guidelines for Advertisements'} pBig bold />
        <MynyfyText
          title={
            'Mynyfy’s advertising guidelines provide details and guidance on the types of ad’s content that are allowed and that are prohibited. When ad is published, it is reviewed against our policies and approved if the guidelines are met else it is rejected and money is remitted back to the paying account.'
          }
          style={{ paddingTop: 10 }}
        />
        <MynyfyText
          title={
            'There are possibilities that account may be deactivated if the prohibited contest is published repeatedly.'
          }
          style={{ paddingTop: 10 }}
        />
        <div style={{ paddingTop: 10 }}>
          <MynyfySpan
            title={'If you think that your ad was mistakenly rejected, you may report it to '}
          />
          <MynyfyEmailSupport />
          <MynyfySpan title={'. It will be reviewed, and necessary action will be taken.'} />
        </div>
        <MynyfyText
          title={
            "It is an advertiser's responsibility to understand and comply with our policies outlined in Mynyfy’ s Advertising guidelines, our Terms of Service and any other applicable terms and guidelines, in addition to all local laws, regulations and, where applicable, self-regulatory advertising codes. Advertisers whose ads are rejected will typically be provided with an opportunity to edit their ads in order to bring them into compliance and can request another review if they believe their ad was incorrectly rejected."
          }
          style={{ paddingTop: 10 }}
        />

        <MynyfyText
          title={'Our advertising policy Guidelines'}
          pBig
          bold
          style={{ paddingTop: 20 }}
        />
        <MynyfyText
          title={
            'All advertisers to comply with the laws in their jurisdiction, not engage in discriminatory practices.'
          }
          style={{ paddingTop: 10 }}
        />
        <MynyfyText
          title={
            'Mynyfy don’t take any responsibility of copy rights and validating ads published which has products, services, schemes or offers which may be deceptive or misleading, including those meant to scam people out of money or personal information. This is sole responsibility of the publisher.'
          }
          style={{ paddingTop: 10 }}
        />
        <MynyfyText
          title={
            'Ads containing shocking, sensational, or excessively violent content, certain adult content and profanity are prohibited.'
          }
          style={{ paddingTop: 10 }}
        />

        <MynyfyText title={'The ad review processes'} pBig bold style={{ paddingTop: 10 }} />
        <MynyfyText
          title={
            'Our ad review system is manual to check ads against our policies. Ads are reviewed as soon as they are published, and is typically completed within 24 hours, although it may take longer in some cases. During this review, the status of the ad will be "In review".  If the ad published is meeting all the guidelines it will be made live else, it will be rejected.'
          }
          style={{ paddingTop: 10 }}
        />
        <MynyfyText
          title={
            'If it is rejected, create a new ad or edit your ad as per the guidelines and published again for review.'
          }
          style={{ paddingTop: 10 }}
        />

        <MynyfyText
          title={'Unacceptable Content'}
          pBig
          bold
          style={{ paddingTop: 20, paddingBottom: 10 }}
        />

        {unacceptableContent1.map((res, i) => (
          <div className='row' style={{ marginBottom: 10, alignItems: 'flex-start' }} key={i}>
            <CircleRounded className='dotIcon' />
            <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
          </div>
        ))}

        <div className='row' style={{ marginBottom: 10, alignItems: 'flex-start' }}>
          <CircleRounded className='dotIcon' />
          <div style={{ flexWrap: 'wrap', flexShrink: 1 }}>
            <MynyfySpan title={'Advertisers can apply for certification with LegitScript '} p />
            <a
              href='https://www.legitscript.com/certification/healthcare-certification'
              target='_blank'
              style={{ textDecoration: 'none' }}
            >
              <MynyfySpan title={'here'} link />
            </a>
            <MynyfySpan
              title={
                '. Advertisers can request permission from Mynyfy by sending the certificate to '
              }
              p
            />
            <MynyfyEmailSupport />
          </div>
        </div>

        {unacceptableContent2.map((res, i) => (
          <div className='row' style={{ marginBottom: 10, alignItems: 'flex-start' }} key={i}>
            <CircleRounded className='dotIcon' />
            <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
          </div>
        ))}

        <div style={{ paddingTop: 40 }}>
          {extraPoints.map((res, i) => (
            <div className='row' style={{ marginBottom: 30, alignItems: 'flex-start' }} key={i}>
              <CircleRounded className='dotIcon' />
              <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
            </div>
          ))}
        </div>

        <div style={{ paddingTop: 10, paddingBottom: 40 }}>
          <MynyfySpan title={'For any other queries, please contact at '} pSmall />
          <MynyfyEmailSupport />
        </div>
      </div>
    </Fragment>
  );
};

export default AdPolicy;
