import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import MynyfyTab from '../../components/MynyfyTab';
import OrdersList from './OrdersList';
import { fetchOrders, fetchOrdersHistory } from '../../redux/actions/order.action';

const Orders = ({ goHome }) => {
  const orders = useSelector((state) => state.orders);

  return (
    <MynyfyTab
      goHome={goHome}
      tabs={['Active', 'Closed']}
      tabComponent={[
        <OrdersList type={'ACTIVE'} data={orders?.active} action={fetchOrders} />,
        <OrdersList type={'NONACTIVE'} data={orders?.history} action={fetchOrdersHistory} />,
      ]}
    />
  );
};

export default Orders;
