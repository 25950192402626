import React, { Fragment } from 'react';
import { Card, Tooltip } from '@mui/material';
import MynyfyText from '../../components/MynyfyText';
import MynyfyHeader from '../../components/MynyfyHeader';
import { Visibility, AdsClick, PersonSearch } from '@mui/icons-material';
import MynyfySpan from '../../components/MynyfySpan';
import { useLocation } from 'react-router';
import moment from 'moment';
import './flashAds.scss';
import MynyfyPDFGenerator from '../../components/MynyfyPDFGenerator';
import FlashAdInvoice from '../../Invoices/FlashAdInvoice';
import { s3ToCloudFront } from '../../utils/CommonMethods';

export default function FlashAdsDetails() {
  const { state } = useLocation();
  let details = state?.details;

  return (
    <Fragment>
      <MynyfyHeader
        title={'Flash Ads Details'}
        customElememt={() =>
          details.transactionDetails ? (
            <MynyfyPDFGenerator
              customBtn={() => <MynyfyText title={'Download Invoice'} link />}
              htmlString={FlashAdInvoice({ details })}
              pdfName={'Flash Ad - Invoice'}
            />
          ) : null
        }
      />
      <div id='flashAd' style={{ padding: 25, textAlign: 'unset' }}>
        <div style={{ textAlign: 'center' }}>
          <Card style={{ padding: 20, borderRadius: 7 }}>
            <img
              src={s3ToCloudFront(details?.image?.url)}
              alt='Earn without spending'
              style={{ width: '100%' }}
            />
            <div className='rowSB' style={{ paddingTop: 10 }}>
              <div>
                <MynyfyText title={moment(details?.createdAt).format('DD MMM YYYY')} />
              </div>
              <div>
                <MynyfySpan title={'Status: '} primary />
                <MynyfySpan
                  title={details?.isActive ? 'Active' : 'In Active'}
                  color={details?.isActive ? 'green' : 'red'}
                />
              </div>
            </div>
          </Card>
          <Card className='rowC tabConatiner' style={{ padding: 15, gap: 25 }}>
            <div className='row'>
              <Tooltip title='Views' arrow placement='top'>
                <Visibility color='primary' />
              </Tooltip>
              <MynyfyText title={details?.totalViews || 0} style={{ paddingLeft: 5 }} />
            </div>
            <div className='row'>
              <Tooltip title='Unique Views' arrow placement='top'>
                <PersonSearch color='primary' />
              </Tooltip>
              <MynyfyText title={details?.uniqueViews || 0} style={{ paddingLeft: 5 }} />
            </div>
            <div className='row'>
              <Tooltip title='Website Clicks' arrow placement='top'>
                <AdsClick color='primary' />
              </Tooltip>
              <MynyfyText title={details?.websiteClicks || 0} style={{ paddingLeft: 5 }} />
            </div>
          </Card>
        </div>

        <Card className='tabConatiner' style={{ padding: 10 }}>
          <div className='rowSB details'>
            <MynyfyText title={'Title: '} className='subHeading' />
            <MynyfyText title={details?.description} />
          </div>
          {details?.endDate ? (
            <div className='rowSB details'>
              <MynyfyText title={'End Date: '} className='subHeading' />
              <MynyfyText title={moment(details?.endDate).format('DD MMMM YYYY')} />
            </div>
          ) : null}
          <div className='rowSB details'>
            <MynyfyText title={'Category: '} className='subHeading' />
            <MynyfyText title={details?.category?.name || '---'} style={{ textAlign: 'right' }} />
          </div>
          <div className='rowSB details'>
            <MynyfyText title={'State: '} className='subHeading' />
            <MynyfyText
              title={details?.state?.map((e) => e.name).join(', ') || 'All'}
              style={{ textAlign: 'right' }}
            />
          </div>
          <div className='rowSB details'>
            <MynyfyText title={'City: '} className='subHeading' />
            <MynyfyText
              title={details?.city?.map((e) => e.name).join(', ') || 'All'}
              style={{ textAlign: 'right' }}
            />
          </div>
          <div className='rowSB details'>
            <MynyfyText title={'Area: '} className='subHeading' />
            <MynyfyText
              title={details?.area?.map((e) => e.name).join(', ') || 'All'}
              style={{ textAlign: 'right' }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
