import React from 'react';
import MynyfyHeader from '../../components/MynyfyHeader';
import { PolicyList } from './PolicyList';
import { Card } from '@mui/material';
import MynyfyText from '../../components/MynyfyText';
import './policy.scss';
import { ChevronRightRounded } from '@mui/icons-material';
import { ReactComponent as PolicyIcon } from '../../assets/svg/policy.svg';
import { ReactComponent as TermsIcon } from '../../assets/svg/Terms.svg';
import { ReactComponent as RefundIcon } from '../../assets/svg/outlineRefund.svg';
import { ReactComponent as ShippingIcon } from '../../assets/svg/shipping.svg';
import { ReactComponent as AboutIcon } from '../../assets/svg/about.svg';
import { ReactComponent as ContactIcon } from '../../assets/svg/contact.svg';
import { ReactComponent as SeeAndWinIcon } from '../../assets/svg/see_and_win.svg';
import { useNavigate } from 'react-router-dom';

const Policies = () => {
  const navigate = useNavigate();

  return (
    <div id='policy'>
      <MynyfyHeader title={'Policies'} />
      {PolicyList.map((res, i) => (
        <Card
          className='rowSB policyCard'
          key={i}
          onClick={() => {
            if (['about', 'contact'].includes(res.icon)) {
              navigate('/' + res.screenName, { state: res.name });
            } else {
              navigate(res.screenName, { state: res.name });
            }
          }}>
          <div className='row'>
            {res.icon === 'policy' ? (
              <PolicyIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'terms' ? (
              <TermsIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'refund' ? (
              <RefundIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'return' ? (
              <ShippingIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'ads' ? (
              <SeeAndWinIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'about' ? (
              <AboutIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'contact' ? (
              <ContactIcon className='icon' fill={res.iconColor} />
            ) : null}
            <div style={{ paddingLeft: 20 }}>
              <MynyfyText title={res.name} bold h6 />
              <MynyfyText title={res.helperText} pSmall className='helperText' />
            </div>
          </div>
          <ChevronRightRounded className='icon' />
        </Card>
      ))}
    </div>
  );
};

export default Policies;
