import React, { Fragment } from 'react';
import MynyfyText from './MynyfyText';

const DownloadApp = () => {
  return (
    <Fragment>
      <div className='rowC' style={{ marginTop: 15 }}>
        <MynyfyText title={'DOWNLOAD APP'} bold style={{ padding: '5px' }} h6 />
        <img
          src={require('../assets/imgs/appstore.icon.png')}
          width={30}
          height={30}
          className='cursor'
          style={{ marginInline: 25 }}
          onClick={() => {
            window.open('https://apps.apple.com/in/app/mynyfy-local-simplified/id1500374826');
          }}
        />
        <img
          src={require('../assets/imgs/playstore.png')}
          width={50}
          height={50}
          className='cursor'
          onClick={() => {
            window.open('https://play.google.com/store/apps/details?id=com.mynyfyxbuyer');
          }}
        />
      </div>
      <div className='divider' style={{ margin: '5px 10px 15px' }} />
    </Fragment>
  );
};

export default DownloadApp;
