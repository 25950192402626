import React from 'react';
import MynyfyTab from '../../components/MynyfyTab';
import { useSelector } from 'react-redux';
import { fetchFavStores, fetchStores } from '../../redux/actions/store.action';
import StoresList from './StoresList';

const Stores = () => {
  const stores = useSelector((state) => state.stores?.stores);
  const favStores = useSelector((state) => state.stores?.favStores);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  return (
    <MynyfyTab
      noSwipe
      tabs={['Stores', 'Favourite Stores']}
      tabComponent={[
        <StoresList
          isFav={false}
          online={true}
          data={stores}
          action={fetchStores}
          emptyListMsg={'No Stores are there at your location'}
        />,
        <StoresList
          isFav={true}
          online={true}
          data={favStores}
          action={fetchFavStores}
          emptyListMsg={
            isLoggedIn
              ? "You haven't added any Stores to your favourites"
              : 'Please Login for your favourite Stores'
          }
        />,
      ]}
    />
  );
};
export default Stores;
