import React, { Fragment, useEffect, useState } from 'react';
import theme from '../../utils/theme';
import _ from 'lodash';
import validate from '../../utils/validate';
import commonService from '../../services/commonService';
import { useDispatch, useSelector } from 'react-redux';
import StepOnePublishAd from './StepOnePublishAd';
import StepTwoPublishAd from './StepTwoPublishAd';
import StepThreePublishAd from './StepThreePublishAd';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfySpan from '../../components/MynyfySpan';
import { useNavigate } from 'react-router';
import './publishAd.scss';
import MynyfyPre from '../../components/MynyfyPre';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyUploadImg from '../../components/MynyfyUploadImg';
import { Photo, PhotoCamera } from '@mui/icons-material';
import MynyfyModal from '../../components/MynyfyModal';
import MynyfyText from '../../components/MynyfyText';
// import { fetchBuyersCount } from '../../store/actions/stats.action';

let VALIDATOR = {
  luckyDrawName: [(value) => (_.isEmpty(value) ? `Please enter Title of your Ad` : null)],
  state: [(value) => (_.isEmpty(value) ? `Please select State` : null)],
  city: [(value) => (_.isEmpty(value) ? `Please select City` : null)],
  area: [(value) => (_.isEmpty(value) ? `Please select Area` : null)],
  category: [(value) => (_.isEmpty(value) ? `Please select Category` : null)],
  termsConditions: [(value) => (_.isEmpty(value) ? `Please enter Ad Description` : null)],
  image: [(value) => (_.isEmpty(value) ? `Please upload Ad Image` : null)],
};

const CreatePublishAd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const states = useSelector((state) => state.appData?.states || []);
  const categories = useSelector((state) => state.appData?.categories || []);
  const totalBuyers = useSelector((state) => state.stats?.buyers || 0);

  const [luckyDrawName, setLuckyDrawName] = useState(null);
  const [termsConditions, setTermsConditions] = useState(null);
  const [error, setError] = useState({});
  const [adImage, setAdImage] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [url, setUrl] = useState(null);
  const [isInterested, setIsInterested] = useState(null);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [buyersCount, setBuyersCount] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (adImage) {
      // Create a blob URL from the blob
      const blobUrl = URL.createObjectURL(adImage.imgFile);
      setImgUrl(blobUrl);

      // Clean up the blob URL when the component unmounts
      return () => URL.revokeObjectURL(adImage.imgFile);
    }
  }, [adImage]);

  const fetchAppData = async (id, key) => {
    await commonService.publicCategorySort(id).then((res) => {
      let sortedData = _.orderBy(res, ['name'], ['asc']);
      if (key === 'city') setCities(sortedData);
      if (key === 'area') setAreas(sortedData);
    });
  };

  // useEffect(() => {
  //   if (!totalBuyers) {
  //     getBuyersCount({});
  //   }
  // }, []);

  const getBuyersCount = (params) => {
    commonService
      .count(params)
      .then((res) => {
        if (res) {
          // if (_.isEmpty(params)) {
          //   dispatch(fetchBuyersCount(res));
          // } else {
          // }
          setBuyersCount(res);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (_.isEmpty(selectedState)) {
      setBuyersCount(0);
    }
    locationHandler({
      locItems: states,
      locKey: selectedState,
      locTypeKey: 'state',
      fetchKey: 'city',
      allKey: 'All States',
      filterKey: setSelectedState,
    });
  }, [selectedState]);

  useEffect(() => {
    if (!_.isEmpty(selectedState) && _.isEmpty(selectedCity)) {
      locationIdFinder(states, selectedState, 'state');
    }
    locationHandler({
      locItems: cities,
      locKey: selectedCity,
      locTypeKey: 'city',
      fetchKey: 'area',
      allKey: 'All Cities',
      filterKey: setSelectedCity,
    });
  }, [selectedCity]);

  useEffect(() => {
    if (!_.isEmpty(selectedCity) && _.isEmpty(selectedArea)) {
      locationIdFinder(cities, selectedCity, 'city');
    }
    locationHandler({
      locItems: areas,
      locKey: selectedArea,
      locTypeKey: 'area',
      allKey: 'All Areas',
      filterKey: setSelectedArea,
    });
  }, [selectedArea]);

  const locationHandler = ({ locItems, locKey, locTypeKey, fetchKey, allKey, filterKey }) => {
    if (locKey?.length == 1 && !locKey?.includes(allKey)) {
      if (fetchKey) {
        fetchAppData(locKey[0]?.data, fetchKey);
      }
      locationIdFinder(locItems, locKey, locTypeKey);
    }
    if (locKey?.length > 1) {
      if (locKey?.includes(allKey)) {
        if (locKey?.[0] == allKey) {
          let reqKeys = locKey.filter((e) => e !== allKey);
          filterKey(reqKeys);
          locationIdFinder(locItems, reqKeys, locTypeKey);
        } else {
          filterKey([allKey]);
          if (locTypeKey == 'state') {
            setBuyersCount(0);
          }
          if (locTypeKey == 'city') {
            locationIdFinder(states, selectedState, 'state');
          }
          if (locTypeKey == 'area') {
            locationIdFinder(cities, selectedCity, 'city');
          }
        }
      } else {
        locationIdFinder(locItems, locKey, locTypeKey);
      }
      if (locTypeKey == 'state') {
        setSelectedCity(null);
        setCities([]);
      }
      if (locTypeKey == 'state' || locTypeKey == 'city') {
        setSelectedArea(null);
        setAreas([]);
      }
    }
  };

  const locationIdFinder = (list, findKey, paramsKey) => {
    let ids = findKey?.map((res) => list.find((e) => e.data == res)?._id);
    let reqIds = ids?.filter((e) => e !== undefined);
    // if (!_.isEmpty(reqIds)) getBuyersCount({ [paramsKey]: reqIds });
  };

  const uploadHandler = (event) => {
    if (event.imgFile.size <= 524288) {
      event.purposeType = 'LuckyDraw';
      setAdImage(event);
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'File size must not exceed 5MB' })
      );
    }
  };

  const validateDetails = () => {
    let obj = {
      ...error,
      luckyDrawName,
      termsConditions,
      image: adImage,
    };
    let errors = validate(obj, VALIDATOR);
    if (_.isEmpty(errors)) {
      setError(errors);
      let details = {
        luckyDrawName,
        state: selectedState,
        city: selectedCity,
        area: selectedArea,
        category: selectedCategory,
        url,
        isInterested,
        termsConditions,
        adImage,
        imgUrl,
      };
      navigate('/PublishAds/Create/Preview', { state: { details } });
    } else {
      setError(errors);
    }
  };

  const getStepOneDisabled = () => {
    if (_.isEmpty(error) && luckyDrawName && termsConditions) return false;
    else return true;
  };

  const getStepTwoDisabled = () => {
    if (_.isEmpty(error)) return false;
    else return true;
  };

  return (
    <Fragment>
      <MynyfyHeader
        title={'Create Ad'}
        customElememt={() => (
          <div>
            <MynyfySpan title={step} bold h3 />
            <MynyfySpan title={'/3'} h3 />
          </div>
        )}
        customBack={() => (step === 1 ? navigate(-1) : setStep((prev) => prev - 1))}
      />
      <div id='PublishAd' style={{ padding: '15px 20px' }}>
        <MynyfyPre
          title={
            'Captivate your audience and generate more leads for your business in 3 easy steps.'
          }
          pSmall
          color={theme.MUTED_BLUE}
        />

        <div style={{ paddingTop: 15 }}>
          {step === 1 &&
            StepOnePublishAd({
              luckyDrawName,
              setLuckyDrawName,
              error,
              setTermsConditions,
              termsConditions,
              url,
              setUrl,
              getStepOneDisabled,
              setStep,
            })}
          {step === 2 &&
            StepTwoPublishAd({
              states,
              setSelectedState,
              selectedState,
              cities,
              setSelectedCity,
              selectedCity,
              areas,
              setSelectedArea,
              selectedArea,
              categories,
              selectedCategory,
              setSelectedCategory,
              setIsInterested,
              isInterested,
              buyersCount,
              totalBuyers,
              setCities,
              setAreas,
              getStepTwoDisabled,
              setStep,
            })}
          {step === 3 &&
            StepThreePublishAd({
              uploadHandler,
              adImage,
              error,
              validateDetails,
              setStep,
              imgUrl,
            })}
        </div>
      </div>
      {/* <MynyfyModal open={isOpen} close={() => setOpen(false)}>
        <div>
          <MynyfyText
            title={'Please Camera or Gallery to upload Ad image'}
            style={{ paddingBottom: 20 }}
            center
            small
          />
          <div className='rowSA'>
            <div className='imgPickerContainer'>
              <MynyfyUploadImg
                accept={true}
                type='icon'
                icon='PhotoCamera'
                label={'Take Photo'}
                name={'adImage'}
                containerMargin='0px 5px 5px'
                onChange={(e) => uploadHandler(e)}
              />
            </div>
            <div className='imgPickerContainer'>
              <MynyfyUploadImg
                accept={true}
                type='icon'
                icon='Photo'
                label={'Pick from Gallery'}
                name={'adImage'}
                containerMargin='0px 5px 5px'
                onChange={(e) => uploadHandler(e)}
              />
            </div>
          </div>
        </div>
      </MynyfyModal> */}
    </Fragment>
  );
};

export default CreatePublishAd;
