import React, { useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { ReactComponent as FavoriteIcon } from '../../assets/svg/favorites.svg';
import { ReactComponent as NotificationIcon } from '../../assets/svg/notification.svg';
import { ReactComponent as ChangePasswordIcon } from '../../assets/svg/changePassword.svg';
import { ReactComponent as LogoutIcon } from '../../assets/svg/logout.svg';
import { ReactComponent as ShareIcon } from '../../assets/svg/share.svg';
import { ReactComponent as TransactionsIcon } from '../../assets/svg/transactions.svg';
import { ReactComponent as AboutIcon } from '../../assets/svg/about.svg';
import { ReactComponent as ContactIcon } from '../../assets/svg/contact.svg';
import { ReactComponent as SeeAndWinIcon } from '../../assets/svg/see_and_win.svg';
import { ReactComponent as PolicyIcon } from '../../assets/svg/policy.svg';
import { ProfileList } from './ProfileList';
import { Card } from '@mui/material';
import { ChevronRightRounded, ChevronLeftRounded } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import './profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import MynyfySpan from '../../components/MynyfySpan';
import { MynyfyEnums } from '../../utils/MynyfyEnums';
import authService from '../../services/authService';
import { userLogout } from '../../redux/actions/user.action';
import MynyfyEmailSupport from '../../components/MynyfyEmailSupport';
import _ from 'lodash';
import { updateLocation } from '../../redux/actions/location.action';
import SelectAdType from '../../components/SelectAdType';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const route = useLocation();

  const deviceId = useSelector((state) => state.user?.deviceId);
  const profile = useSelector((state) => state.user?.user);
  const location = useSelector((state) => state.location?.location);

  const [openAdModal, setOpenAdModal] = useState(false);

  const logoutHandler = () => {
    authService.logout({ userId: profile?.id, deviceId }).catch(() => {});
    authService._removeToken();
    let userLoc = _.cloneDeep(location);
    dispatch(userLogout());
    dispatch(updateLocation(userLoc));
    if (route.state?.isDirectUrl) navigate(-1);
    else navigate('/');
  };

  return (
    <div id='profile'>
      <div className='profileContainer'>
        <div className='row'>
          <ChevronLeftRounded className='backIcon' onClick={() => navigate(-1)} />
          <MynyfyText title={'Profile'} h3 color={'#fff'} />
        </div>
        <MynyfyText
          title={
            'Mynyfy Login is the one common account for all Mynyfy sellers across the platform.'
          }
          color='#fff'
          style={{ padding: '5px 15px' }}
        />

        <Card className='profileCard'>
          <div className='row'>
            <div className='profileImgContainer'>
              <MynyfyText
                title={
                  (profile?.firstName?.charAt(0) || 'M') + (profile?.lastName?.charAt(0) || '')
                }
                bold
                className='profileName'
              />
            </div>
            <div className='profileDetails'>
              <div>
                <MynyfySpan title={profile?.firstName} pBig semiBold />
                &nbsp;
                {profile?.lastName ? <MynyfySpan title={profile?.lastName} pBig semiBold /> : null}
              </div>
              <MynyfyText title={profile?.mobile} pBig semiBold style={{ padding: '5px 0px' }} />
              <MynyfyText title={profile?.email} pBig semiBold />
            </div>
          </div>
          <div className='rowSB' style={{ padding: '10px 10px 0px' }}>
            <MynyfyText
              title={'Edit'}
              link
              center
              bold
              pBig
              style={{ marginLeft: 16 }}
              onClick={() => navigate('/EditProfile', { state: { noReferral: true } })}
            />
            <MynyfyText title={'My Address'} link semiBold onClick={() => navigate('/Address')} />
          </div>
        </Card>
      </div>
      {ProfileList.map((res, i) => (
        <Card
          className='rowSB optionCard'
          key={i}
          onClick={() => {
            if (res.icon === 'logout') logoutHandler();
            else if (res.icon === 'ads') setOpenAdModal(true);
            else navigate('/' + res.screenName);
          }}>
          <div className='row'>
            {res.icon === 'ads' ? (
              <SeeAndWinIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'favourites' ? (
              <FavoriteIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'notification' ? (
              <NotificationIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'transactions' ? (
              <TransactionsIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'share' ? (
              <ShareIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'about' ? (
              <AboutIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'policy' ? (
              <PolicyIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'contact' ? (
              <ContactIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'changePassword' ? (
              <ChangePasswordIcon className='icon' fill={res.iconColor} />
            ) : res.icon === 'logout' ? (
              <LogoutIcon className='icon' fill={res.iconColor} />
            ) : null}
            <div style={{ paddingLeft: 20 }}>
              <MynyfyText title={res.name} bold h6 />
              <MynyfyText title={res.helperText} pSmall className='helperText' />
            </div>
          </div>
          <ChevronRightRounded className='icon' />
        </Card>
      ))}
      <MynyfyText
        title={`Version : ${MynyfyEnums.VERSION}`}
        center
        light
        style={{ padding: '20px 0px 5px' }}
      />
      <div style={{ padding: '10px 0px 30px', textAlign: 'center' }}>
        <MynyfySpan title={'For support : '} light />
        <MynyfyEmailSupport />
      </div>
      <SelectAdType
        isVisible={openAdModal}
        closeModal={() => setOpenAdModal(false)}
        flashAdScreen={'/FlashAds'}
        adScreen={'/PublishAds'}
      />
    </div>
  );
};

export default Profile;
