import { STATES, CATEGORIES, AD_TIMER, ORDER_MYNTS } from '../actionTypes';

export const fetchStates = (states) => ({
  type: STATES,
  payload: states,
});

export const fetchCategories = (categories) => ({
  type: CATEGORIES,
  payload: categories,
});

export const fetchAdTimer = (timer) => ({
  type: AD_TIMER,
  payload: timer,
});

export const fetchOrderMynts = (ordderMynts) => ({
  type: ORDER_MYNTS,
  payload: ordderMynts,
});
