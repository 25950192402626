import React from 'react';
import Contest from '.';
import { ContestType } from '../../utils/enums';

const AllContests = ({ from, status, isHistory, brandDetails, sellerDetails }) => {
  return (
    <Contest
      type={[
        ContestType.JACKPOT,
        ContestType.SHOP_AND_WIN,
        ContestType.SCAN_AND_WIN,
        ContestType.SHOP_AND_SCAN,
      ]}
      status={status || 'active'}
      isCategories={false}
      emptyListTitle={'Contests'}
      from={from}
      isHistory={isHistory || false}
      brandDetails={brandDetails}
      sellerDetails={sellerDetails}
    />
  );
};

export default AllContests;
