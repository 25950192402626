import {
  BAG_OFFERS,
  BAG_PRODUCTS,
  BAG_CALCULATION,
  BAG_SELLING_ID,
  BAG_ABOUT,
} from '../actionTypes';

export const fetchBagOffers = (offers) => ({
  type: BAG_OFFERS,
  payload: offers,
});

export const fetchBagProducts = (products) => ({
  type: BAG_PRODUCTS,
  payload: products,
});

export const fetchBagCalculation = (calculation) => ({
  type: BAG_CALCULATION,
  payload: calculation,
});

export const fetchBagSellingId = (id) => ({
  type: BAG_SELLING_ID,
  payload: id,
});

export const fetchBagAbout = (about) => ({
  type: BAG_ABOUT,
  payload: about,
});
