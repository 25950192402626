import { Dialog } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import MynyfyText from './MynyfyText';
import ClaimedAnimation from '../assets/animations/claimed.json';

const MynyfyClaimed = ({ open, title, titleStyle, loop, animation, callback }) => {
  return (
    <Dialog
      open={open}
      PaperComponent={() => (
        <div>
          <MynyfyText
            title={title}
            style={{
              color: '#fff',
              top: '50%',
              left: '50%',
              padding: 10,
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              width: '85%',
              ...titleStyle,
            }}
            center
            h6
          />
          <Lottie
            options={{ loop: loop || false, animationData: animation || ClaimedAnimation }}
            eventListeners={
              callback ? [{ eventName: 'complete', callback: () => callback() }] : undefined
            }
          />
        </div>
      )}
    />
  );
};

export default MynyfyClaimed;
