import React, { useEffect, useState } from 'react';
import MynyfyText from './MynyfyText';
import theme from '../utils/theme';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import MynyfySpan from './MynyfySpan';
import MynyfyModal from './MynyfyModal';
import MynyfyLoader from './MynfyfLoader';
import { snackbarInfo } from '../redux/actions/snackbar.action';
import commonService from '../services/commonService';
import storeService from '../services/storeService';

const SelectSeller = ({
  isVisible,
  closeModal,
  brandId,
  selectedSeller,
  selectedAddress,
  setData,
}) => {
  const dispatch = useDispatch();

  const [sellers, setSellers] = useState(null);
  const [isNoSellers, setIsNoSellers] = useState(false);

  useEffect(() => {
    if (isVisible) {
      getSellersHandler();
    }
  }, [isVisible == true]);

  const getSellersHandler = async () => {
    let userAddress = [
      { type: 'State', name: selectedAddress.state },
      { type: 'City', name: selectedAddress.city },
      { type: 'Area', name: selectedAddress.locality },
    ];
    for (let i = 0; i < userAddress.length; i++) {
      await commonService.appDataByAny(userAddress[i]).then((res) => {
        userAddress[i]._id = res.data?._id;
      });
    }
    let body = {
      brand: brandId,
      pageLimit: 30,
      timeCutoff: Date.now(),
    };
    userAddress.map((res) => {
      body[res.type.toLowerCase()] = res._id;
    });
    getSellerList(body);
  };

  const getSellerList = (body) => {
    storeService.storeListByBrandId(body).then((res) => {
      if (res.success) {
        if (_.isEmpty(res.data)) {
          setIsNoSellers(true);
        } else {
          setSellers(res.data);
          if (res.data?.length > 1) {
            // do nothing
          } else {
            setData(res.data[0]);
            closeHandler();
          }
        }
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    });
  };

  const addressHandler = (key) => {
    return key ? `${key.name}, ` : null;
  };

  const closeHandler = () => {
    closeModal();
    setIsNoSellers(false);
  };

  return (
    <MynyfyModal open={isVisible} close={() => closeHandler()}>
      <div style={{ maxHeight: '75%', backgroundColor: '#fff' }}>
        {isNoSellers ? (
          <div style={{ padding: 20 }}>
            <MynyfyText title={'Sorry!'} bold h6 />
            <MynyfyText
              title={'No Stores are available at the selected delivery address'}
              p
              style={{ paddingTop: 8, paddingBottom: 5 }}
            />
          </div>
        ) : (
          <div id='OrderModals'>
            <div className='rowSB'>
              <MynyfyText title={'Select Store'} bold h5 />
            </div>
            <div>
              {sellers ? (
                _.isEmpty(sellers) ? (
                  <MynyfyText
                    title={'No Stores are available at the selected delivery address'}
                    style={{ padding: '100px 20px' }}
                    center
                  />
                ) : (
                  <div style={{ padding: '20px 0px' }}>
                    {sellers.map((res, i) => (
                      <div
                        className='orderModalContainer link'
                        style={{
                          backgroundColor: selectedSeller?._id == res._id ? theme.ICE_BLUE : '#fff',
                        }}
                        key={i}
                        onClick={() => {
                          setData(res);
                          closeHandler();
                        }}>
                        <div>
                          <MynyfyText title={res.name} bold p />
                          <div style={{ marginTop: 5 }}>
                            <MynyfySpan title={addressHandler(res.area)} />
                            <MynyfySpan title={addressHandler(res.city)} />
                            <MynyfySpan title={addressHandler(res.state)} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              ) : (
                <MynyfyLoader />
              )}
            </div>
          </div>
        )}
      </div>
    </MynyfyModal>
  );
};

export default SelectSeller;
