import {
  FETCH_ACTUAL_BRANDS,
  FETCH_ACTUAL_FAV_BRANDS,
  FETCH_BRANDS,
  FETCH_BRANDS_DETAILS,
  FETCH_FAV_BRANDS,
} from '../actionTypes';

export const fetchActualBrands = (actualBrands) => ({
  type: FETCH_ACTUAL_BRANDS,
  payload: actualBrands,
});

export const fetchBrands = (brands) => ({
  type: FETCH_BRANDS,
  payload: brands,
});

export const fetchActualFavBrands = (actualFavBrands) => ({
  type: FETCH_ACTUAL_FAV_BRANDS,
  payload: actualFavBrands,
});

export const fetchFavBrands = (favBrands) => ({
  type: FETCH_FAV_BRANDS,
  payload: favBrands,
});

export const fetchBrandDetails = (brandDetails) => ({
  type: FETCH_BRANDS_DETAILS,
  payload: brandDetails,
});
