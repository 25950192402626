import React from 'react';
import MynyfyText from '../../components/MynyfyText';
import MynyfyButton from '../../components/MynyfyButton';
import AdTips from './AdTips';
import MynyfyUploadImg from '../../components/MynyfyUploadImg';
import theme from '../../utils/theme';

const StepThreePublishAd = ({
  uploadingProgress,
  uploadHandler,
  adImage,
  error,
  validateDetails,
  imgUrl,
}) => {
  return (
    <>
      {uploadingProgress ? (
        <div className='uploadContainer'>
          <MynyfyText title={`Uploading...  ${uploadingProgress}%`} />
        </div>
      ) : (
        <div className='uploadOuterContainer'>
          {!imgUrl ? (
            <MynyfyUploadImg
              accept={true}
              type='text'
              label={'Upload Ad Image*'}
              name={'adImage'}
              onChange={(e) => uploadHandler(e)}
              className='uploadContainer cursor'
              color={theme.DARK_COLOR}
            />
          ) : (
            <div className='uploadContainer'>
              <img src={imgUrl} className='uploadedAdImg' />
              <MynyfyUploadImg
                accept={true}
                type='text'
                label={'Change Image'}
                name={'changeAdImage'}
                onChange={(e) => uploadHandler(e)}
                className='changeImgContainer cursor'
                bold={true}
                color={theme.DARK_COLOR}
              />
            </div>
          )}

          {error.image ? <MynyfyText title={error.image} error className='errorMsg' /> : null}
        </div>
      )}
      <AdTips step={3} />
      <MynyfyButton
        disabled={!adImage}
        label={'Preview Ad'}
        center
        style={{ borderRadius: 50 }}
        containerStyle={{ marginTop: 21 }}
        onClick={() => validateDetails()}
      />
    </>
  );
};

export default StepThreePublishAd;
