import _ from 'lodash';

/*
 * Some pre-defined validators
 */
const defaultValidators = {
  required: (value) => (!value ? 'Required' : null),
};

/**
 * Run the given validator or check if there is a predefined validator
 */
function runValidator(validator, value, all) {
  if (_.isFunction(validator)) {
    return validator(value, all);
  } else if (defaultValidators[validator]) {
    return defaultValidators[validator](value);
  }
}

/**
 * Validate the form input `values` against the `validators`
 *
 * Your validators should be a an object with keys that match keys in values.
 * The value of your validators can be either a single function or an array of functions
 *
 * Your validators should return a non-falsey value if they fail
 *
 * arg example:
 *
 * values = { name: 'Test Name', age: 20 }
 *
 * validators = {
 *   name: [
 *     'required', // use default validator
 *     val => val.length > 20 ? 'name is too long' : null
 *   ],
 *   age: value => value > 999999 ? 'too old' : null,
 * }
 *
 */

export default function validate(values = {}, validators = {}) {
  // Add all errors here as we go
  const errors = {};

  // const validatorKeys = _.keys(validators);

  const valueKeys = _.keys(values);
  // Go over each validator key and see if we have an error
  _.each(valueKeys, (v) => {
    const value = values[v];
    const validator = validators[v];

    let result;

    // If the validator is an array of functions iterate over each one
    if (_.isArray(validator)) {
      for (let i = 0; i < validator.length; i++) {
        result = runValidator(validator[i], value, values);

        // Once we get a result, bail out
        if (result) break;
      }
    } else if (_.isFunction(validator)) {
      result = runValidator(validator, value, values);
    }

    if (result) {
      errors[v] = result;
    }
  });

  return errors;
}
