export const emailValidation =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const firstNameValidation = /^[^\s]+[-a-zA-Z\s]*[^\s]$/;
export const lastNameValidation = /^(?![\s-])[\w\s-]+$/;
export const pincodeValidation = /^[0-9]{6,6}$/;

export const profileValidator = {
  firstName: [
    (value) =>
      value
        ? firstNameValidation.test(value) === false
          ? 'Please enter a valid first name'
          : value?.length < 2
          ? 'First name must be atleast 3 characters'
          : null
        : 'Please enter First Name',
  ],
  lastName: [
    (value) =>
      value
        ? lastNameValidation.test(value) === false
          ? 'Please enter a valid last name'
          : value?.length < 1
          ? 'Last name cannot be empty'
          : null
        : 'Please enter Last Name',
  ],
  email: [
    (value) =>
      value
        ? emailValidation.test(value) === false
          ? 'Please enter a valid email address'
          : null
        : 'Please enter Email',
  ],
};

export const registrationValidator = {
  mobile: [(value) => (value?.length === 10 ? null : 'Please enter valid Mobile Number')],
  password: [
    (value) =>
      value?.length
        ? value?.length >= 6
          ? null
          : 'Password should be atleast 6 characters'
        : 'Please enter Password',
  ],
  otp: [(value) => (value?.length ? null : 'Please enter OTP')],
  isTerms: [(value) => (value ? null : 'Please check Terms and conditions')],
};

export const locationValidator = {
  selectedState: [(value) => (value ? null : 'Select your State')],
  selectedCity: [(value) => (value ? null : 'Select your City')],
  selectedArea: [(value) => (value ? null : 'Select your Area')],
};
