import React from 'react';
import { useSelector } from 'react-redux';
import MynyfyText from './MynyfyText';
import { onShare } from '../screens/Refer/referHelper';

const ReferComponent = ({ referContainerStyle }) => {
  const referPoints = useSelector((state) => state.referPoints);
  const user = useSelector((state) => state.user);
  const profile = user.profile;

  return (
    <div
      id='Refer'
      className='referContainer'
      style={{ ...referContainerStyle }}
      onClick={() => onShare(profile?.referralCode)}>
      <div className='row'>
        <div className='referIconConatiner'>
          <img src={require('../assets/imgs/icons/share.png')} className='referIcon' />
        </div>
        <div className='referContent'>
          <MynyfyText title={'Refer to Earn '} p />
          <MynyfyText title={`${referPoints || ''} Mynts `} primary p />
          <MynyfyText title={'per referral'} p />
        </div>
      </div>
    </div>
  );
};

export default ReferComponent;
